import { useState } from "react";
import dummyImage from "../../../assets/images/profile.jpg";
import Reviews from "../../Reviews/Reviews";
import "./TaskAndPriceDetailModal.css";
import Modal from "react-bootstrap/Modal";
import { useEffect } from "react";
import axios from "axios";
import { toast } from "sonner";
import Loader from "../../Loader/Loader";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { decode } from "base-64";
import MyRatingComponent from "../../MyRatingComponent";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const TaskAndPriceDetailModal = ({
    bookingTaskId,
    onPlumbingHelpResponseTwo,
}) => {
    const { t } = useTranslation();
    const { serviceId } = useParams();
    const { userBookingId } = useParams();
    const [show, setShow] = useState(false);
    const [bidDetails, setBidDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSelectAndContinue = (itemId) => {
        const decodedId = decode(serviceId);
        const decodedBookingId = decode(userBookingId);
        axios
            .post(
                `${apiUrl}/booking/search`,
                {
                    step: 3,
                    serviceId: decodedId,
                    bookingId: decodedBookingId,
                    selectedServiceId: itemId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                }
            )
            .then((response) => {
                if (response.status === 200) {
                    localStorage.setItem("selectedServiceId", itemId);
                    toast.success(t("Step 2 Completed"));
                    if (onPlumbingHelpResponseTwo) {
                        onPlumbingHelpResponseTwo();
                    }
                } else {
                    toast.error("API error: " + response.data);
                }
            })
            .catch((error) => {
                toast.error(t("Network or other error:") + error);
            });
    };

    useEffect(() => {
        axios
            .get(`${apiUrl}/task/service-detail?serviceId=${bookingTaskId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then((response) => {
                setBidDetails(response.data.data);
            })
            .catch((error) => {
                toast.error("Fetching error", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [bookingTaskId]);

    return (
        <>
            <button
                type="button"
                className="mb-3 border-0 bg-white"
                style={{
                    color: "#0D7A5F",
                    fontSize: "16px",
                    fontWeight: "600",
                }}
                onClick={handleShow}
            >
                {t("View Profile & Reviews")}
            </button>

            <Modal
                size="lg"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="taskAndPriceDetailModal p-0"
                centered
            >
                {loading && <Loader />}
                <Modal.Header closeButton className="border-0"></Modal.Header>
                <Modal.Body>
                    <div className="d-flex align-items-start justify-content-between flex-column flex-lg-row">
                        <div className="d-flex align-items-lg-center flex-column flex-lg-row gap-3 mb-3 mb-sm-0 w-100">
                            <img
                                src={
                                    bidDetails.profileImage
                                        ? bidDetails.profileImage
                                        : dummyImage
                                }
                                className="profile-img"
                                alt="person"
                            />
                            <div>
                                <div className="d-flex align-items-lg-center align-items-start flex-column flex-sm-row gap-2 mb-2">
                                    <h1 className="mb-0">
                                        {bidDetails.firstname}&nbsp;
                                        {bidDetails.lastname}
                                    </h1>
                                    <span className="px-2 py-1 span">
                                        {bidDetails.minimumhours}{" "}
                                        {t("Hours Minimum")}
                                    </span>
                                </div>
                                <div className="d-flex align-items-center gap-2">
                                    <MyRatingComponent
                                        value={bidDetails.averageRating}
                                    />
                                    <h6 className="mb-0">
                                        {bidDetails.averageRating || 0}
                                    </h6>
                                </div>
                                <p className="fw-bold fs-6 text-dark">
                                    Address :&nbsp;
                                    <span className="fw-normal">
                                        {bidDetails.location}
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div>
                            <h2 className="text-lg-end">
                                {bidDetails.rate} {t("din/hr")}
                            </h2>
                            <button
                                className="coman-btn-fill px-3"
                                style={{ height: "44px", whiteSpace: "nowrap" }}
                                onClick={() =>
                                    handleSelectAndContinue(bidDetails._id)
                                }
                            >
                                {t("Select & Continue")}
                            </button>
                        </div>
                    </div>
                    <hr />
                    <div className="my-2">
                        <h3>{t("Select & Continue")}</h3>
                        <p>{bidDetails.aboutMe}</p>
                    </div>
                    <div className="my-2">
                        <h3>{t("Skills & Experience")}</h3>
                        <p>{bidDetails.skillsAndExperience}</p>
                    </div>
                    <div className="my-2 reviews">
                        <h3>
                            {t("Reviews")} ({bidDetails.feedbacksCount})
                        </h3>
                        <Reviews bookingReview={bidDetails} />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default TaskAndPriceDetailModal;
