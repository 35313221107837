import React, { createContext, useContext, useState, useEffect } from "react";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const storedLanguage = localStorage.getItem("selectedLanguage") || "sr";
    const [selectedLanguage, setSelectedLanguage] = useState(storedLanguage);

    useEffect(() => {
        localStorage.setItem("selectedLanguage", selectedLanguage);
    }, [selectedLanguage]);

    const value = {
        selectedLanguage,
        setSelectedLanguage,
    };

    return (
        <LanguageContext.Provider value={value}>
            {children}
        </LanguageContext.Provider>
    );
};

export const useLanguage = () => {
    const context = useContext(LanguageContext);
    if (!context) {
        throw new Error("useLanguage must be used within a LanguageProvider");
    }
    return context;
};
