import ReactSlider from "react-slider";
import TaskAndPriceCard from "../../../components/Cards/TaskandPriceCard/TaskAndPriceCard";
import "./PlumbingHelp.css";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import Calendar from "react-calendar";
import axios from "axios";
import search from "../../../assets/images/Search.png";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { decode } from "base-64";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const TaskAndPrice = ({ onPlumbingHelpResponseTwo }) => {
    const { t, i18n } = useTranslation();
    const [activeDate, setActiveDate] = useState(new Date());
    const [priceRange, setPriceRange] = useState([0, 40000]);
    const [sortOption, setSortOption] = useState("");
    const [date, setDate] = useState(null);
    const [searchName, setSearchName] = useState("");
    const [morningChecked, setMorningChecked] = useState(false);
    const [afternoonChecked, setAfternoonChecked] = useState(false);
    const [eveningChecked, setEveningChecked] = useState(false);
    const [selectedTime, setSelectedTime] = useState("");
    const [taskPriceDetails, setTaskPriceDetails] = useState([]);
    const [showDateFields, setShowDateFields] = useState(false);
    const [chooseDate, setChooseDate] = useState("");
    const { serviceId } = useParams();
    const decodedId = decode(serviceId);
    const [averageRate, setAverageRate] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const notificationsPerPage = 10;

    const handleDateChange = (newDate) => {
        setActiveDate(newDate);
        const dateInSeconds = moment(newDate).unix();
        const localOffsetInSeconds = moment().utcOffset() * 60;
        const localDateInSeconds = dateInSeconds + localOffsetInSeconds;
        setChooseDate(localDateInSeconds);
        setShowDateFields(false);
    };

    const lat = localStorage.getItem("lat");
    const lang = localStorage.getItem("long");

    useEffect(() => {
        let prizeOrder = "2";
        if (sortOption === "1") {
            prizeOrder = "0";
        } else if (sortOption === "2") {
            prizeOrder = "1";
        }
        setLoading(true);
        axios
            .get(
                `${apiUrl}/task/filter?serviceId=${decodedId}&name=${searchName}&date=${date}&lat=${lat}&long=${lang}&morning=${morningChecked}&afternoon=${afternoonChecked}&evening=${eveningChecked}&chooseDate=${chooseDate}&selectTime=${selectedTime}&lowestPrize=${priceRange[0]}&highestPrize=${priceRange[1]}&prizeOrder=${prizeOrder}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                }
            )
            .then((response) => {
                setTaskPriceDetails(response.data.data);
            })
            .catch((error) => {
                // console.error("Error fetching task details:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [
        decodedId,
        searchName,
        sortOption,
        date,
        selectedTime,
        priceRange,
        morningChecked,
        afternoonChecked,
        eveningChecked,
        chooseDate,
        lat,
        lang,
    ]);

    useEffect(() => {
        axios
            .get(`${apiUrl}/task/average-rate?serviceId=${decodedId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then((response) => {
                setAverageRate(response.data.data);
            })
            .catch((error) => {
                // console.error("Error fetching average rate", error);
            });
    }, [decodedId]);

    const updateDateFilter = (selectedDate) => {
        setDate(selectedDate);
        setChooseDate("");
    };

    const indexOfLastNotification = currentPage * notificationsPerPage;
    const indexOfFirstNotification =
        indexOfLastNotification - notificationsPerPage;
    const currentNotifications = taskPriceDetails.slice(
        indexOfFirstNotification,
        indexOfLastNotification
    );

    const pageNumbers = Math.ceil(
        taskPriceDetails.length / notificationsPerPage
    );

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const renderPagination = () => {
        const MAX_PAGES_VISIBLE = 5;
        const paginationItems = [];

        let startPage = currentPage - Math.floor(MAX_PAGES_VISIBLE / 2);
        startPage = Math.max(startPage, 1);
        const endPage = Math.min(
            startPage + MAX_PAGES_VISIBLE - 1,
            pageNumbers
        );

        startPage = Math.max(endPage - MAX_PAGES_VISIBLE + 1, 1);

        for (let i = startPage; i <= endPage; i++) {
            paginationItems.push(
                <li
                    key={i}
                    className={`page-item ${currentPage === i ? "active" : ""}`}
                >
                    <button
                        className="page-link"
                        onClick={() => setCurrentPage(i)}
                    >
                        {i}
                    </button>
                </li>
            );
        }
        return (
            <>
                {taskPriceDetails.length > 10 && (
                    <nav className="mt-5">
                        <ul className="pagination align-items-center justify-content-center gap-2">
                            <li>
                                <button
                                    onClick={() =>
                                        handlePageChange(currentPage - 1)
                                    }
                                    disabled={currentPage === 1}
                                    className="bg-white border-0 arrow-btn"
                                >
                                    &laquo;
                                </button>
                            </li>
                            {paginationItems}
                            <li>
                                <button
                                    onClick={() =>
                                        handlePageChange(currentPage + 1)
                                    }
                                    disabled={currentPage === pageNumbers}
                                    className="bg-white border-0 arrow-btn"
                                >
                                    &raquo;
                                </button>
                            </li>
                        </ul>
                    </nav>
                )}
            </>
        );
    };

    return (
        <div className="task-price-container">
            <div className="container">
                <div className="d-flex justify-content-between gap-3 align-items-center mt-5 flex-md-row flex-column">
                    <div className="search-bars position-relative">
                        <img
                            src={search}
                            alt="search"
                            className="position-absolute"
                        />
                        <input
                            type="search"
                            name="name"
                            value={searchName}
                            placeholder={t("Search")}
                            onChange={(e) => setSearchName(e.target.value)}
                        />
                    </div>
                    <div className="d-flex align-items-center flex-wrap">
                        <h2 className="mb-0" style={{ whiteSpace: "nowrap" }}>
                            {t("Sorted By")}:&nbsp;
                        </h2>
                        <select
                            className="sorted form-select"
                            style={{ width: "220px" }}
                            value={sortOption}
                            onChange={(e) => setSortOption(e.target.value)}
                        >
                            <option value="">{t("Recommended")}</option>
                            <option value="1">
                                {t("Price")} ({t("Lowest to Highest")})
                            </option>
                            <option value="2">
                                {t("Price")} ({t("Highest to Lowest")})
                            </option>
                        </select>
                    </div>
                </div>

                <div className="main-grid-2 my-5">
                    <div className="task-price-left">
                        <h3>{t("Date")}</h3>
                        <div className="grid-2">
                            <button
                                className="button"
                                onClick={() => updateDateFilter(0)}
                            >
                                {t("Today")}
                            </button>
                            <button
                                className="button"
                                onClick={() => updateDateFilter(1)}
                            >
                                {t("Within 3 Days")}
                            </button>
                            <button
                                className="button"
                                onClick={() => updateDateFilter(null)}
                            >
                                {t("Within A Week")}
                            </button>
                            <button
                                className="button"
                                onClick={() =>
                                    setShowDateFields(!showDateFields)
                                }
                            >
                                {t("Choose Dates")}
                            </button>

                            {showDateFields && (
                                <Calendar
                                    onChange={handleDateChange}
                                    value={activeDate}
                                    minDate={new Date()}
                                    locale={i18n.language}
                                />
                            )}
                        </div>
                        <hr />
                        <div className="mb-4">
                            <h3>{t("Time of Day")}</h3>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={morningChecked}
                                    onChange={() =>
                                        setMorningChecked(!morningChecked)
                                    }
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="morningCheckbox"
                                >
                                    {t("Morning (8 AM - 12 PM)")}
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={afternoonChecked}
                                    onChange={() =>
                                        setAfternoonChecked(!afternoonChecked)
                                    }
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="afternoonCheckbox"
                                >
                                    {t("Afternoon (12 PM - 5 PM)")}
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={eveningChecked}
                                    onChange={() =>
                                        setEveningChecked(!eveningChecked)
                                    }
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="eveningCheckbox"
                                >
                                    {t("Evening (5 PM - 9:30 PM)")}
                                </label>
                            </div>
                        </div>
                        <span className="hr-text position-relative m-auto">
                            <p className="mb-0 text-center">
                                {t("Or choose a specific time")}
                            </p>
                            <hr />
                        </span>
                        <div className="mt-4">
                            <select
                                className="sorted w-100 form-select"
                                value={selectedTime}
                                onChange={(e) =>
                                    setSelectedTime(e.target.value)
                                }
                            >
                                <option value="">{t("I’m Flexible")}</option>
                                <option value={"08:00"}>8:00am</option>
                                <option value={"08:30"}>8:30am</option>
                                <option value={"09:00"}>9:00am</option>
                                <option value={"09:30"}>9:30am</option>
                                <option value={"10:00"}>10:00am</option>
                                <option value={"10:30"}>10:30am</option>
                                <option value={"11:00"}>11:00am</option>
                                <option value={"11:30"}>11:30am</option>
                                <option value={"12:00"}>12:00pm</option>
                                <option value={"12:30"}>12:30pm</option>
                                <option value={"13:00"}>1:00pm</option>
                                <option value={"13:30"}>1:30pm</option>
                                <option value={"14:00"}>2:00pm</option>
                                <option value={"14:30"}>2:30pm</option>
                                <option value={"15:00"}>3:00pm</option>
                                <option value={"15:30"}>3:30pm</option>
                                <option value={"16:00"}>4:00pm</option>
                                <option value={"16:30"}>4:30pm</option>
                                <option value={"17:00"}>5:00pm</option>
                                <option value={"17:30"}>5:30pm</option>
                                <option value={"18:00"}>6:00pm</option>
                                <option value={"18:30"}>6:30pm</option>
                                <option value={"19:00"}>7:00pm</option>
                                <option value={"19:30"}>7:30pm</option>
                                <option value={"20:00"}>8:00pm</option>
                                <option value={"20:30"}>8:30pm</option>
                                <option value={"21:00"}>9:00pm</option>
                                <option value={"21:30"}>9:30pm</option>
                            </select>
                        </div>
                        <hr />
                        <div>
                            <h3>{t("Price")}</h3>
                            <ReactSlider
                                className="horizontal-slider"
                                thumbClassName="example-thumb"
                                trackClassName="example-track"
                                value={priceRange}
                                min={0}
                                max={40000}
                                renderThumb={(props, state) => (
                                    <div {...props}>{state.valueNow}</div>
                                )}
                                onChange={(range) => setPriceRange(range)}
                            />
                            <div className="d-flex justify-content-between">
                                <span>
                                    {t("Start")}: {priceRange[0]} {t("din")}
                                </span>{" "}
                                <br />
                                <span>
                                    {t("End")}: {priceRange[1]} {t("din")}
                                </span>
                            </div>
                        </div>
                        <div className="text-center mt-3">
                            <h4>
                                {t("The average hourly rate is")}&nbsp;
                                <span>
                                    {Number(averageRate).toFixed(2)}{" "}
                                    {t("din/hr")}
                                </span>
                            </h4>
                        </div>
                    </div>
                    <div>
                        <TaskAndPriceCard
                            loading={loading}
                            taskPriceDetails={currentNotifications}
                            onPlumbingHelpResponseTwo={
                                onPlumbingHelpResponseTwo
                            }
                        />
                        {renderPagination()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TaskAndPrice;
