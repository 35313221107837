import { useEffect, useState } from "react";
import Title from "../../../components/title/Title";
import "./EarningsTrackings.css";
import axios from "axios";
import { toast } from "sonner";
import Loader from "../../../components/Loader/Loader";
import moment from "moment";
import { useTranslation } from "react-i18next";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const EarningsTrackings = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [earning, setEarning] = useState([]);
    const [totalEarning, setTotalEarning] = useState(0);

    useEffect(() => {
        setLoading(true);
        axios
            .get(`${apiUrl}/tasker/earning`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then((response) => {
                setEarning(response.data.data.data);
                setTotalEarning(response.data.data);
            })
            .catch((error) => {
                toast.error("Fetching error", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const formatDate = (createdAt) => {
        const messageDate = moment(createdAt);
        const today = moment().startOf("day");
        const yesterday = moment().subtract(1, "days").startOf("day");

        if (messageDate.isSame(today, "day")) {
            return `Today ${messageDate.format("hh:mm A")}`;
        } else if (messageDate.isSame(yesterday, "day")) {
            return `Yesterday ${messageDate.format("hh:mm A")}`;
        } else {
            return messageDate.format("D/M/YYYY");
        }
    };

    return (
        <div className="earning-wrapper mb-5 flex-1">
            {loading && <Loader />}
            <div className="container">
                <div className="col-lg-10 offset-lg-1">
                    <Title
                        title={t("Earnings Trackings")}
                        location="/home-provider"
                    />
                    <div className="header d-flex align-items-center p-4 flex-column flex-sm-row justify-content-center mb-4 gap-2">
                        <h1 className="mb-0">{t("Total Earning")} : </h1>
                        <h2 className="mb-0">
                            {totalEarning.total} {t("din")}
                        </h2>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                        <h5 className="mb-0">{t("Total Task Completed")} : </h5>
                        <h6 className="mb-0">{earning.length}</h6>
                    </div>{" "}
                    <hr />
                    <div className="grid-3">
                        {earning.length > 0 ? (
                            earning.map((item) => (
                                <div
                                    className="box d-flex align-items-center justify-content-between p-3"
                                    key={item._id}
                                >
                                    <div>
                                        <h3 className="mb-1">{item.service}</h3>
                                        <p className="mb-0">
                                            {formatDate(item.time)}
                                        </p>
                                    </div>
                                    <h4 className="mb-0 ms-2">
                                        {item.total} {t("din")}
                                    </h4>
                                </div>
                            ))
                        ) : (
                            <div
                                className="position-absolute top-50 start-50 translate-middle"
                                style={{ whiteSpace: "nowrap" }}
                            >
                                {t("You have not completed any task yet")}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EarningsTrackings;
