import "./PlumbingDetailsCard.css";
import dummyImage from "../../../assets/images/profile.jpg";
import location from "../../../assets/images/location.png";
import calendars from "../../../assets/images/Calendar.png";
import ruler from "../../../assets/images/ruler.png";
import { toast } from "sonner";
import { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../../../components/Loader/Loader";
import { formatDateTime, updateLocale } from "../../../Utils/Utils";
import { useTranslation } from "react-i18next";
import MyRatingComponent from "../../MyRatingComponent";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const CompletedTaskCard = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [profileDetails, setProfileDetails] = useState([]);

    useEffect(() => {
        setLoading(true);
        axios
            .get(`${apiUrl}/task/completed-tasks`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then((response) => {
                setProfileDetails(response.data.data);
            })
            .catch((error) => {
                toast.error("Fetching error", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    if (profileDetails.length === 0) {
        return <p>{t("You’ve not any Completed booking yet")}</p>;
    }

    const selectedLanguage = localStorage.getItem("selectedLanguage") || "sr";
    updateLocale(selectedLanguage);
    return (
        <>
            {loading && <Loader />}
            {profileDetails.map((task) => (
                <div
                    className="plumbing-detail-card d-flex flex-column justify-content-between"
                    key={task._id}
                    style={{ height: "100%" }}
                >
                    <div>
                        <div className="card-header p-4">
                            <h2>{task.service}</h2>
                            <div className="d-flex align-items-center gap-3">
                                <img
                                    src={
                                        task.profileImage
                                            ? task.profileImage
                                            : dummyImage
                                    }
                                    className="person"
                                    alt="person"
                                />
                                <div>
                                    <h3 className="mb-0">
                                        {task.firstname}&nbsp;
                                        {task.lastname}
                                    </h3>
                                    <div className="mb-2 d-flex gap-1 align-items-center">
                                        <MyRatingComponent
                                            value={task.averageRating}
                                        />
                                        <span>{task.averageRating || 0}</span>
                                    </div>
                                    <h6>{task.minimumhours} Hours Minimum</h6>
                                </div>
                            </div>
                        </div>
                        <div className="card-body p-4 pb-0">
                            <div className="d-flex gap-2">
                                <img
                                    className="icons"
                                    src={calendars}
                                    alt="calenders"
                                />
                                <p>{formatDateTime(task.date, task.time)}</p>
                            </div>

                            <div className="d-flex gap-2">
                                <img
                                    className="icons"
                                    src={location}
                                    alt="location"
                                />
                                <p>{task.address}</p>
                            </div>
                            <div className="d-flex gap-2">
                                <img
                                    className="icons"
                                    src={ruler}
                                    alt="ruler"
                                />
                                <p className="mb-0">
                                    {task.howBigIsYourTask} {t("Hours")}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="py-3 px-4">
                        <div className="m-auto w-50">
                            <button className="coman-btn">
                                {t("Completed")}
                            </button>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default CompletedTaskCard;
