import "./Languages.css";
import flag1 from "../../assets/images/flag-1.png";
import flag2 from "../../assets/images/flag-2.png";
import currect from "../../assets/images/currect-2.png";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../context/LanguageContext";
import axios from "axios";
import i18next from "../../i18n";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const Languages = () => {
    const { t } = useTranslation();
    const { selectedLanguage, setSelectedLanguage } = useLanguage();

    const handleLanguageSelect = async (language) => {
        try {
            const languageStatus = language === "en" ? 1 : 2;
            const response = await axios.put(
                `${apiUrl}/user/profile`,
                { language: languageStatus },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            if (response.status === 200) {
                setSelectedLanguage(language);
                i18next.changeLanguage(language);
            } else {
                // console.error("Failed to update language status");
            }
        } catch (error) {
            // console.error("Error updating language status:", error);
        }
    };
    return (
        <div className="height">
            <div className="box d-flex flex-column justify-content-center align-items-center p-5 w-100">
                <div className="d-flex align-items-center gap-2">
                    <img className="flag" src={flag2} alt="flag2" />
                    <h6
                        className={
                            selectedLanguage === "sr"
                                ? "selected-language"
                                : "unselected-language"
                        }
                        onClick={() => handleLanguageSelect("sr")}
                    >
                        {t("Serbian")}
                        {selectedLanguage === "sr" && (
                            <img
                                src={currect}
                                className="currect"
                                alt="Selected"
                            />
                        )}
                    </h6>
                </div>
                <hr />
                <div className="d-flex align-items-center gap-2">
                    <img className="flag" src={flag1} alt="flag1" />
                    <h6
                        className={
                            selectedLanguage === "en"
                                ? "selected-language"
                                : "unselected-language"
                        }
                        onClick={() => handleLanguageSelect("en")}
                    >
                        {t("English")}
                        {selectedLanguage === "en" && (
                            <img
                                src={currect}
                                className="currect"
                                alt="Selected"
                            />
                        )}
                    </h6>
                </div>
            </div>
        </div>
    );
};

export default Languages;
