import "./Reviews.css";
import star from "../../assets/images/Rating-Star.png";
import dummyImage from "../../assets/images/profile.jpg";
import leftArrow from "../../assets/images/left-arrow.png";
import rightArrow from "../../assets/images/right-arrow-2.png";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Reviews = ({ bookingReview }) => {
    const { t } = useTranslation();
    const reviewsPerPage = 5;
    const [currentPage, setCurrentPage] = useState(1);

    const startIndex = (currentPage - 1) * reviewsPerPage;
    const endIndex = startIndex + reviewsPerPage;

    const displayedReviews =
        bookingReview.feedbacks &&
        bookingReview.feedbacks.slice(startIndex, endIndex);

    const totalPages = Math.ceil(
        (bookingReview.feedbacks && bookingReview.feedbacks.length) /
            reviewsPerPage
    );

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const [expandedReviews, setExpandedReviews] = useState([]);

    const toggleReadMore = (reviewId) => {
        setExpandedReviews((prevExpandedReviews) => {
            if (prevExpandedReviews.includes(reviewId)) {
                return prevExpandedReviews.filter((id) => id !== reviewId);
            } else {
                return [...prevExpandedReviews, reviewId];
            }
        });
    };

    return (
        <div className="reviews-rate">
            {displayedReviews && displayedReviews.length > 0 ? (
                displayedReviews.map((item) => (
                    <div className="reviews-wrapper p-3 mb-3" key={item._id}>
                        <div className="d-flex align-items-center gap-2 mb-2">
                            <img
                                src={
                                    item?.user?.profileImage
                                        ? item?.user?.profileImage
                                        : dummyImage
                                }
                                alt="person"
                                className="profile-photo"
                            />
                            <div>
                                <div className="d-flex align-items-center gap-2">
                                    <h3 className="mb-0">
                                        {item?.user?.firstname}&nbsp;
                                        {item?.user?.lastname}
                                    </h3>
                                    <span className="px-2 py-1">
                                        {item?.service?.service}
                                    </span>
                                </div>
                                <div className="d-flex align-items-center gap-2">
                                    <img
                                        src={star}
                                        className="star"
                                        alt="star"
                                    />
                                    <h5 className="mb-0">{item.rate}</h5>
                                    <h6 className="mb-0">
                                        on&nbsp;
                                        {moment(item.createdAt).format(
                                            "ddd, MMM D"
                                        )}
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p className="mb-0">
                                {expandedReviews.includes(item._id)
                                    ? item.review
                                    : item.review.slice(0, 150)}
                                {item.review.length > 150 && (
                                    <span
                                        className="read-more"
                                        onClick={() => toggleReadMore(item._id)}
                                    >
                                        &nbsp;
                                        {expandedReviews.includes(item._id) ? (
                                            <>{t("Read Less")}</>
                                        ) : (
                                            <>{t("Read More")}</>
                                        )}
                                    </span>
                                )}
                            </p>
                        </div>
                    </div>
                ))
            ) : (
                <p>{t("No feedback available.")}</p>
            )}

            {totalPages > 1 && (
                <div className="pagination">
                    <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="bg-white border-0"
                    >
                        <img
                            src={leftArrow}
                            alt="leftArrow"
                            className="arrow-page"
                        />
                    </button>
                    {Array.from({ length: totalPages }, (_, i) => (
                        <button
                            key={i + 1}
                            onClick={() => handlePageChange(i + 1)}
                            className={`${
                                i + 1 === currentPage ? "active" : ""
                            } pagination-btn`}
                        >
                            {i + 1}
                        </button>
                    ))}
                    <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className="bg-white border-0"
                    >
                        <img
                            src={rightArrow}
                            alt="rightArrow"
                            className="arrow-page"
                        />
                    </button>
                </div>
            )}
        </div>
    );
};

export default Reviews;
