import { useState } from "react";
import "./TaskPostDetailModal.css";
import Modal from "react-bootstrap/Modal";
import { useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import dummyImage from "../../../assets/images/profile.jpg";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { encode } from "base-64";
import MyRatingComponent from "../../MyRatingComponent";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const BrowsTaskDetailModal = ({ browseTaskId, onBidSelected }) => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [taskDetails, setTaskDetails] = useState({});
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (browseTaskId) {
            axios
                .get(`${apiUrl}/tasker/tasks?taskId=${browseTaskId}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                })
                .then((response) => {
                    setTaskDetails(response.data.data);
                })
                .catch((error) => {
                    // console.error("Error fetching task details:", error);
                });
        }
    }, [browseTaskId, onBidSelected]);

    function formatDate(inputDate) {
        return moment(inputDate).format("DD/MM/YYYY");
    }
    function formatTime(inputTime) {
        if (inputTime) {
            const formattedTime = moment(inputTime).format("hh:mm A");
            return formattedTime;
        } else {
            return "N/A";
        }
    }
    return (
        <>
            <button
                type="button"
                className="coman-btn"
                onClick={handleShow}
                style={{ height: "35px", fontWeight: "500", width: "50%" }}
            >
                {t("View Details")}
            </button>
            <Modal
                size="lg"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="detailModal"
                centered
            >
                <Modal.Header closeButton className="border-0"></Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <div className="mb-4 d-flex align-items-center gap-3">
                            <img
                                src={
                                    taskDetails.profileImage
                                        ? taskDetails.profileImage
                                        : dummyImage
                                }
                                className="profile-img"
                                alt="person"
                            />
                            <div>
                                <h1>
                                    {taskDetails.firstname} &nbsp;
                                    {taskDetails.lastname}
                                </h1>
                                <div className="d-flex gap-1 align-items-center">
                                    <MyRatingComponent
                                        value={taskDetails.averageRating}
                                    />
                                    <span>
                                        {taskDetails.averageRating || 0}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="d-flex align-items-start flex-column flex-sm-row gap-0 gap-sm-5 ">
                            <div>
                                <h3 className="mb-2">
                                    {t("Required Service")}
                                </h3>
                                <h2>{taskDetails?.service}</h2>
                            </div>
                            <div>
                                <h3 className="mb-2">{t("Budget")}</h3>
                                <h2>{`${taskDetails.budget} din (${
                                    taskDetails.selectPrice === 1
                                        ? t("Hourly")
                                        : t("Fixed")
                                })`}</h2>
                            </div>
                        </div>
                        <hr />
                        <div className="d-flex flex-wrap mb-4 gap-2">
                            <div style={{ flexGrow: 1, flexBasis: 200 }}>
                                <h4>{t("Date")}</h4>
                                <p className="mb-0">
                                    {formatDate(taskDetails.selectDate)}
                                </p>
                            </div>
                            <div style={{ flexGrow: 1, flexBasis: 200 }}>
                                <h4>{t("Time")}</h4>
                                <p className="mb-0">
                                    {formatTime(taskDetails.selectTime)}
                                </p>
                            </div>
                            <div style={{ flexGrow: 1, flexBasis: 200 }}>
                                <h4>{t("Required Time")}</h4>
                                <p className="mb-0">
                                    {taskDetails.howBigIsYourTask} {t("Hours")}
                                </p>
                            </div>
                        </div>
                        <div className="mb-4">
                            <h4>{t("Address")}</h4>
                            <p>{taskDetails.address}</p>
                        </div>
                        <div className="mb-4">
                            <h4>{t("Unit or Apartment No.")}</h4>
                            <p>{taskDetails.apartmentNo}</p>
                        </div>
                        <div>
                            <h4 className="mb-2">{t("Description")}</h4>
                            <div className="description p-3">
                                <p className="mb-0">
                                    {taskDetails.description && (
                                        <span className="mb-0 p-text">
                                            {isExpanded
                                                ? taskDetails.description
                                                : taskDetails.description.slice(
                                                      0,
                                                      150
                                                  )}
                                            {taskDetails.description.length >
                                                150 &&
                                                !isExpanded && (
                                                    <span
                                                        className="read-more"
                                                        onClick={toggleReadMore}
                                                    >
                                                        &nbsp;{t("Read More")}
                                                    </span>
                                                )}
                                            {taskDetails.description.length >
                                                150 &&
                                                isExpanded && (
                                                    <span
                                                        className="read-more"
                                                        onClick={toggleReadMore}
                                                    >
                                                        &nbsp;{t("Read Less")}
                                                    </span>
                                                )}
                                        </span>
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                    {taskDetails.isCreated ? (
                        <p className="text-center text-danger">
                            {t("You've already submitted a bid for this task.")}
                        </p>
                    ) : (
                        <Link
                            to={`/bid-submission/${encode(browseTaskId)}`}
                            className="coman-btn-fill mb-3"
                        >
                            {t("Bid Now")}
                        </Link>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default BrowsTaskDetailModal;
