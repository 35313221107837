import { Navigate, Route, Routes } from "react-router-dom";
import PublicRoutes from "./PublicRoutes";
import PrivateRoutes from "./PrivateRoutes";
import Landing from "../pages/Landing";
import Services from "../pages/Services";
import Terms from "../pages/Terms";
import Privacy from "../pages/Privacy";
import Home from "../pages/UserFlow/Home/Home";
import PlumbingHelpSearch from "../pages/UserFlow/PlumbingHelpSearch/PlumbingHelpSearch";
import Bids from "../pages/UserFlow/Bids/Bids";
import BidsPayment from "../pages/UserFlow/Bids/BidsPayment";
import BidsConfirmDetails from "../pages/UserFlow/Bids/BidsConfirmDetails";
import TaskPosting from "../pages/UserFlow/TaskPosting/TaskPosting";
import Notifications from "../components/Notifications/Notifications";
import MyTask from "../pages/UserFlow/MyTask/MyTask";
import MyTaskDetailsCredit from "../pages/UserFlow/MyTaskDetails/MyTaskDetailsCredit";
import EditProfiles from "../pages/UserFlow/EditProfiles";
import Language from "../pages/UserFlow/Language";
import CustomerSupports from "../pages/UserFlow/CustomerSupport/CustomerSupports";
import Faqs from "../pages/UserFlow/FAQs/Faqs";
import TermsAndCondition from "../pages/UserFlow/TermsAndCondition";
import PrivacyPolicys from "../pages/UserFlow/PrivacyPolicys";
import UserMessage from "../pages/UserFlow/UserMessage";
import RatingReviews from "../pages/UserFlow/RatingReviews";
import Choose from "../pages/UserFlow/Auth/ChooseLoginorSignUp/Choose";
import SignUp from "../pages/UserFlow/Auth/SignUp";
import Login from "../pages/UserFlow/Auth/Login";
import ForgotPassword from "../pages/UserFlow/Auth/ForgotPassword";
import VerificationCode from "../pages/UserFlow/Auth/VerificationCode/VerificationCode";
import EditEmail from "../pages/UserFlow/Auth/EditEmail";
import TaskerChoose from "../pages/ServiceProviderFlow/Auth/ChooseLoginorSignUp/TaskerChoose";
import TaskerSignUp from "../pages/ServiceProviderFlow/Auth/TaskerSignUp";
import TaskerLogin from "../pages/ServiceProviderFlow/Auth/TaskerLogin";
import TaskerForgotPassword from "../pages/ServiceProviderFlow/Auth/TaskerForgotPassword";
import TaskerEditEmail from "../pages/ServiceProviderFlow/Auth/TaskerEditEmail";
import TaskerVerificationCode from "../pages/ServiceProviderFlow/Auth/VerificationCode/TaskerVerificationCode";
import ProfileCreation from "../pages/ServiceProviderFlow/ProfileCreation/ProfileCreation";
import SetYourAvailability from "../pages/ServiceProviderFlow/SetYourAvailability";
import HomeProvider from "../pages/ServiceProviderFlow/HomeProvider/HomeProvider";
import TaskerMessage from "../pages/ServiceProviderFlow/TaskerMessage";
import BidSubmission from "../pages/ServiceProviderFlow/BidSubmission/BidSubmission";
import MyBids from "../pages/ServiceProviderFlow/MyBids/MyBids";
import BidDetails from "../pages/ServiceProviderFlow/BidDetails/BidDetails";
import ProviderTask from "../pages/ServiceProviderFlow/MyTask/ProviderTask";
import TaskAcceptance from "../pages/ServiceProviderFlow/TaskAcceptance/TaskAcceptance";
import TaskDetails from "../pages/ServiceProviderFlow/TaskDetails/TaskDetails";
import ProviderNotification from "../pages/ServiceProviderFlow/ProviderNotification";
import ProviderEditProfile from "../pages/ServiceProviderFlow/ProviderEditProfile";
import EditServices from "../pages/ServiceProviderFlow/EditServices";
import BankDetails from "../pages/ServiceProviderFlow/BankDetails";
import ProviderLanguage from "../pages/ServiceProviderFlow/ProviderLanguage";
import ProviderCustomerSupport from "../pages/ServiceProviderFlow/CustomerSupport/ProviderCustomerSupport";
import ProviderFaqs from "../pages/ServiceProviderFlow/ProviderFaqs";
import ProviderPrivacyPolicy from "../pages/ServiceProviderFlow/ProviderPrivacyPolicy";
import ProviderTermsAndConditions from "../pages/ServiceProviderFlow/ProviderTermsAndConditions";
import EarningsTrackings from "../pages/ServiceProviderFlow/EarningsTrackings/EarningsTrackings";
// import Subscription from "../pages/ServiceProviderFlow/SubscriptionPlan/Subscription";
import ProviderRatingReviews from "../pages/ServiceProviderFlow/ProviderRatingReviews";
// import Index from "../components/404/Index";
import Blocked from "../components/Blocked/Blocked";
import UserAuthLayout from "../Layout/UserAuthLayout";
import TaskerAuthLayout from "../Layout/TaskerAuthLayout";
import UserLayout from "../Layout/UserLayout";
import TaskerLayout from "../Layout/TaskerLayout";
import LandingLayout from "../Layout/LandingLayout";
import Sitemap from "./Sitemap";
import PaymentSuccess from "../pages/ServiceProviderFlow/SubscriptionPlan/PaymentSuccess";
import PaymentError from "../pages/ServiceProviderFlow/SubscriptionPlan/PaymentError";

const AllRoutes = () => {
    return (
        <Routes>
            {/* User Public Route  */}
            <Route element={<PublicRoutes />}>
                <Route element={<LandingLayout />}>
                    <Route path="/" element={<Landing />} />
                    <Route path="/services" element={<Services />} />
                </Route>
                <Route element={<UserAuthLayout />}>
                    <Route path="login" element={<Login />} />
                    <Route path="terms" element={<Terms />} />
                    <Route path="privacy" element={<Privacy />} />
                    <Route path="user-login" element={<Choose />} />
                    <Route path="signup" element={<SignUp />} />
                    <Route path="forgot" element={<ForgotPassword />} />
                    <Route path="verification" element={<VerificationCode />} />
                    <Route path="/edit-email" element={<EditEmail />} />
                </Route>
                <Route element={<TaskerAuthLayout />}>
                    <Route path="/tasker" element={<TaskerChoose />} />
                    <Route path="/tasker-signup" element={<TaskerSignUp />} />
                    <Route path="/tasker-login" element={<TaskerLogin />} />
                    <Route
                        path="/tasker-forgot"
                        element={<TaskerForgotPassword />}
                    />
                    <Route
                        path="/tasker-edit-email"
                        element={<TaskerEditEmail />}
                    />
                    <Route
                        path="/tasker-verification"
                        element={<TaskerVerificationCode />}
                    />
                    <Route
                        path="/profile-creation"
                        element={<ProfileCreation />}
                    />
                    <Route
                        path="/tasker-availability"
                        element={<SetYourAvailability />}
                    />
                </Route>
            </Route>

            {/* User Private Route  */}
            <Route element={<PrivateRoutes allowedRoles={["1"]} />}>
                {/* user  */}
                <Route element={<UserLayout />}>
                    <Route path="/home" element={<Home />} />
                    <Route
                        path="/plumbing/:serviceId/:userBookingId"
                        element={<PlumbingHelpSearch />}
                    />
                    <Route path="/bids/:taskBidId" element={<Bids />} />
                    <Route
                        path="/bidspayment/:bidId/:_iD"
                        element={<BidsPayment />}
                    />
                    <Route
                        path="/bidspaymentconfirm/:bidId/:_iD"
                        element={<BidsConfirmDetails />}
                    />
                    {/* <Route path="/refer" element={<ReferralProgram />} /> */}
                    <Route path="/task-post" element={<TaskPosting />} />
                    <Route path="/notification" element={<Notifications />} />
                    <Route path="/mytask" element={<MyTask />} />
                    <Route
                        path="/mytaskdetailscredit/:mytaskdetailscreditId"
                        element={<MyTaskDetailsCredit />}
                    />
                    <Route path="/edit-profile" element={<EditProfiles />} />
                    <Route path="/languages" element={<Language />} />
                    <Route
                        path="/customer-support"
                        element={<CustomerSupports />}
                    />
                    <Route path="/faqs" element={<Faqs />} />
                    <Route
                        path="/terms-and-conditions"
                        element={<TermsAndCondition />}
                    />
                    <Route
                        path="/privacy-policy"
                        element={<PrivacyPolicys />}
                    />
                    <Route path="/message" element={<UserMessage />} />
                    <Route path="/reviews" element={<RatingReviews />} />
                </Route>
            </Route>

            {/* Tasker Private Route */}
            <Route element={<PrivateRoutes allowedRoles={["2"]} />}>
                {/* provider  */}
                <Route element={<TaskerLayout />}>
                    <Route path="/home-provider" element={<HomeProvider />} />
                    <Route path="/tasker-message" element={<TaskerMessage />} />
                    <Route
                        path="/bid-submission/:bidSubmissionId"
                        element={<BidSubmission />}
                    />
                    {/* <Route path="/provider-refer" element={<ReferralProgram />} /> */}
                    <Route path="/my-bids" element={<MyBids />} />
                    <Route
                        path="/bid-details/:bidDetailsId"
                        element={<BidDetails />}
                    />
                    <Route path="/provider-task" element={<ProviderTask />} />
                    <Route
                        path="/task-acceptance"
                        element={<TaskAcceptance />}
                    />
                    <Route
                        path="/task-details/:bookingId"
                        element={<TaskDetails />}
                    />
                    <Route
                        path="/provider-notification"
                        element={<ProviderNotification />}
                    />
                    <Route
                        path="/provider-edit-profile"
                        element={<ProviderEditProfile />}
                    />
                    <Route path="/edit-services" element={<EditServices />} />
                    <Route path="/bank-details" element={<BankDetails />} />
                    <Route path="/language" element={<ProviderLanguage />} />
                    <Route
                        path="/provider-customer-support"
                        element={<ProviderCustomerSupport />}
                    />
                    <Route path="/provider-faqs" element={<ProviderFaqs />} />
                    <Route
                        path="/provider-privacy-policy"
                        element={<ProviderPrivacyPolicy />}
                    />
                    <Route
                        path="/provider-terms-condition"
                        element={<ProviderTermsAndConditions />}
                    />
                    <Route
                        path="/earnings-tracking"
                        element={<EarningsTrackings />}
                    />
                    {/* <Route
                        path="/subscription-plan"
                        element={<Subscription />}
                    /> */}
                    <Route
                        path="/reviews-ratings"
                        element={<ProviderRatingReviews />}
                    />
                </Route>
                <Route path="/payment-success" element={<PaymentSuccess />} />
                <Route path="/payment-error" element={<PaymentError />} />
            </Route>

            <Route path="/sitemap.xml" element={<Sitemap />} />
            <Route path="/block" element={<Blocked />} />
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    );
};

export default AllRoutes;
