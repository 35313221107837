import Title from "../../../components/title/Title";
import TaskCard from "../../../components/Cards/TaskCard/TaskCard";
import "./BidSubmission.css";
// import SuccessModal from "../../../components/Modal/Success/SuccessModal";
import Modal from "react-bootstrap/Modal";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import axios from "axios";
import Loader from "../../../components/Loader/Loader";
import currect from "../../../assets/images/currect.png";
import { useTranslation } from "react-i18next";
import { decode } from "base-64";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const BidSubmission = () => {
    const { t } = useTranslation();
    const { bidSubmissionId } = useParams();
    const decodedId = decode(bidSubmissionId);
    const [formData, setFormData] = useState({
        taskId: decodedId,
        praposedPrice: "",
        description: "",
    });
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [bidDetails, setBidDetails] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "hours" && !/^\d*$/.test(value)) {
            return;
        }
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let response;
        try {
            const postData = {
                taskId: formData.taskId,
                praposedPrice: formData.praposedPrice,
                description: formData.description,
                ...(bidDetails.selectPrice === 1 && { hours: formData.hours }),
            };

            response = await axios.post(`${apiUrl}/bid/`, postData, {
                method: "POST",
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            });
            if (response.status === 200) {
                toast.success(t("Bid submitted successfully"));
                setShow(true);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            if (error.response) {
                toast.error(error.response.data.message);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setLoading(true);
        axios
            .get(`${apiUrl}/tasker/tasks?taskId=${decodedId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then((response) => {
                setBidDetails(response.data.data);
            })
            .catch((error) => {
                toast.error("Error fetching task details:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [decodedId]);

    const handleNavigateClick = () => {
        setShow(false);
        navigate("/home-provider");
    };

    return (
        <div className="bid-submission-wrapper flex-1">
            {loading && <Loader />}
            <div className="container">
                <div className="col-sm-10 offset-sm-1">
                    <Title
                        title={t("Bid Submission")}
                        location="/home-provider"
                    />
                    <div className="main-grid-2 my-5">
                        <TaskCard
                            bidDetails={bidDetails}
                            bidSubmissionCard={true}
                        />
                        <div className="bid-submission-from">
                            <form className="box p-4" onSubmit={handleSubmit}>
                                <input
                                    type="text"
                                    value={formData.taskId}
                                    onChange={handleChange}
                                    name="taskId"
                                    className="d-none"
                                />
                                {bidDetails.selectPrice === 1 && (
                                    <div className="mb-4">
                                        <label
                                            htmlFor="task"
                                            className="form-label"
                                        >
                                            {t("Edit Hours")}
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder={t("Enter hours")}
                                            value={formData.hours}
                                            onChange={handleChange}
                                            name="hours"
                                            max="99999"
                                        />
                                    </div>
                                )}
                                <div className="mb-4">
                                    <label
                                        htmlFor="task"
                                        className="form-label"
                                    >
                                        {t(
                                            "Your Proposed Prices for Completing Task"
                                        )}{" "}
                                        {bidDetails.selectPrice === 1 ? (
                                            <span
                                                className="fw-bold"
                                                style={{ color: "#0D7A5F" }}
                                            >
                                                ({t("Hourly")})
                                            </span>
                                        ) : (
                                            <span
                                                className="fw-bold"
                                                style={{ color: "#0D7A5F" }}
                                            >
                                                ({t("Fixed")})
                                            </span>
                                        )}
                                    </label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder={t("Your Proposed Price")}
                                        value={formData.praposedPrice}
                                        onChange={handleChange}
                                        name="praposedPrice"
                                    />
                                </div>
                                <div className="mb-5">
                                    <label
                                        htmlFor="task"
                                        className="form-label"
                                    >
                                        {t("Description")}
                                    </label>
                                    <textarea
                                        className="form-control"
                                        rows={4}
                                        placeholder={t("Write Here")}
                                        value={formData.description}
                                        onChange={handleChange}
                                        name="description"
                                    />
                                </div>
                                <button className="coman-btn-fill">
                                    {t("Submit Bid")}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {show && (
                <Modal
                    show={show}
                    backdrop="static"
                    keyboard={false}
                    centered
                    className="successmodal"
                >
                    <Modal.Header className="border-0">
                        <h1 className="modal-title m-auto">{t("Success!")}</h1>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <img
                            src={currect}
                            className="image mb-4"
                            alt="currect"
                        />
                        <p className="mb-0">
                            {t("Your booking has been Submitted.")}
                        </p>
                    </Modal.Body>
                    <Modal.Footer className="border-0">
                        <button
                            type="button"
                            className="coman-btn-fill"
                            onClick={handleNavigateClick}
                        >
                            {t("Ok")}
                        </button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );
};

export default BidSubmission;
