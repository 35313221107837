import { useTranslation } from "react-i18next";
import PrivacyPolicy from "../components/PrivacyPolicy";
import Title from "../components/title/Title";
import { Helmet } from "react-helmet";

const Privacy = () => {
    const { t } = useTranslation();
    return (
        <div className="container privacy-policy-container flex-1">
            <Helmet>
                <title>
                    Poslic Privacy Policy: Your Data Protection Guidelines
                </title>
                <meta
                    name="description"
                    content="Discover Poslic's comprehensive Privacy Policy(2024), ensuring your data protection and transparency in our services. Stay informed and secure with us."
                />
                <meta
                    name="keywords"
                    content="privacy policy,security and data protection,data loss prevention,data security,privacy policy for website,cookie policy,data protection policy,Poslic Privacy Policy,personal data protection"
                />
            </Helmet>
            <div className="col-lg-10 offset-lg-1">
                <Title title={t("Poslic Privacy Policy")} location="/" />
                <PrivacyPolicy />
            </div>
        </div>
    );
};

export default Privacy;
