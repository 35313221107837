import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import filter from "../../../assets/images/Filter.png";
import axios from "axios";
import { toast } from "sonner";
import PlacesAutocomplete from "react-places-autocomplete";
import "./Filter.css";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../context/LanguageContext";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const FilterModal = ({ onApplyFilters }) => {
    const { t } = useTranslation();
    const { selectedLanguage } = useLanguage();
    const [show, setShow] = useState(false);

    const [modalFilters, setModalFilters] = useState({
        location: "",
        serviceId: "",
        selectPrice: {},
    });

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [service, setService] = useState([]);

    const handleApply = () => {
        onApplyFilters(modalFilters);
        setShow(false);
    };

    useEffect(() => {
        axios
            .get(`${apiUrl}/data/browse-task`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then((response) => {
                setService(response.data.data);
            })
            .catch((error) => {
                toast.error("fetching error", error);
            });
    }, []);
    return (
        <>
            <button
                className="d-flex justify-content-center align-items-center filter-btn"
                onClick={handleShow}
            >
                <img src={filter} className="filter-icon" alt="filter" />
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                className="logoutModal filter-modal"
                dialogClassName="my-modal"
            >
                <Modal.Body className="px-5 pt-4">
                    <h1 className="text-center mb-4">{t("Filter")}</h1>
                    <div>
                        <div className="mb-3">
                            <label htmlFor="location" className="form-label">
                                {t("Location")}
                            </label>
                            <PlacesAutocomplete
                                value={modalFilters.location}
                                onChange={(newValue) =>
                                    setModalFilters({
                                        ...modalFilters,
                                        location: newValue,
                                    })
                                }
                                onSelect={(address) => {
                                    setModalFilters({
                                        ...modalFilters,
                                        location: address,
                                    });
                                }}
                            >
                                {({
                                    getInputProps,
                                    suggestions,
                                    getSuggestionItemProps,
                                    loading,
                                }) => (
                                    <div>
                                        <input
                                            {...getInputProps({
                                                placeholder: t(
                                                    "Enter Your Location"
                                                ),
                                                className: "form-control",
                                            })}
                                        />
                                        <div className="suggestions-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map(
                                                (suggestion, index) => (
                                                    <div
                                                        {...getSuggestionItemProps(
                                                            suggestion
                                                        )}
                                                        key={index}
                                                        className="suggestion-item"
                                                    >
                                                        <span className="suggestion-text">
                                                            {
                                                                suggestion.description
                                                            }
                                                        </span>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="Service" className="form-label">
                                {t("Service")}
                            </label>
                            <select
                                className="form-select"
                                value={modalFilters.serviceId}
                                name="serviceId"
                                onChange={(e) =>
                                    setModalFilters({
                                        ...modalFilters,
                                        serviceId: e.target.value,
                                    })
                                }
                            >
                                <option value="">
                                    {t("Choose Your Service")}
                                </option>
                                {service.map((item) => (
                                    <option key={item._id} value={item._id}>
                                        {selectedLanguage === "en"
                                            ? item.task
                                            : item.serbianTask}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="selectPrice" className="form-label">
                                {t("Select Price")}
                            </label>
                            <div className="d-flex flex-wrap gap-4">
                                <div>
                                    <input
                                        className="me-2"
                                        type="radio"
                                        name="price"
                                        value={0}
                                        checked={
                                            modalFilters.selectPrice === "0"
                                        }
                                        onChange={(e) =>
                                            setModalFilters({
                                                ...modalFilters,
                                                selectPrice: e.target.value,
                                            })
                                        }
                                    />
                                    <label htmlFor="Fixed">{t("Fixed")}</label>
                                </div>
                                <div>
                                    <input
                                        className="me-2"
                                        type="radio"
                                        name="price"
                                        value={1}
                                        checked={
                                            modalFilters.selectPrice === "1"
                                        }
                                        onChange={(e) =>
                                            setModalFilters({
                                                ...modalFilters,
                                                selectPrice: e.target.value,
                                            })
                                        }
                                    />
                                    <label htmlFor="Hourly">
                                        {t("Hourly")}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-0 row px-5 pb-4">
                    <div className="col">
                        <button
                            className="coman-btn fw-medium"
                            onClick={handleClose}
                        >
                            {t("Cancel")}
                        </button>
                    </div>
                    <div className="col">
                        <button
                            className="coman-btn-fill fw-medium"
                            onClick={handleApply}
                        >
                            {t("Apply")}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default FilterModal;
