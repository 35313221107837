import Title from "../title/Title";
import "./BankDetails.css";
import { useTranslation } from "react-i18next";
import wallet from "../../assets/images/empty-wallet-tick.png";
import edit from "../../assets/images/edit-4.png";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "sonner";
import Loader from "../../components/Loader/Loader";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const Index = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [data, setData] = useState({});
    const [editBtn, setEditBtn] = useState(false);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${apiUrl}/tasker/bank-detail`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            });
            setData(response.data.data);
        } catch (error) {
            toast.error("Fetching error", error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await axios.post(
                `${apiUrl}/tasker/bank-detail`,
                { email: email },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                }
            );
            setEditBtn(false);
            fetchData();
            toast.success("Payment account successfully added");
        } catch (error) {
            toast.error("Failed: " + error.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = () => {
        setEditBtn(!editBtn);
    };

    return (
        <div className="bank-details-wrapper">
            {loading && <Loader />}
            <div className="container">
                <div className="col-sm-10 offset-sm-1">
                    <Title
                        title={t("PayPal Detail")}
                        location="/home-provider"
                    />
                    {Object.keys(data).length > 0 ? (
                        <div className="d-flex align-items-center justify-content-center mt-5">
                            <div className="d-flex align-items-center justify-content-between gap-4 box px-4 py-3">
                                <div className="d-flex align-items-center gap-3">
                                    <img
                                        className="icon"
                                        src={wallet}
                                        alt="wallet"
                                    />
                                    <p className="mb-0">{data.email}</p>
                                </div>
                                <img
                                    className="icon"
                                    src={edit}
                                    alt="edit"
                                    onClick={handleEdit}
                                />
                            </div>
                        </div>
                    ) : (
                        <form
                            className="p-md-5 p-sm-4 p-3 box-2 mt-5"
                            onSubmit={handleSubmit}
                        >
                            <label htmlFor="email" className="form-label">
                                {t("PayPal Account Email Address")}
                            </label>
                            <input
                                type="email"
                                className="form-control"
                                name="email"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                            />
                            <button
                                className="coman-btn-fill mt-5"
                                type="submit"
                            >
                                {t("Save")}
                            </button>
                        </form>
                    )}

                    {editBtn && (
                        <form
                            className="p-md-5 p-sm-4 p-3 box-2 mt-5"
                            onSubmit={handleSubmit}
                        >
                            <label htmlFor="email" className="form-label">
                                {t("PayPal Account Email Address")}
                            </label>
                            <input
                                type="email"
                                className="form-control"
                                name="email"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                            />
                            <button
                                className="coman-btn-fill mt-5"
                                type="submit"
                            >
                                {t("Save")}
                            </button>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Index;
