import background from "../../../assets/images/bg-1.png";
import hide from "../../../assets/images/Hide.png";
import show from "../../../assets/images/Show.png";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { toast } from "sonner";
import axios from "axios";
import Loader from "../../../components/Loader/Loader";
import PlacesAutocomplete from "react-places-autocomplete";
import { useTranslation } from "react-i18next";
import ReactFlagsSelect from "react-flags-select";
import { countries } from "countries-list";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const SignUp = () => {
    const { t } = useTranslation();
    const localStorageLanguage = localStorage.getItem("selectedLanguage");
    const language = localStorageLanguage === "en" ? 1 : 2;
    const serbiaCode = "RS";
    const serbiaPhoneCode = "+381";
    const [formData, setFormData] = useState({
        firstname: "",
        lastname: "",
        email: "",
        countrycode: `${serbiaCode} ${serbiaPhoneCode}`,
        mobilenumber: "",
        location: "",
        zipcode: "",
        createpassword: "",
        confirmpassword: "",
        role: "1",
        language: language,
    });

    const [visiblePassword, setVisisblePassword] = useState(false);
    const [visibleConfirm, setVisisbleConfirm] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleCountrySelect = (code) => {
        const selectedCountry = countries[code.toUpperCase()];
        if (selectedCountry) {
            setFormData({
                ...formData,
                countrycode: `${code} +${selectedCountry.phone}`,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const localStorageLanguage = localStorage.getItem("selectedLanguage");

        let languageHeaderValue = "1";
        if (localStorageLanguage === "sr") {
            languageHeaderValue = "2";
        }

        if (!formData.firstname.trim()) {
            toast.error(t("First Name Required"));
            return;
        }
        if (!formData.lastname.trim()) {
            toast.error(t("Last Name Required"));
            return;
        }
        if (!formData.email.trim()) {
            toast.error(t("Email Required"));
            return;
        }
        if (!formData.countrycode.trim()) {
            toast.error(t("Country Selected Required"));
            return;
        }
        if (!formData.mobilenumber.trim()) {
            toast.error(t("Mobile Number Required"));
            return;
        }
        const mobileNumber = formData.mobilenumber.trim();
        if (mobileNumber.length < 7 || mobileNumber.length > 10) {
            toast.error(
                t("Mobile Number should be between 7 and 10 characters")
            );
            return;
        }
        if (!formData.location.trim()) {
            toast.error(t("Location Required"));
            return;
        }
        if (!formData.zipcode.trim()) {
            toast.error(t("Zip Code Required"));
            return;
        }
        const zipCode = formData.zipcode.trim();
        if (zipCode.length < 5 || zipCode.length > 6) {
            toast.error(t("Zip code should be between 5 and 6 characters"));
            return;
        }
        const createPassword = formData.createpassword.trim();
        const passwordRegex =
            /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]).{8,}$/;

        if (!passwordRegex.test(createPassword)) {
            let errorMessage = t("Password must ");

            if (!/(?=.*[A-Z])/.test(createPassword)) {
                errorMessage += t("contain at least one uppercase letter");
            }

            if (!/(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-])/.test(createPassword)) {
                errorMessage += errorMessage.endsWith("letter")
                    ? t(" and one special character")
                    : t("contain at least one special character");
            }

            if (createPassword.length < 8) {
                errorMessage += errorMessage.endsWith("character")
                    ? t(" and be at least 8 characters long")
                    : t("contain at least 8 characters");
            }
            toast.error(t(errorMessage));
            return;
        }

        const confirmPassword = formData.confirmpassword.trim();
        if (createPassword !== confirmPassword) {
            toast.error(t("Create password and Confirm Password do not match"));
            return;
        }

        setLoading(true);
        formData.role = "1";
        let response;
        try {
            response = await axios.post(`${apiUrl}/auth/register`, formData, {
                method: "POST",
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                    language: languageHeaderValue,
                },
            });
            if (
                response.status === 200 &&
                response.data &&
                typeof response.data === "object"
            ) {
                const accessToken = response.data.data.auth.accessToken;
                const role = response.data.data.role;
                const userId = response.data.data._id;
                const isVerify = response.data.data.isVerify;
                if ({ response, accessToken }) {
                    localStorage.setItem("accessToken", accessToken);
                    localStorage.setItem("userId", userId);
                    localStorage.setItem("role", role);
                    localStorage.setItem("isVerify", isVerify);
                    localStorage.setItem("userEmail", formData.email);
                    toast.success(t("Registered Successfully"));
                    navigate("/verification");
                }
            } else if (response.status === 401 || response.status === 422) {
                toast.error("Failed: " + response.data.message);
            }
        } catch (error) {
            toast.error(error.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="position-relative choose-section py-5">
            {loading && <Loader />}
            <div className="container d-flex justify-content-center align-items-center">
                <div className="box p-3 py-4">
                    <div className="box-header mb-4">
                        <h1 className="text-center">
                            {t("Create your account")}
                        </h1>
                        <p className="mb-0 text-center">
                            {t("Create an account to get started")}.
                        </p>
                    </div>
                    <form action="" onSubmit={handleSubmit}>
                        <div className="form-group d-flex flex-column flex-sm-row mb-3">
                            <input
                                value={formData.firstname}
                                onChange={handleChange}
                                name="firstname"
                                type="text"
                                autoComplete="on"
                                className="form-control me-3 mb-3 mb-sm-0"
                                placeholder={t("First Name")}
                            />
                            <input
                                value={formData.lastname}
                                onChange={handleChange}
                                name="lastname"
                                type="text"
                                className="form-control"
                                placeholder={t("Last Name")}
                                autoComplete="on"
                            />
                        </div>
                        <div className="form-group mb-3">
                            <input
                                value={formData.email}
                                onChange={handleChange}
                                name="email"
                                type="email"
                                className="form-control"
                                placeholder={t("Email Address")}
                                autoComplete="on"
                            />
                        </div>
                        <div className="fomr-group mb-3 d-flex align-items-center position-relative gap-2">
                            <input
                                value={formData.mobilenumber}
                                onChange={handleChange}
                                name="mobilenumber"
                                type="number"
                                style={{ paddingLeft: "130px" }}
                                className="form-control"
                                placeholder={t("Mobile Phone")}
                                autoComplete="on"
                            />
                            <ReactFlagsSelect
                                searchable
                                selected={formData.countrycode.substring(0, 2)}
                                onSelect={handleCountrySelect}
                                countries={Object.keys(countries)}
                                className="menu-flags position-absolute"
                                customLabels={Object.entries(countries).reduce(
                                    (acc, [code, country]) => {
                                        acc[code] = {
                                            secondary: `+${country.phone}`,
                                        };
                                        return acc;
                                    },
                                    {}
                                )}
                                placeholder={t("Country")}
                            />
                        </div>
                        <div className="form-group mb-3">
                            <PlacesAutocomplete
                                value={formData.location}
                                onChange={(newValue) =>
                                    setFormData({
                                        ...formData,
                                        location: newValue,
                                    })
                                }
                                onSelect={async (address) => {
                                    const geocoder =
                                        new window.google.maps.Geocoder();
                                    geocoder.geocode(
                                        { address: address },
                                        (results, status) => {
                                            if (
                                                status === "OK" &&
                                                results.length > 0
                                            ) {
                                                const locationData =
                                                    results[0].geometry
                                                        .location;
                                                setFormData({
                                                    ...formData,
                                                    location: address,
                                                    lat: locationData
                                                        .lat()
                                                        .toString(), // Convert to string
                                                    long: locationData
                                                        .lng()
                                                        .toString(),
                                                });
                                            }
                                        }
                                    );
                                }}
                            >
                                {({
                                    getInputProps,
                                    suggestions,
                                    getSuggestionItemProps,
                                    loading,
                                }) => (
                                    <div>
                                        <input
                                            {...getInputProps({
                                                placeholder: t(
                                                    "Enter Your Location"
                                                ),
                                                className: "form-control",
                                            })}
                                        />
                                        <div className="suggestions-container">
                                            {loading && (
                                                <div>{t("Loading")}...</div>
                                            )}
                                            {suggestions.map(
                                                (suggestion, index) => (
                                                    <div
                                                        {...getSuggestionItemProps(
                                                            suggestion
                                                        )}
                                                        key={index}
                                                        className="suggestion-item"
                                                    >
                                                        <span className="suggestion-text">
                                                            {
                                                                suggestion.description
                                                            }
                                                        </span>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                        </div>
                        <div className="form-group mb-3">
                            <input
                                value={formData.zipcode}
                                onChange={handleChange}
                                name="zipcode"
                                type="number"
                                className="form-control"
                                placeholder={t("Zip Code")}
                                autoComplete="on"
                            />
                        </div>
                        <div className="form-group position-relative mb-3">
                            <input
                                value={formData.createpassword}
                                onChange={handleChange}
                                name="createpassword"
                                type={visiblePassword ? "text" : "password"}
                                className="form-control pe-5"
                                placeholder={t("Create Password")}
                                autoComplete="on"
                            />
                            <div
                                onClick={() =>
                                    setVisisblePassword(!visiblePassword)
                                }
                            >
                                {visiblePassword ? (
                                    <img
                                        className="eye-icon"
                                        src={show}
                                        alt="show"
                                    />
                                ) : (
                                    <img
                                        className="eye-icon"
                                        src={hide}
                                        alt="hide"
                                    />
                                )}
                            </div>
                        </div>
                        <div className="form-group position-relative mb-3">
                            <input
                                value={formData.confirmpassword}
                                onChange={handleChange}
                                name="confirmpassword"
                                type={visibleConfirm ? "text" : "password"}
                                className="form-control"
                                placeholder={t("Confirm Password")}
                                autoComplete="on"
                            />
                            <div
                                onClick={() =>
                                    setVisisbleConfirm(!visibleConfirm)
                                }
                            >
                                {visibleConfirm ? (
                                    <img
                                        className="eye-icon"
                                        src={show}
                                        alt="show"
                                    />
                                ) : (
                                    <img
                                        className="eye-icon"
                                        src={hide}
                                        alt="hide"
                                    />
                                )}
                            </div>
                        </div>

                        <div className="box-footer">
                            <p className="mb-4 text-center px-0 mx-0 px-md-3 mx-md-3">
                                {t(
                                    "By clicking below and creating an account, I agree to Poslić"
                                )}{" "}
                                <Link
                                    to="/terms"
                                    className="links"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {t("Terms of Use")}
                                </Link>{" "}
                                {t("and")}{" "}
                                <Link
                                    to="/privacy"
                                    className="links"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {t("Privacy Policy")}.
                                </Link>
                            </p>
                            <button
                                type="submit"
                                className="coman-btn-fill mb-4"
                            >
                                {t("Create account")}
                            </button>
                            <h6 className="text-center">
                                {t("Already have an account")}?{" "}
                                <Link to="/login" className="links">
                                    {t("Log in")}
                                </Link>
                            </h6>
                        </div>
                    </form>
                </div>
            </div>
            <img
                src={background}
                className="w-100 position-absolute top-0 start-0 bg-image h-100 object-fit-cover z-n1"
                alt="background"
            />
        </div>
    );
};

export default SignUp;
