import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLanguage } from "../../context/LanguageContext";
import { encode } from "base-64";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const SelectServiceButton = ({ SelectServiceLink }) => {
    const { selectedLanguage } = useLanguage();
    const [userBookingId, setUserBookingId] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        if (userBookingId) {
            const serviceLink = `/plumbing/${encode(
                SelectServiceLink._id
            )}/${encode(userBookingId)}`;
            navigate(serviceLink);
        }
    }, [SelectServiceLink._id, navigate, userBookingId]);
    const handleResultShow = () => {
        const payload = {
            step: 1,
            serviceId: SelectServiceLink._id,
        };
        axios
            .post(`${apiUrl}/booking/search`, payload, {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                const id = response.data.data._id;
                setUserBookingId(id);
            })
            .catch((error) => {
                // console.error("API Error:", error);
            });
    };

    return (
        <Link to="#" className="button" onClick={handleResultShow}>
            {selectedLanguage === "en"
                ? SelectServiceLink.task
                : SelectServiceLink.serbianTask}
        </Link>
    );
};

export default SelectServiceButton;
