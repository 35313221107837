import { useState, useEffect } from "react";
import arrowIcon from "../assets/images/up-arrow.png";

const GoToTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    const goToBtn = () => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    };

    const listenToScroll = () => {
        let heightToHidden = 20;
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop;

        if (winScroll > heightToHidden) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () => window.removeEventListener("scroll", listenToScroll);
    }, []);

    return (
        <div>
            {isVisible && (
                <div
                    className="rounded-circle position-fixed"
                    onClick={goToBtn}
                    style={{
                        bottom: "3%",
                        right: "2%",
                        width: "3rem",
                        height: "3rem",
                        background: "#0D7A5F",
                        zIndex: 9999999,
                        cursor: "pointer",
                        transition: "all 1s",
                    }}
                >
                    <img
                        src={arrowIcon}
                        alt="images"
                        className="img-fluid p-3"
                    />
                </div>
            )}
        </div>
    );
};

export default GoToTop;
