import React, { useEffect, useState } from "react";
import "./PlumbingHelp.css";
// import { Link } from "react-router-dom";
import leftArrow from "../../../assets/images/left-arrow.png";
import PlumbingHelp from "./PlumbingHelp";
import TaskAndPrice from "./TaskAndPrice";
import ChooseDateTime from "./ChooseDateTime";
import PaymentMethod from "./PaymentMethod";
import { useNavigate } from "react-router-dom";
import ConfirmDetails from "./ConfirmDetails";
import { useTranslation } from "react-i18next";

const CustomHorizontalStepper = ({ steps, activeStep, setActiveStep }) => {
    const { t } = useTranslation();
    const getProgressBarClass = () => {
        if (activeStep === 1) {
            return "green-progress-25";
        } else if (activeStep === 2) {
            return "green-progress-50";
        } else if (activeStep === 3) {
            return "green-progress-75";
        } else if (activeStep === 4) {
            return "green-progress-100";
        } else {
            return "";
        }
    };
    useEffect(() => {
        document.documentElement.style.setProperty(
            "--step-1-content",
            JSON.stringify(t("Describe Your Task"))
        );
        document.documentElement.style.setProperty(
            "--step-2-content",
            JSON.stringify(t("Browse Taskers & prices"))
        );
        document.documentElement.style.setProperty(
            "--step-3-content",
            JSON.stringify(t("Choose Date & Time"))
        );
        document.documentElement.style.setProperty(
            "--step-4-content",
            JSON.stringify(t("Choose Payment"))
        );
        document.documentElement.style.setProperty(
            "--step-5-content",
            JSON.stringify(t("Confirm Details"))
        );
    }, [t]);

    return (
        <div className="custom-horizontal-stepper">
            {steps.map((step, index) => (
                <div
                    key={index}
                    className={`step ${index === activeStep ? "active" : ""} ${
                        index < activeStep ? "completed" : ""
                    }`}
                    // onClick={() => setActiveStep(index)}
                >
                    {step}
                </div>
            ))}
            <div className={`progress-bar ${getProgressBarClass()}`}>
                <div className="indicator"></div>
            </div>
            <div className="progress-bar-2"></div>
        </div>
    );
};

const PlumbingHelpSearch = () => {
    const [activeStep, setActiveStep] = useState(() => {
        const storedStep = localStorage.getItem("activeStep");
        return storedStep ? Number(storedStep) : 0;
    });

    const navigate = useNavigate();

    const steps = ["1", "2", "3", "4", "5"];

    const handlePlumbingHelpResponse = () => {
        setActiveStep(1);
    };

    const handlePlumbingHelpResponseTwo = () => {
        setActiveStep(2);
    };

    const handlePlumbingHelpResponseThree = () => {
        setActiveStep(3);
    };

    const handlePlumbingHelpResponseFour = () => {
        setActiveStep(4);
    };

    const handleBack = () => {
        if (activeStep > 0) {
            setActiveStep(activeStep - 1);
        } else {
            navigate("/home");
        }
    };

    return (
        <div className="container plumbing-container flex-1">
            <div className="col-6 offset-3">
                <div className="position-relative mt-4">
                    {activeStep > -1 && (
                        <button
                            className="border-0 bg-white"
                            onClick={handleBack}
                        >
                            <img
                                className="arrow"
                                src={leftArrow}
                                alt="leftArrow"
                            />
                        </button>
                    )}
                    <CustomHorizontalStepper
                        steps={steps}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                    />
                </div>
            </div>

            {activeStep === 0 && (
                <PlumbingHelp
                    onPlumbingHelpResponse={handlePlumbingHelpResponse}
                />
            )}
            {activeStep === 1 && (
                <TaskAndPrice
                    onPlumbingHelpResponseTwo={handlePlumbingHelpResponseTwo}
                />
            )}
            {activeStep === 2 && (
                <ChooseDateTime
                    onPlumbingHelpResponseThree={
                        handlePlumbingHelpResponseThree
                    }
                />
            )}
            {activeStep === 3 && (
                <PaymentMethod
                    handlePlumbingHelpResponseFour={
                        handlePlumbingHelpResponseFour
                    }
                />
            )}
            {activeStep === 4 && <ConfirmDetails />}
        </div>
    );
};

export default PlumbingHelpSearch;
