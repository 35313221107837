import Title from "../../../components/title/Title";
import Accordion from "../../../components/Accordion/Accordion";
import { t } from "i18next";

const Faqs = () => {
    return (
        <div className="faqs-wrapper flex-1">
            <div className="container">
                <div className="col-lg-10 offset-lg-1">
                    <Title title="FAQs" location="/home" />
                    <Accordion />
                    <p className="px-3">
                        {t(
                            "For additional questions or support, feel free to contact our customer support team. Thank you for using Poslić!"
                        )}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Faqs;
