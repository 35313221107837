import React from "react";
import StarRatings from "react-star-ratings";

function MyRatingComponent({ value }) {
    const ratingValue = value !== null ? value : 0;
    return (
        <StarRatings
            rating={ratingValue}
            starRatedColor="#FFC403"
            starEmptyColor="#E2E2E2"
            starDimension="18px"
            starSpacing="0"
            numberOfStars={5}
            name="rating"
            isAggregateRating={true}
            starHoverDimension="22px"
        />
    );
}

export default MyRatingComponent;
