import { useState } from "react";
import "./CustomerSupport.css";
import axios from "axios";
import { toast } from "sonner";
import Loader from "../../../components/Loader/Loader";
import Title from "../../../components/title/Title";
import tick from "../../../assets/images/tick-circle-2.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const CustomerSupports = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
    });
    const [isFormSubmitted, setFormSubmitted] = useState(true);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let response;
        try {
            response = await axios.post(
                `${apiUrl}/user/customer-support`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                }
            );

            if (response.status === 200) {
                setFormSubmitted(false);
            }
        } catch (error) {
            // console.error("Additonal payment service error:", error);
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error("Failed: " + error.response.data.message);
            } else {
                toast.error("Failed: " + error.message);
            }
        } finally {
            setLoading(false);
        }
    };
    return (
        <div className="customer-support-wrapper flex-1">
            <div className="container">
                <div className="col-sm-10 offset-sm-1">
                    {isFormSubmitted ? (
                        <div>
                            <Title
                                title={t("Customer Support")}
                                location="/home"
                            />
                            <div className="box p-4 ps-md-5 my-5">
                                {loading && <Loader />}
                                <form
                                    action=""
                                    className="px-0 px-lg-5"
                                    onSubmit={handleSubmit}
                                >
                                    <div className="mb-3">
                                        <label
                                            htmlFor="name"
                                            className="form-label"
                                        >
                                            {t("Name")}
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={handleChange}
                                            value={formData.name}
                                            name="name"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label
                                            htmlFor="email"
                                            className="form-label"
                                        >
                                            {t("Email")}
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            onChange={handleChange}
                                            value={formData.email}
                                            name="email"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label
                                            htmlFor="Message"
                                            className="form-label"
                                        >
                                            {t("Message")}
                                        </label>
                                        <textarea
                                            className="form-control"
                                            rows="4"
                                            onChange={handleChange}
                                            value={formData.message}
                                            name="message"
                                        />
                                    </div>
                                    <div className="mt-5">
                                        <button
                                            type="submit"
                                            className="coman-btn-fill"
                                        >
                                            {t("Send")}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    ) : (
                        <div className="successful-wrapper">
                            <div
                                className="container d-flex justify-content-center align-items-center flex-column"
                                style={{ height: "65vh" }}
                            >
                                <div className="center text-center">
                                    <img
                                        src={tick}
                                        className="mb-3"
                                        alt="images"
                                        style={{ height: "120px" }}
                                    />
                                    <h3 className="mb-3">
                                        {t(
                                            "Your request for help is submitted"
                                        )}
                                    </h3>
                                    <h6 className="mb-3">
                                        {t("We’ll get back to you soon")}
                                    </h6>
                                    <Link to="/home" className="coman-btn-fill">
                                        {t("Done")}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CustomerSupports;
