import { useState } from "react";
import axios from "axios";
import { toast } from "sonner";
import Loader from "../../../components/Loader/Loader";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../context/LanguageContext";
import Select from "react-select";
import PlacesAutocomplete from "react-places-autocomplete";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import "./TaskPosting.css";
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from "react-datepicker";
import moment from "moment";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const TaskPosting = () => {
    const { t } = useTranslation();
    const { selectedLanguage } = useLanguage();
    const [formData, setFormData] = useState({
        description: "",
        selectDate: null,
        selectTime: "",
        selectPrice: 0,
        budget: "",
        address: "",
        apartmentNo: "",
        howBigIsYourTask: "",
        serviceId: "",
        lat: "",
        long: "",
    });
    const [loading, setLoading] = useState(false);
    const [service, setService] = useState([]);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "howBigIsYourTask" && !/^\d*$/.test(value)) {
            return;
        }
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const date = moment(formData.selectDate).format("YYYY-MM-DD");
        const time = moment(formData.selectTime).format("HH:mm");
        const datetime = moment(`${date} ${time}`, "YYYY-MM-DD HH:mm");
        const timestamp = datetime.valueOf().toString();

        let response;
        try {
            response = await axios.post(
                `${apiUrl}/task/`,
                {
                    ...formData,
                    selectDate: timestamp,
                    selectTime: timestamp,
                },
                {
                    method: "POST",
                    headers: {
                        accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                }
            );
            if (
                response.status === 200 &&
                response.data &&
                typeof response.data === "object"
            ) {
                toast.success(t("Task Create Successfully"));
                navigate("/home");
            } else if (response.status === 401 || response.status === 422) {
                toast.error("Failed: " + response.data.message);
            }
        } catch (error) {
            toast.error("Failed: " + error.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        axios
            .get(`${apiUrl}/data/`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then((response) => {
                setService(response.data.data);
            })
            .catch((error) => {
                toast.error("fetching error", error);
            });
    }, []);

    const handleSelect = async (address) => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: address }, (results, status) => {
            if (status === "OK" && results.length > 0) {
                const locationData = results[0].geometry.location;
                const lat = locationData.lat().toString();
                const long = locationData.lng().toString();

                setFormData({
                    ...formData,
                    address: address,
                    lat: lat,
                    long: long,
                });
            }
        });
    };

    return (
        <div className="task-posting-wrapper my-5 flex-1">
            {loading && <Loader />}
            <div className="container">
                <div className="col-12 col-lg-8 offset-lg-2">
                    <h1 className="text-center mb-4">{t("Task Posting")}</h1>
                    <form action="" onSubmit={handleSubmit}>
                        <div className="box px-sm-5 py-sm-4 p-3 mb-4">
                            <div className="form-group mb-4">
                                <label htmlFor="service" className="mb-2">
                                    {t("Select Service")}
                                </label>
                                <Select
                                    options={service.map((item) => ({
                                        value: item._id,
                                        label:
                                            selectedLanguage === "en"
                                                ? item.task
                                                : item.serbianTask,
                                    }))}
                                    value={{
                                        value: formData.serviceId,
                                        label: formData.serviceId
                                            ? service.find(
                                                  (item) =>
                                                      item._id ===
                                                      formData.serviceId
                                              )[
                                                  selectedLanguage === "en"
                                                      ? "task"
                                                      : "serbianTask"
                                              ]
                                            : "",
                                    }}
                                    onChange={(option) =>
                                        setFormData({
                                            ...formData,
                                            serviceId: option.value,
                                        })
                                    }
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            borderRadius: "10px",
                                            border: "2px solid rgba(214, 214, 214, 0.90)",
                                            minHeight: "50px",
                                            fontSize: "18px",
                                            fontWeight: "500",
                                            opacity: "0.7",
                                            color: "#000",
                                        }),
                                    }}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="service" className="mb-2">
                                    {t("Description")}
                                </label>
                                <textarea
                                    className="form-control"
                                    value={formData.description}
                                    onChange={handleChange}
                                    name="description"
                                    rows="3"
                                ></textarea>
                            </div>
                            <div className="row mb-4">
                                <div className="form-group col-12 col-lg-6 mb-4 mb-lg-0">
                                    <label htmlFor="service" className="mb-2">
                                        {t("Select Date")}
                                    </label>
                                    <ReactDatePicker
                                        selected={formData.selectDate}
                                        onChange={(date) =>
                                            setFormData({
                                                ...formData,
                                                selectDate: date,
                                            })
                                        }
                                        dateFormat="dd/MM/yyyy"
                                        minDate={new Date()}
                                        className="form-control"
                                        wrapperClassName="date-picker-wrapper"
                                        popperClassName="date-picker-popper"
                                        calendarClassName="date-picker-calendar"
                                    />
                                </div>
                                <div className="form-group col-12 col-lg-6">
                                    <label htmlFor="service" className="mb-2">
                                        {t("Select Time")}
                                    </label>
                                    <TimePicker
                                        value={formData.selectTime}
                                        onChange={(time) =>
                                            setFormData({
                                                ...formData,
                                                selectTime: time,
                                            })
                                        }
                                        use12Hours
                                        showSecond={false}
                                        name="selectTime"
                                        minuteStep={60}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-12 col-lg-6">
                                    <label htmlFor="service" className="mb-2">
                                        {t("Select Price")}
                                    </label>
                                    <div className="d-flex align-items-center justify-content-center gap-2 gap-sm-5 price">
                                        <div className="d-flex align-items-center">
                                            <input
                                                className="me-2"
                                                type="radio"
                                                value={0}
                                                onChange={handleChange}
                                                name="selectPrice"
                                                defaultChecked
                                            />
                                            <label htmlFor="Fixed">
                                                {t("Fixed")}
                                            </label>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <input
                                                className="me-2"
                                                type="radio"
                                                value={1}
                                                onChange={handleChange}
                                                name="selectPrice"
                                            />
                                            <label htmlFor="Hourly">
                                                {t("Hourly")}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-12 col-lg-6">
                                    <label htmlFor="service" className="mb-2">
                                        {t("Budget")}
                                    </label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        value={formData.budget}
                                        onChange={handleChange}
                                        name="budget"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="box px-sm-5 py-sm-3 p-3">
                            <div className="form-group mb-4">
                                <label htmlFor="service" className="mb-2">
                                    {t("Address")}
                                </label>
                                <PlacesAutocomplete
                                    value={formData.address}
                                    onChange={(newValue) =>
                                        setFormData({
                                            ...formData,
                                            address: newValue,
                                        })
                                    }
                                    onSelect={handleSelect}
                                >
                                    {({
                                        getInputProps,
                                        suggestions,
                                        getSuggestionItemProps,
                                        loading,
                                    }) => (
                                        <div>
                                            <input
                                                {...getInputProps({
                                                    placeholder: t(
                                                        "Enter Your Location"
                                                    ),
                                                    className: "form-control",
                                                })}
                                            />
                                            <div className="suggestions-container">
                                                {loading && (
                                                    <div>{t("Loading")}...</div>
                                                )}
                                                {suggestions.map(
                                                    (suggestion, index) => (
                                                        <div
                                                            {...getSuggestionItemProps(
                                                                suggestion
                                                            )}
                                                            key={index}
                                                            className="suggestion-item"
                                                        >
                                                            <span className="suggestion-text">
                                                                {
                                                                    suggestion.description
                                                                }
                                                            </span>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="Unit" className="mb-2">
                                    {t("Unit or Apartment No.")}
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={formData.apartmentNo}
                                    onChange={handleChange}
                                    name="apartmentNo"
                                />
                            </div>
                            <div className="form-group mb-4">
                                <label htmlFor="task" className="mb-2">
                                    {t("How Big is Your Task")}
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder={t(
                                        "Enter approximate hours to complete task"
                                    )}
                                    value={formData.howBigIsYourTask}
                                    onChange={handleChange}
                                    name="howBigIsYourTask"
                                    max="99999"
                                />
                            </div>
                            <div className="mb-2">
                                <button
                                    type="submit"
                                    className="coman-btn-fill"
                                >
                                    {t("Post Now")}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default TaskPosting;
