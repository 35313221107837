import { Link, useNavigate } from "react-router-dom";
import background from "../../../assets/images/bg-1.png";
import hide from "../../../assets/images/Hide.png";
import show from "../../../assets/images/Show.png";
import { useState } from "react";
import { toast } from "sonner";
import axios from "axios";
import Loader from "../../../components/Loader/Loader";
import { useTranslation } from "react-i18next";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const TaskerLogin = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [visiblePassword, setVisisblePassword] = useState(false);
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });

    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const localStorageLanguage = localStorage.getItem("selectedLanguage");

        let languageHeaderValue = "1";
        if (localStorageLanguage === "sr") {
            languageHeaderValue = "2";
        }

        if (!formData.email.trim()) {
            toast.error(t("Email Required"));
            return;
        }
        const createPassword = formData.password.trim();
        const passwordRegex =
            /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]).{8,}$/;

        if (!passwordRegex.test(createPassword)) {
            let errorMessage = t("Password must ");

            if (!/(?=.*[A-Z])/.test(createPassword)) {
                errorMessage += t("contain at least one uppercase letter");
            }

            if (!/(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-])/.test(createPassword)) {
                errorMessage += errorMessage.endsWith("letter")
                    ? t(" and one special character")
                    : t("contain at least one special character");
            }

            if (createPassword.length < 8) {
                errorMessage += errorMessage.endsWith("character")
                    ? t(" and be at least 8 characters long")
                    : t("contain at least 8 characters");
            }
            toast.error(t(errorMessage));
            return;
        }

        setLoading(true);
        let response;
        try {
            response = await axios.post(`${apiUrl}/auth/login`, formData, {
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                    language: languageHeaderValue,
                },
            });
            const userStatus = response.data.data.isVerify;
            const service = response.data.data.checkService;
            const checkAvailability = response.data.data.checkAvailability;

            if (response.status === 200) {
                const accessToken = response.data.data.auth.accessToken;
                const userId = response.data.data._id;
                const isBlocked = response.data.data.isBlocked;
                const role = response.data.data.role;
                localStorage.setItem("accessToken", accessToken);
                localStorage.setItem("userId", userId);
                localStorage.setItem("role", role);
                if (userStatus === false) {
                    localStorage.setItem("userEmail", formData.email);
                    toast.error(
                        t("Your are not verifried , OTP sent to your email")
                    );
                    navigate("/tasker-verification");
                } else if (service === false) {
                    if (role === "2") {
                        toast.error(t("Please add your service"));
                        localStorage.setItem("isVerify", true);
                        navigate("/profile-creation");
                    } else {
                        toast.error(t("You don't have tasker account"));
                        navigate("/tasker-login");
                        localStorage.clear();
                    }
                } else if (checkAvailability === false) {
                    if (role === "2") {
                        toast.error(t("Please add your availability"));
                        localStorage.setItem("isVerify", true);
                        localStorage.setItem("checkService", true);
                        navigate("/tasker-availability");
                    } else {
                        navigate("/tasker-login");
                        toast.error(t("You don't have tasker account"));
                        localStorage.clear();
                    }
                } else {
                    if (role === "2") {
                        if (isBlocked === true) {
                            localStorage.setItem("isUserBlocked", isBlocked);
                            navigate("/block");
                            toast.error(t("You are blocked from Admin"));
                        } else {
                            localStorage.setItem("isUserBlocked", isBlocked);
                            localStorage.setItem("isVerify", true);
                            localStorage.setItem("checkService", true);
                            localStorage.setItem("checkAvailability", true);
                            navigate("/home-provider");
                            toast.success(t("You are successfully logged in"));
                        }
                    } else {
                        navigate("/login");
                        toast.error(t("You don't have tasker account"));
                        localStorage.clear();
                    }
                }
            }
        } catch (error) {
            toast.error(error.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="position-relative choose-section py-5">
            {loading && <Loader />}
            <div className="container d-flex justify-content-center align-items-center">
                <div className="box p-3 py-4">
                    <div className="box-header mb-4">
                        <h1 className="text-center">{t("Log in")}</h1>
                        <p className="mb-0 text-center">
                            {t("Please log in to continue")}.
                        </p>
                    </div>
                    <form action="" onSubmit={handleSubmit}>
                        <div className="form-group mb-3">
                            <input
                                onChange={handleChange}
                                value={formData.email}
                                name="email"
                                type="email"
                                className="form-control"
                                placeholder={t("Email Address")}
                                autoComplete="off"
                            />
                        </div>
                        <div className="form-group position-relative mb-4">
                            <input
                                value={formData.password}
                                onChange={handleChange}
                                name="password"
                                type={visiblePassword ? "text" : "password"}
                                className="form-control"
                                placeholder={t("Password")}
                                autoComplete="off"
                            />
                            <div
                                onClick={() =>
                                    setVisisblePassword(!visiblePassword)
                                }
                            >
                                {visiblePassword ? (
                                    <img
                                        className="eye-icon"
                                        src={show}
                                        alt="show"
                                    />
                                ) : (
                                    <img
                                        className="eye-icon"
                                        src={hide}
                                        alt="hide"
                                    />
                                )}
                            </div>
                        </div>
                        <button type="submit" className="coman-btn-fill mb-4">
                            {t("Log in")}
                        </button>
                        <Link
                            to="/tasker-forgot"
                            className="links d-block text-center mb-5"
                        >
                            {t("Forgot Password")}?
                        </Link>
                    </form>
                    <div className="box-footer">
                        <h6 className="text-center">
                            {t("Don’t have an account")}?{" "}
                            <Link to="/tasker-signup" className="links">
                                {t("Sign up")}
                            </Link>
                        </h6>
                    </div>
                </div>
            </div>
            <img
                src={background}
                className="w-100 position-absolute top-0 start-0 object-fit-cover z-n1 bg-image h-100"
                alt="background"
            />
        </div>
    );
};

export default TaskerLogin;
