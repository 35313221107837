import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../Hooks/useAuth";

const PublicRoutes = () => {
    const { isAuthenticated, role, isVerify, checkService, checkAvailability } =
        useAuth();
    if (isAuthenticated) {
        if (role === "1" && isVerify) {
            return <Navigate to="/home" />;
        } else if (
            role === "2" &&
            isVerify &&
            checkService &&
            checkAvailability
        ) {
            return <Navigate to="/home-provider" />;
        }
    }

    return <Outlet />;
};

export default PublicRoutes;
