import "./PlumbingHelp.css";
import dummyImage from "../../../assets/images/profile.jpg";
import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import axios from "axios";
import { toast } from "sonner";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import Loader from "../../../components/Loader/Loader";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { decode } from "base-64";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const ChooseDateTime = ({ onPlumbingHelpResponseThree }) => {
    const { t, i18n } = useTranslation();
    const selectedServiceId = localStorage.getItem("selectedServiceId");
    const [activeDate, setActiveDate] = useState(new Date());

    const [loading, setLoading] = useState(false);
    const [profileDetails, setProfileDetails] = useState(false);
    const { serviceId } = useParams();
    const { userBookingId } = useParams();
    const decodedId = decode(serviceId);
    const decodedBookingId = decode(userBookingId);
    const [availableTimes, setAvailableTimes] = useState([]);
    const [selectedTime, setSelectedTime] = useState();

    const handleDateChange = (newDate) => {
        setActiveDate(newDate);
        const date = new Date(newDate);
        const formattedDate =
            date.getTime() - date.getTimezoneOffset() * 60 * 1000;
        axios
            .get(
                `${apiUrl}/task/date-time?taskerId=${profileDetails.taskerId}&date=${formattedDate}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                }
            )
            .then((response) => {
                setSelectedTime("");
                setAvailableTimes(response.data.data);
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            });
    };

    const handleTimeChange = (event) => {
        const newTime = event.target.value;
        setSelectedTime(newTime);
    };

    useEffect(() => {
        if (decodedId) {
            setLoading(true);
            axios
                .get(
                    `${apiUrl}/task/service-detail?serviceId=${selectedServiceId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "accessToken"
                            )}`,
                        },
                    }
                )
                .then((response) => {
                    setProfileDetails(response.data.data);
                })
                .catch((error) => {
                    toast.error("Fetching error", error);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [decodedId, selectedServiceId]);

    useEffect(() => {
        if (profileDetails) {
            handleDateChange(new Date());
        }
    }, [profileDetails]);

    const handleSelectAndContinue = () => {
        if (!selectedTime) {
            toast.error(t("Please select time"));
            return;
        }

        const selectedDateTime = moment(activeDate)
            .hour(parseInt(selectedTime.slice(0, 2), 10))
            .minute(parseInt(selectedTime.slice(3, 5), 10))
            .utc();

        const timestamp = selectedDateTime.valueOf();

        axios
            .post(
                `${apiUrl}/booking/search`,
                {
                    step: 4,
                    bookingId: decodedBookingId,
                    date: timestamp.toString(),
                    time: timestamp.toString(),
                    serviceId: serviceId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                }
            )
            .then((response) => {
                if (response.status === 200) {
                    toast.success(t("Step 3 Completed"));
                    if (onPlumbingHelpResponseThree) {
                        onPlumbingHelpResponseThree();
                    }
                } else {
                    toast.error("API error: " + response.data);
                }
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            });
    };

    const formattedDate = activeDate.toLocaleDateString(undefined, {
        month: "long",
        day: "numeric",
    });

    const formattedTime = moment(selectedTime, "HH:mm").format("hh:mm A");

    return (
        <div className="date-time-container col-lg-8 col-12 offset-lg-2 offset-0 p-3 p-md-5 my-5">
            {loading && <Loader />}
            <h1 className="text-center">
                {t("Choose your task date and start time")} :
            </h1>

            <div className="row">
                <div className="col-md-8 col-12">
                    <div className="mt-4 left pe-0 pe-md-5">
                        <div className="d-flex gap-2 align-items-center flex-wrap">
                            <img
                                src={
                                    profileDetails.profileImage
                                        ? profileDetails.profileImage
                                        : dummyImage
                                }
                                className="person-img"
                                alt="person"
                            />
                            <h3 className="mb-0">
                                {profileDetails.firstname}&nbsp;
                                {profileDetails.lastname}
                            </h3>
                            <h6 className="mb-0 opacity-50">
                                ({t("Availability")})
                            </h6>
                        </div>

                        <div className="mt-3">
                            <Calendar
                                onChange={handleDateChange}
                                value={activeDate}
                                minDate={new Date()}
                                locale={i18n.language}
                            />
                        </div>

                        <div className="my-2">
                            <select
                                value={selectedTime}
                                onChange={handleTimeChange}
                                className="form-select"
                            >
                                {Array.isArray(availableTimes) &&
                                availableTimes.length > 0 ? (
                                    <>
                                        <option value="">
                                            {t("Please select time")}
                                        </option>
                                        {availableTimes.map((time) => {
                                            const formattedTime = moment(
                                                time,
                                                "HH:mm"
                                            ).format("hh:mm A");
                                            return (
                                                <option key={time} value={time}>
                                                    {formattedTime}
                                                </option>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <option value="">
                                        {t("Tasker is not available.")}
                                    </option>
                                )}
                            </select>
                        </div>

                        <div>
                            <p>
                                {t(
                                    "You can chat with your tasker, adjust task details or change task time after booking."
                                )}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-12 d-flex justify-content-center align-items-start flex-column">
                    <h5 className="mb-3">{t("Request for")} :</h5>
                    {selectedTime ? (
                        <h4 className="mb-3">
                            {formattedDate}, {formattedTime}
                        </h4>
                    ) : (
                        <h4 className="mb-3">
                            {t("Please select a date & time.")}
                        </h4>
                    )}
                    <button
                        className="coman-btn-fill"
                        onClick={handleSelectAndContinue}
                    >
                        {t("Select & Continue")}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ChooseDateTime;
