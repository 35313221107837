import leftArrow from "../../assets/images/left-arrow.png";
import rightArrow from "../../assets/images/right-arrow-2.png";
import "./PaginationTwo.css";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const range = (start, end) => {
        return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    };

    return (
        <ul className="provider-pagination pagination justify-content-center gap-3">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                <button
                    className="page-link bg-white border-0"
                    onClick={() => onPageChange(currentPage - 1)}
                >
                    <img
                        src={leftArrow}
                        alt="arrow"
                        className="small-icon-img"
                    />
                </button>
            </li>
            {range(1, totalPages).map((page) => (
                <li
                    key={page}
                    className={`page-item ${
                        currentPage === page ? "active" : ""
                    }`}
                >
                    <button
                        className="page-link"
                        onClick={() => onPageChange(page)}
                    >
                        {page}
                    </button>
                </li>
            ))}
            <li
                className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                }`}
            >
                <button
                    className="page-link bg-white border-0"
                    onClick={() => onPageChange(currentPage + 1)}
                >
                    <img
                        src={rightArrow}
                        alt="arrow"
                        className="small-icon-img"
                    />
                </button>
            </li>
        </ul>
    );
};

export default Pagination;
