import ProviderMyTaskCard from "../../../components/Cards/ProviderMyTaskCard/ProviderMyTaskCard";
import "./ProviderTask.css";
import tickCircle from "../../../assets/images/tick-circle-3.png";
import { Link } from "react-router-dom";
import ProviderCancelTaskCard from "../../../components/Cards/ProviderMyTaskCard/ProviderCancelTaskCard";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { toast } from "sonner";
import ProviderCompleteTaskCard from "../../../components/Cards/ProviderMyTaskCard/ProviderCompleteTaskCard";
import { useTranslation } from "react-i18next";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const ProviderTask = () => {
    const { t } = useTranslation();
    const [count, setCount] = useState("");
    useEffect(() => {
        axios
            .get(`${apiUrl}/tasker/task-acceptance`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then((response) => {
                setCount(response.data);
            })
            .catch((error) => {
                toast.error("Fetching error", error);
            });
    }, []);
    return (
        <div className="myTask-wrapper my-5 flex-1">
            <div className="container">
                <div className="d-flex align-items-center justify-content-between flex-column flex-lg-row gap-4 gap-lg-0">
                    <Link
                        to="/task-acceptance"
                        className="task-btn py-1 px-3 d-flex align-items-center gap-2 order-2 order-lg-0"
                    >
                        <img src={tickCircle} alt="tick" />{" "}
                        {t("Task Acceptance")}
                        {count.data > 0 && <span>{count.data}</span>}
                    </Link>
                    <h1 className="text-center mb-4 mb-sm-0 order-1 order-lg-0">
                        {t("My Tasks")}
                    </h1>
                    <ul
                        className="nav nav-pills gap-3 order-3 order-lg-0 justify-content-center"
                        id="pills-tab"
                        role="tablist"
                    >
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link active"
                                id="pills-home-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-home"
                                type="button"
                                role="tab"
                                aria-controls="pills-home"
                                aria-selected="true"
                            >
                                {t("On Going")}
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="pills-profile-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-profile"
                                type="button"
                                role="tab"
                                aria-controls="pills-profile"
                                aria-selected="false"
                            >
                                {t("Cancelled")}
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="pills-contact-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-contact"
                                type="button"
                                role="tab"
                                aria-controls="pills-contact"
                                aria-selected="false"
                            >
                                {t("Completed")}
                            </button>
                        </li>
                    </ul>
                </div>
                <div className="tab-content mt-4" id="pills-tabContent">
                    <div
                        className="tab-pane fade show active"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                        tabIndex={0}
                    >
                        <div className="grid-3">
                            <ProviderMyTaskCard />
                        </div>
                    </div>
                    <div
                        className="tab-pane fade"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                        tabIndex={0}
                    >
                        <div className="grid-3">
                            <ProviderCancelTaskCard />
                        </div>
                    </div>
                    <div
                        className="tab-pane fade"
                        id="pills-contact"
                        role="tabpanel"
                        aria-labelledby="pills-contact-tab"
                        tabIndex={0}
                    >
                        <div className="grid-3">
                            <ProviderCompleteTaskCard />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProviderTask;
