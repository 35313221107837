import "./Navbar.css";
import i18n from "../../i18n";
import { useLanguage } from "../../context/LanguageContext";
import logo from "../../assets/images/Original.png";
import { useTranslation } from "react-i18next";
import currect from "../../assets/images/currect-2.png";
import world from "../../assets/images/worldwide.png";
import { Link } from "react-router-dom";

const TaskerNavbar = () => {
    const { t } = useTranslation();
    const { selectedLanguage, setSelectedLanguage } = useLanguage();

    const handleLanguageSelect = (language) => {
        setSelectedLanguage(language);
        i18n.changeLanguage(language);
    };
    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary py-3">
            <div className="container">
                <Link
                    to="/"
                    className="navbar-brand d-flex gap-2 align-items-center"
                >
                    <img src={logo} alt="logo" />
                    <span
                        className="badge"
                        style={{
                            backgroundColor: "#0D7A5F",
                            fontSize: "16px",
                            fontWeight: "500",
                        }}
                    >
                        {t("Tasker")}
                    </span>
                </Link>
                <div className="dropdown language-dropdown">
                    <button
                        className="btn dropdown-toggle text-uppercase d-flex align-items-center gap-2"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        <img src={world} className="icon" alt="world" />{" "}
                        {selectedLanguage}
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end p-0 rounded-2 mt-2 overflow-hidden p-2">
                        <li>
                            <p
                                className={`dropdowns-list mb-0 ${
                                    selectedLanguage === "sr"
                                        ? "bg-success"
                                        : ""
                                }`}
                                onClick={() => handleLanguageSelect("sr")}
                            >
                                {selectedLanguage === "sr" && (
                                    <img
                                        src={currect}
                                        className="currect"
                                        alt="Selected"
                                    />
                                )}{" "}
                                {t("Serbian")}
                            </p>
                        </li>
                        <li>
                            <p
                                className={`dropdowns-list mb-0 ${
                                    selectedLanguage === "en"
                                        ? "bg-success"
                                        : ""
                                }`}
                                onClick={() => handleLanguageSelect("en")}
                            >
                                {selectedLanguage === "en" && (
                                    <img
                                        src={currect}
                                        className="currect"
                                        alt="Selected"
                                    />
                                )}{" "}
                                {t("English")}
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default TaskerNavbar;
