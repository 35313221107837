import React from "react";
import "./Accordion.css";
import { useTranslation } from "react-i18next";

const Accordion = () => {
    const { t } = useTranslation();
    const accordion = [
        {
            id: 1,
            title: t("How does Poslić work?"),
            desc: t(
                "Poslić is a digital platform designed to facilitate the meeting between users seeking assistance for specific tasks and local taskers offering their services. By registering on Poslić, users can post details about the task they need help with, while taskers can search for and apply to tasks that match their skills. Communication and payment arrangements are made directly between the user and the tasker, with Poslić serving as a connecting platform without directly participating in the transactions."
            ),
        },
        {
            id: 2,
            title: t("Is using Poslić free?"),
            desc: t(
                "Registration and use of Poslić are completely free for users who wish to post tasks. For taskers, Poslić offers an initial trial period after which an annual membership fee is charged. This fee provides access to the platform and the ability to apply for an unlimited number of tasks."
            ),
        },
        {
            id: 3,
            title: t("How can I update my personal information?"),
            desc: t(
                "To update your personal information, visit the 'My Profile' section and select 'Edit Profile'. Here, you can change contact information, password, profile photo, and other relevant information."
            ),
        },
        {
            id: 4,
            title: t("How do I cancel a task?"),
            desc: t(
                "Task cancellation is possible through the 'My Tasks' option. Select the task you wish to cancel and use the 'Cancel Task' option. We recommend directly communicating with the tasker about the cancellation to avoid possible misunderstandings."
            ),
        },
        {
            id: 5,
            title: t("What happens if I have a problem with a tasker?"),
            desc: t(
                "If you encounter any problems with a tasker, try to resolve the situation through direct contact first. If that doesn't resolve the issue, contact our customer support via email, phone, or live chat. Although Poslić does not directly participate in transactions, our support team is here to help you find the best possible solution."
            ),
        },
        {
            id: 6,
            title: t("How does Poslić guarantee transaction security?"),
            desc: t(
                "While Poslić does not manage financial transactions directly between users and taskers, the security of our community is extremely important to us. We promote transparency and trust among users through a rating and review system. Additionally, we provide guidelines and advice for safe communication and payment between users and taskers. For added security, we advise that payments be made through verified and secure payment methods outside of our platform."
            ),
        },
    ];
    return (
        <div className="accordion accordion-flush my-5" id="accordion">
            {accordion.map((item) => (
                <div className="accordion-item" key={item.id}>
                    <h2 className="accordion-header" id={`heading${item.id}`}>
                        <button
                            className="accordion-button collapsed py-4"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${item.id}`}
                            aria-expanded="false"
                            aria-controls={`collapse${item.id}`}
                        >
                            {item.title}
                        </button>
                    </h2>
                    <div
                        id={`collapse${item.id}`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`heading${item.id}`}
                        data-bs-parent="#accordion"
                    >
                        <div className="accordion-body pt-0">{item.desc}</div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Accordion;
