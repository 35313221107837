import { useTranslation } from "react-i18next";
import PlumbingDetailsCard from "../../../components/Cards/PlumbingDetailsCard/PlumbingDetailsCard";
import PlumbingHelpPaymentDetails from "../../../components/PaymentDetails/PlumbingHelpPaymentDetails";

const PaymentMethod = ({ handlePlumbingHelpResponseFour }) => {
    const { t } = useTranslation();
    return (
        <div className="confirm-detail-container mt-5">
            <div className="container">
                <p className="heading text-center my-4 w-50 m-auto">
                    {t(
                        "You’re almost done! We just need a few more details to connect you with your tasker."
                    )}
                </p>
                <div className="main-grid-2">
                    <PlumbingDetailsCard />
                    <PlumbingHelpPaymentDetails
                        handlePlumbingHelpResponseFour={
                            handlePlumbingHelpResponseFour
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default PaymentMethod;
