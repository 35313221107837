import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import "./LogoutModal.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";
import logoutImg from "../../../assets/images/power-off.png";
import Loader from "../../Loader/Loader";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const LogoutModal = ({ icon }) => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const navigate = useNavigate();

    // const isUserBlocked = localStorage.getItem("isUserBlocked");

    // useEffect(() => {
    //     if (!localStorage.getItem("accessToken")) {
    //         navigate("/login");
    //     } else if (isUserBlocked === "true") {
    //         navigate("/block");
    //     }
    // }, [navigate, isUserBlocked]);

    const handleLogout = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.get(`${apiUrl}/auth/logout`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            });
            if (response.status === 200) {
                localStorage.removeItem("accessToken");
                localStorage.removeItem("isUserBlocked");
                localStorage.removeItem("isVerify");
                localStorage.removeItem("role");
                localStorage.removeItem("userEmail");
                localStorage.removeItem("userId");
                localStorage.removeItem("selectedServiceId");
                localStorage.removeItem("lat");
                localStorage.removeItem("long");
                navigate("/");
                toast.success(response.data.message);
            }
        } catch (error) {
            toast.error(error.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {icon === "true" ? (
                <img
                    className="logout-img me-2 d-lg-none"
                    src={logoutImg}
                    alt="logoutImg"
                    onClick={handleShow}
                />
            ) : (
                <button
                    className="border-0 bg-body w-100"
                    style={{
                        color: "#000",
                        opacity: "0.8",
                        fontSize: "18px",
                        fontWeight: "500",
                    }}
                    onClick={handleShow}
                >
                    {t("Logout")}
                </button>
            )}

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                className="logoutModal"
                dialogClassName="my-modal"
            >
                {loading && <Loader />}
                <Modal.Body className="px-5 pt-4">
                    <h1 className="text-center mb-4">{t("Logout")}</h1>
                    <h5 className="text-center">
                        {t("Are you sure want to logout?")}
                    </h5>
                </Modal.Body>
                <Modal.Footer className="border-0 row px-5 pb-4">
                    <div className="col">
                        <button
                            className="coman-btn fw-medium"
                            onClick={handleClose}
                        >
                            {t("Cancel")}
                        </button>
                    </div>
                    <div className="col">
                        <button
                            className="coman-btn-fill fw-medium"
                            onClick={handleLogout}
                        >
                            {t("Logout")}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default LogoutModal;
