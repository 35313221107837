import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/style/index.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { LanguageProvider } from "./context/LanguageContext";
import Routing from "./Routes";
import axios from "axios";

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            localStorage.clear();
            window.location.href = "/login";
        }
        return Promise.reject(error);
    }
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <LanguageProvider>
        <I18nextProvider i18n={i18n}>
            <Routing />
        </I18nextProvider>
    </LanguageProvider>
);
