import axios from "axios";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const PaymentSuccess = () => {
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);

        const planId = localStorage.getItem("planId");

        const requestData = {
            planId: planId,
            orderId: urlParams.get("ORDERID"),
            shopId: urlParams.get("SHOPID"),
            authNumber: urlParams.get("AUTHNUMBER"),
            amount: urlParams.get("AMOUNT"),
            currency: urlParams.get("CURRENCY"),
            transactionId: urlParams.get("TRANSACTIONID"),
            accountingMode: urlParams.get("ACCOUNTINGMODE"),
            result: urlParams.get("RESULT"),
            transactionType: urlParams.get("TRANSACTIONTYPE"),
            panAlias: urlParams.get("PANALIAS"),
            panAliaserv: urlParams.get("PANALIASREV"),
            panAliasExpDate: urlParams.get("PANALIASEXPDATE"),
            panAliasTatl: urlParams.get("PANALIASTAIL"),
            maskedPan: urlParams.get("MASKEDPAN"),
            trecurr: urlParams.get("TRECURR"),
            crecurr: urlParams.get("CRECURR"),
            panTatl: urlParams.get("PANTAIL"),
            panExpiryDate: urlParams.get("PANEXPIRYDATE"),
            cardType: urlParams.get("CARDTYPE"),
            network: urlParams.get("NETWORK"),
            mac: urlParams.get("MAC"),
        };

        axios
            .post(`${apiUrl}/payment/response`, requestData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then((response) => {
                console.log(
                    "Payment response submitted successfully:",
                    response.data
                );
            })
            .catch((error) => {
                console.error("Error submitting payment response:", error);
            });
    }, []);
    return (
        <div className="container vh-100 d-flex flex-column justify-content-center align-items-center text-center">
            <h2 className="mb-3">Payment Successful</h2>
            <p>Thank you for your payment. Your transaction was successful.</p>
            <Link
                className="my-5 bg-success p-3 rounded text-white"
                to="/home-provider"
            >
                Go to home page
            </Link>
        </div>
    );
};

export default PaymentSuccess;
