import { useTranslation } from "react-i18next";
import servicesBg from "../assets/images/services-bg.jpg";
import card1 from "../assets/images/service1.jpg";
import card2 from "../assets/images/service2.jpg";
import card3 from "../assets/images/service3.jpg";
import card4 from "../assets/images/service4.jpg";
import card5 from "../assets/images/service5.jpg";
import card6 from "../assets/images/service6.jpg";
import card7 from "../assets/images/service7.jpg";
import card8 from "../assets/images/service8.jpg";
import card9 from "../assets/images/service9.jpg";
import card10 from "../assets/images/service10.jpg";
import card11 from "../assets/images/service11.jpg";
import card12 from "../assets/images/service12.jpg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Services = () => {
    const { t } = useTranslation();

    const servicesData = [
        {
            title: t("Featured Tasks"),
            image: card1,
            description: t("Let us help tackle your to-do list"),
            tasks: [
                t("Furniture Assembly"),
                t("TV Mounting"),
                t("Home Repairs"),
                t("Help Moving"),
                t("Heavy Lifting"),
                t("Home Cleaning"),
                t("Spring Cleaning"),
                t("Personal Assistant"),
                t("Yard Work Services"),
                t("Wait in Line"),
            ],
        },
        {
            title: t("Handyman"),
            image: card2,
            description: t("Repairs and home improvements"),
            tasks: [
                t("Home Repairs"),
                t("Furniture Assembly"),
                t("TV Mounting"),
                t("Heavy Lifting"),
                t("Install/Service Air Conditioner"),
                t("Painting"),
                t("Plumbing"),
                t("Shelf Mounting"),
                t("Home Maintenance"),
                t("Wardrobe Assembly"),
                t("Hanging Curtains & Installing Blinds"),
                t("Yard Work Services"),
                t("Light Installation"),
                t("Electrical Help"),
                t("Carpentry Services"),
                t("General Mounting"),
                t("Wallpapering Service"),
                t("Fence Installation & Repair Services"),
                t("Doorbell Installation"),
                t("Aerial Work"),
                t("Renovation and Construction"),
                t("Glazier category"),
                t("Excavator Services"),
                t("Towing service"),
            ],
        },
        {
            title: t("Moving Services"),
            image: card3,
            description: t("Stress-free from packing to unpacking"),
            tasks: [
                t("Help Moving"),
                t("Packing Services & Help"),
                t("Unpacking Services"),
                t("Heavy Lifting"),
                t("Junk Pickup"),
                t("One Item Movers"),
                t("Storage Unit Moving"),
                t("Mattress Pick-Up & Removal"),
                t("Furniture Removal"),
                t("Appliance Removal"),
                t("Heavy Furniture Moving"),
                t("Rearrange Furniture"),
                t("Full Service Help Moving"),
                t("Appliance Repair"),
            ],
        },
        {
            title: t("Cleaning"),
            image: card8,
            description: t("A clean home for a happier life"),
            tasks: [
                t("House Cleaning Services"),
                t("Deep Cleaning"),
                t("Disinfecting Services"),
                t("Move In Cleaning"),
                t("Move Out Cleaning"),
                t("Carpet Cleaning Service"),
                t("Garage Cleaning"),
                t("One Time Cleaning Services"),
                t("Car Washing"),
                t("Laundry"),
                t("Pressure Washing"),
                t("Spring Cleaning"),
            ],
        },
        {
            title: t("Shopping + Delivery"),
            image: card9,
            description: t("Your shopping, our concern"),
            tasks: [
                t("Delivery Service"),
                t("Grocery Shopping & Delivery"),
                t("Running Your Errands"),
                t("Wait in Line"),
                t("Deliver Big Piece of Furniture"),
                t("Pet Food Delivery"),
                t("Baby Food Delivery"),
                t("Return Items"),
                t("Wait for Delivery"),
                t("Shipping"),
                t("Breakfast Delivery"),
                t("Coffee Delivery"),
            ],
        },
        {
            title: t("Yardwork Services"),
            image: card7,
            description: t("Manage your yard without the hassle"),
            tasks: [
                t("Gardening Services"),
                t("Weed Removal"),
                t("Lawn Care Services"),
                t("Lawn Mowing Services"),
                t("Landscaping Services"),
                t("Gutter Cleaning"),
                t("Tree Trimming Service"),
                t("Vacation Plant Watering"),
                t("Patio Cleaning"),
                t("Fence Installation & Repair Services"),
                t("Patio Furniture Assembly"),
                t("Fence Staining"),
                t("Lawn Fertilizer Service"),
                t("Hedge Trimming Service"),
                t("Outdoor Party Setup"),
                t("Urban Gardening Service"),
                t("Leaf Raking & Removal"),
                t("Produce Gardening"),
                t("Shed Maintenance"),
            ],
        },
        {
            title: t("Personal Assistant"),
            image: card4,
            description: t("Managing your time and tasks for you"),
            tasks: [
                t("Personal Assistant"),
                t("Running Your Errands"),
                t("Wait in Line"),
                t("Organization"),
                t("Interior Design Service"),
                t("Virtual Assistant"),
                t("Dog Walking"),
                t("Childcare"),
                t("Pet Sitting"),
                t("Elderly Care"),
            ],
        },
        {
            title: t("Help parents"),
            image: card5,
            description: t("Reliable assistance for your little ones"),
            tasks: [
                t("Setting up the nursery"),
                t("Professional childcare and babysitting"),
                t("Helping children learn and develop skills"),
                t("Organize a Room/ Organizacija prostora"),
                t("Toy assembly"),
                t("Delivery services"),
                t("Shopping"),
                t("Grocery delivery and shopping services"),
                t("Visiting nurses and professional help with baby care"),
                t("General cleaning services"),
            ],
        },
        {
            title: t("Virtual & Online Tasks"),
            image: card6,
            description: t("Your virtual assistant for all online needs"),
            tasks: [
                t("Virtual assistance"),
                t("Organization"),
                t("Data Entry"),
                t("Computer Help"),
                t("3D Modeling"),
                t("Interior Design"),
                t("Photo and Video"),
                t("Graphic Design"),
                t("Logo Design"),
                t("Presentation Design"),
                t("Internet Marketing"),
                t("Internet Advertising"),
                t("Website Development"),
                t("Mobile Applications"),
                t("Programming"),
                t("Virtual Assistants"),
                t("Web Design"),
            ],
        },
        {
            title: t("Parties & Events"),
            image: card10,
            description: t("Event planning without the stress"),
            tasks: [
                t("Sound Systems & DJ Services"),
                t("Catering Services"),
                t("Photography Services"),
                t("Decoration Services"),
                t("Santa's Home Visit"),
                t("Bartending"),
            ],
        },
        {
            title: t("Academic tasks"),
            image: card11,
            description: t("Academic support for all ages"),
            tasks: [
                t("College"),
                t("High School"),
                t("Elementary School"),
                t("Foreign Languages"),
                t("Computer Science"),
                t("Bachelor's Thesis"),
                t("Master's Thesis"),
                t("Seminars"),
                t("Translations"),
            ],
        },
        {
            title: t("Transportation Services"),
            image: card12,
            description: t("Fast and secure transport to your destination"),
            tasks: [
                t("Urban Passenger Transport"),
                t("Inter-city Passenger Transport"),
                t("Airport Transfer"),
                t("Local Parcel Delivery"),
                t("Inter-city Goods Transport"),
                t("Special Deliveries"),
                t("Customized Transport for People with Special Needs"),
                t("Medical Transport"),
                t("Special Occasion Transport"),
                t("Tourist Tours"),
                t("Business Transport"),
            ],
        },
    ];

    const mainHeading = {
        fontSize: "20px",
        fontWeight: "500",
        lineHeight: "24px",
        letterSpacing: "0em",
    };

    const smallHeading = {
        fontSize: "18px",
        fontWeight: "400",
        lineHeight: "22px",
        letterSpacing: "0em",
        color: "rgba(0, 0, 0, 0.6)",
    };

    const primaryText = {
        fontSize: "18px",
        fontWeight: "500",
        lineHeight: "22px",
        letterSpacing: "0em",
        color: "rgba(0, 0, 0, 0.8)",
        width: "fit-content",
    };
    return (
        <div>
            <Helmet>
                <title>
                    Poslic Services: Absolute Home, Office & Personal Services
                </title>
                <meta
                    name="description"
                    content="Poslic offers absolute home, office, and personal services including home cleaning, handyman, moving, yard work, and more. Your one-stop solution!"
                />
                <meta
                    name="keywords"
                    content="personal services in serbia,office service in serbia,Home services in serbia,services,home cleaning services,handyman,transportation services,movers near me,handyman near me,long distance movers,house cleaning services near me,house cleaning services,local movers near me,car transport service,vehicle transport,professional house cleaning,tv installation near me,tv mounting,yard work near me,virtual assistant data entry,repair services,car repair services,home repair services,plumbers near me,water heater repair,wardrobe installation,furniture installation,catering services,photography services,bartending,catering near me,photographers near me,wedding photographers near me,real estate photography,wedding catering near me,party decorators near me,christmas light installers,birthday decorators near me,wedding stage decoration,handyman services near me,paint and sip near me,commercial plumbing,home glass repair near me,air conditioning installation,painters and decorators,garage door repair,house maintenance services,lawn sprinkler repair,carpenter near me,carpenter service,junk trash removal,vacuum repair near me,vacuum repair service,freezer repair service ,delivery service,grocery store pick up,food delivery near me,takeaway delivery near me,door delivery,electronic shop near me,department stores,Delivery boy"
                />
            </Helmet>
            <section className="bg-image h-100">
                <div className="container d-flex justify-content-center align-items-center bg-image">
                    <h1
                        style={{ fontSize: "2rem", fontWeight: 500 }}
                        className="text-center text-light"
                    >
                        {t("Elevate Your Lifestyle with Our Services")}
                    </h1>
                </div>
                <img
                    src={servicesBg}
                    className="position-absolute w-100 bg-image h-100 top-0 start-0 object-fit-cover z-n1"
                    alt="bgimage"
                />
            </section>
            <section className="my-5 container">
                <h2 className="mb-4 coman_h2">{t("Our Services")}</h2>
                <div className="row g-4">
                    {servicesData.map((service, index) => (
                        <div className="col-xl-4 col-md-6" key={index}>
                            <div className="card border-0 p-2">
                                <img
                                    src={service.image}
                                    className="rounded-3 object-fit-cover"
                                    style={{ height: "270px" }}
                                    alt="images"
                                />
                                <div className="card-body">
                                    <h5
                                        className="card-title d-flex flex-column gap-2"
                                        style={mainHeading}
                                    >
                                        <h3
                                            style={{
                                                fontSize: "20px",
                                                fontWeight: 500,
                                            }}
                                            className="mb-0"
                                        >
                                            {service.title}
                                        </h3>
                                        <span style={smallHeading}>
                                            {service.description}
                                        </span>
                                        <hr />
                                    </h5>
                                    <div className="d-flex flex-column gap-3">
                                        {service.tasks.map((task, index) => (
                                            <Link
                                                to="/login"
                                                className="card-text"
                                                style={primaryText}
                                                key={index}
                                            >
                                                {task}
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
};

export default Services;
