import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import "./PostTaskCard.css";
import { useTranslation } from "react-i18next";
import { decode } from "base-64";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const TaskBidDetailscard = () => {
    const { t } = useTranslation();
    const { taskBidId } = useParams();
    const decodedId = decode(taskBidId);
    const [taskDetails, setTaskDetails] = useState({});
    useEffect(() => {
        if (decodedId) {
            axios
                .get(`${apiUrl}/task?taskId=${decodedId}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                })
                .then((response) => {
                    setTaskDetails(response.data.data);
                })
                .catch((error) => {
                    // console.error("Error fetching task details:", error);
                });
        }
    }, [decodedId]);
    return (
        <div className="taskbid-wrapper">
            <div className="card border-0 p-3" key={taskDetails._id}>
                <div>
                    <div className="col-12 row mb-3">
                        <div className="col-8">
                            <p className="mb-0">{t("Required Service")}</p>
                            <h3 className="mb-0">{taskDetails.task}</h3>
                        </div>
                        <div className="col-4 p-0">
                            <p className="mb-0">{t("Budget")}</p>
                            <h3 className="mb-0">
                                {taskDetails.selectPrice === 0
                                    ? `${taskDetails.budget} din`
                                    : `${taskDetails.budget} ${t("din/hr")}`}
                            </h3>
                        </div>
                    </div>
                    <div>
                        <p className="mb-0 card-title">{t("Description")}</p>
                        <p className="mb-0 text opacity-100 desc">
                            {taskDetails.description}
                        </p>
                    </div>
                </div>
                <hr />
                <div className="bids d-flex align-items-center justify-content-center">
                    <p className="mb-0">{t("No. of Bids")} &nbsp;</p>
                    <span>{taskDetails.bidCount}</span>
                </div>
            </div>
        </div>
    );
};

export default TaskBidDetailscard;
