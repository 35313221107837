import { useTranslation } from "react-i18next";
import TermsAndConditions from "../components/TermsAndConditions";
import Title from "../components/title/Title";
import { Helmet } from "react-helmet";

const Terms = () => {
    const { t } = useTranslation();
    return (
        <div className="container privacy-policy-container flex-1">
            <Helmet>
                <title>
                    Poslic Terms of use: Guidelines for Using Our Platform
                </title>
                <meta
                    name="description"
                    content="Explore Poslic's Terms of Use (2024) to understand our guidelines and policies for using our website effectively. Stay informed and compliant."
                />
                <meta
                    name="keywords"
                    content="terms and conditions,terms of service,terms of use,agreement terms and conditions,terms and conditions page,Service of terms and conditions,Poslic Terms of use,Poslic Terms and condition"
                />
            </Helmet>
            <div className="col-lg-10 offset-lg-1">
                <Title title={t("Terms of Use for Poslić")} location="/" />
                <TermsAndConditions />
            </div>
        </div>
    );
};

export default Terms;
