import { useTranslation } from "react-i18next";
import TermsAndConditions from "../../components/TermsAndConditions";
import Title from "../../components/title/Title";

const ProviderTermsAndConditions = () => {
    const { t } = useTranslation();
    return (
        <div className="container privacy-policy-container flex-1">
            <div className="col-lg-10 offset-lg-1">
                <Title
                    title={t("Terms of Use for Poslić")}
                    location="/home-provider"
                />
                <TermsAndConditions />
            </div>
        </div>
    );
};

export default ProviderTermsAndConditions;
