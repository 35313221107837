import { Link } from "react-router-dom";
import "./TaskPostDetailModal.css";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "sonner";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { encode } from "base-64";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const TaskPostDetailModal = ({ taskId, numBids, onPostDelete }) => {
    const { t } = useTranslation();

    const [show, setShow] = useState(false);
    const [taskDetails, setTaskDetails] = useState({});
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };
    // const [bidId, setBidId] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (taskId) {
            axios
                .get(`${apiUrl}/task/?taskId=${taskId}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                })
                .then((response) => {
                    setTaskDetails(response.data.data);
                })
                .catch((error) => {
                    // console.error("Error fetching task details:", error);
                });
        }
    }, [taskId]);

    const handleDeletePost = () => {
        axios
            .delete(`${apiUrl}/task/delete`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
                data: { taskId },
            })
            .then((response) => {
                if (response.status === 200) {
                    toast.success(t("Task deleted successfully"));
                    onPostDelete();
                    handleClose();
                } else {
                    toast.error("Failed to delete the task. Please try again.");
                }
            })
            .catch((error) => {
                // console.error("Error deleting task:", error);
            });
    };

    function formatDate(inputDate) {
        return moment(inputDate).format("DD/MM/YYYY");
    }

    function formatTime(inputTime) {
        if (inputTime) {
            const formattedTime = moment(inputTime).format("hh:mm A");
            return formattedTime;
        } else {
            return "N/A";
        }
    }

    return (
        <>
            <button
                type="button"
                className="coman-btn"
                onClick={handleShow}
                style={{ height: "35px", fontWeight: "500" }}
            >
                {t("View Details")}
            </button>
            <Modal
                size="lg"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="detailModal"
                centered
            >
                <Modal.Header closeButton className="border-0"></Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <div className="d-flex align-items-start flex-column flex-sm-row gap-0 gap-sm-5 ">
                            <div>
                                <h3 className="mb-2">
                                    {t("Required Service")}
                                </h3>
                                <h2>{taskDetails.task}</h2>
                            </div>
                            <div>
                                <h3 className="mb-2">{t("Budget")}</h3>
                                <h2>{`${t(
                                    "{{budget}} {{currency}} ({{priceType}})",
                                    {
                                        budget: taskDetails.budget,
                                        currency: t("din"),
                                        priceType:
                                            taskDetails.selectPrice === 0
                                                ? t("Fixed")
                                                : t("Hourly"),
                                    }
                                )}`}</h2>
                            </div>
                        </div>
                        <hr />
                        <div className="d-flex flex-wrap mb-4 gap-2">
                            <div style={{ flexGrow: 1, flexBasis: 200 }}>
                                <h4>{t("Date")}</h4>
                                <p className="mb-0">
                                    {formatDate(taskDetails.selectDate)}
                                </p>
                            </div>
                            <div style={{ flexGrow: 1, flexBasis: 200 }}>
                                <h4>{t("Time")}</h4>
                                <p className="mb-0">
                                    {formatTime(taskDetails.selectTime)}
                                </p>
                            </div>
                            <div style={{ flexGrow: 1, flexBasis: 200 }}>
                                <h4>{t("Required Time")}</h4>
                                <p className="mb-0">
                                    {taskDetails.howBigIsYourTask} {t("Hours")}
                                </p>
                            </div>
                        </div>
                        <div className="mb-4">
                            <h4>{t("Address")}</h4>
                            <p>{taskDetails.address}</p>
                        </div>
                        <div className="mb-4">
                            <h4>{t("Unit or Apartment No.")}</h4>
                            <p>{taskDetails.apartmentNo}</p>
                        </div>
                        <div>
                            <h4 className="mb-2">{t("Description")}</h4>
                            <div className="description p-3">
                                <p className="mb-0">
                                    {taskDetails.description && (
                                        <span className="mb-0 p-text">
                                            {isExpanded
                                                ? taskDetails.description
                                                : taskDetails.description.slice(
                                                      0,
                                                      150
                                                  )}
                                            {taskDetails.description.length >
                                                150 &&
                                                !isExpanded && (
                                                    <span
                                                        className="read-more"
                                                        onClick={toggleReadMore}
                                                    >
                                                        &nbsp;{t("Read More")}
                                                    </span>
                                                )}
                                            {taskDetails.description.length >
                                                150 &&
                                                isExpanded && (
                                                    <span
                                                        className="read-more"
                                                        onClick={toggleReadMore}
                                                    >
                                                        &nbsp;{t("Read Less")}
                                                    </span>
                                                )}
                                        </span>
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="text-center border-0">
                        <Link
                            to={`/bids/${encode(taskId)}`}
                            className="coman-btn-fill mb-3"
                        >
                            <span>
                                {t("No. of Bids")} : {numBids}
                            </span>
                        </Link>
                        {taskDetails.status === 1 ? (
                            <p className="text-center text-danger">
                                {t(
                                    "The service has been booked for this task, so it's not possible to delete this post."
                                )}
                            </p>
                        ) : (
                            <span
                                className="delete-btn"
                                onClick={handleDeletePost}
                            >
                                {t("Delete Post")}
                            </span>
                        )}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default TaskPostDetailModal;
