import axios from "axios";
import { useState } from "react";
import { toast } from "sonner";
import Loader from "../../../components/Loader/Loader";
import { Link, useNavigate } from "react-router-dom";
import background from "../../../assets/images/bg-1.png";
import { useTranslation } from "react-i18next";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const TaskerEditEmail = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const localStorageLanguage = localStorage.getItem("selectedLanguage");

        let languageHeaderValue = "1";
        if (localStorageLanguage === "sr") {
            languageHeaderValue = "2";
        }
        setLoading(true);
        try {
            const response = await axios.post(
                `${apiUrl}/auth/edit-email`,
                { email: email },
                {
                    headers: {
                        accept: "application/json",
                        "Content-Type": "application/json",
                        language: languageHeaderValue,
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                }
            );
            if (response.status === 200) {
                localStorage.setItem("userEmail", email);
                navigate("/tasker-verification");
                toast.success(response.data.message);
            }
        } catch (error) {
            toast.error(error.response.data.message);
        } finally {
            setLoading(false);
        }
    };
    return (
        <div className="position-relative choose-section py-5">
            {loading && <Loader />}
            <div className="container d-flex justify-content-center align-items-center">
                <div className="box p-3 py-4">
                    <div className="box-header mb-4">
                        <h1 className="text-center">{t("Edit Email")}</h1>
                    </div>
                    <form action="" onSubmit={handleSubmit}>
                        <div className="form-group mb-3">
                            <input
                                name="email"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                type="email"
                                className="form-control"
                                placeholder={t("Email Address")}
                                autoComplete="off"
                            />
                        </div>
                        <button type="submit" className="coman-btn-fill mb-4">
                            {t("Update & Send OTP")}
                        </button>
                        <div className="text-center">
                            <Link to="/tasker-verification" className="links">
                                {t("Cancel")}
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
            <img
                src={background}
                className="w-100 position-absolute top-0 start-0 object-fit-cover z-n1 bg-image h-100"
                alt="background"
            />
        </div>
    );
};

export default TaskerEditEmail;
