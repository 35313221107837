import React from "react";
import { Outlet } from "react-router-dom";
import UserNavbar from "../components/Navbar/UserNavbar";

const UserAuthLayout = () => {
    return (
        <>
            <UserNavbar />
            <Outlet />
        </>
    );
};

export default UserAuthLayout;
