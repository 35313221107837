import "./Message.css";
import dummyImage from "../../assets/images/profile.jpg";
import send from "../../assets/images/send.png";
import React, { useCallback, useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import axios from "axios";
// import CalenderModal from "../Modal/CalenderModal/CalenderModal";
import pendingChat from "../../assets/images/pendingChat.png";
import moment from "moment";
import { useTranslation } from "react-i18next";

const apiUrl = process.env.REACT_APP_API_BASE_URL;
const socketServerUrl = process.env.REACT_APP_SOCKET_SERVER;

const TaskerChat = () => {
    const { t } = useTranslation();
    const [senderId, setSenderId] = useState("");
    const [receiverId, setReceiverId] = useState("");
    const [roomId, setRoomId] = useState("");
    const [messageInput, setMessageInput] = useState("");
    const [chatList, setChatList] = useState([]);
    const [selectedUserMessages, setSelectedUserMessages] = useState([]);
    const [isChatPageVisible, setIsChatPageVisible] = useState(true);

    const [selectedUser, setSelectedUser] = useState({
        id: "",
        name: "",
        image: "",
        service: "",
        bookingId: "",
    });

    const socket = useRef(null);

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            sendMessage(messageInput);
        }
    };

    const sendMessage = useCallback(
        (message) => {
            if (message.trim() === "") {
                return;
            }
            if (socket.current && roomId) {
                socket.current.emit("sendMessage", {
                    message,
                    senderId,
                    receiverId: selectedUser.id,
                    bookingId: selectedUser.bookingId,
                    roomId,
                });

                setChatList((prevChatList) => {
                    const updatedChatList = prevChatList.map((chat) => {
                        if (
                            chat._id === selectedUser.id &&
                            chat.bookingId === selectedUser.bookingId
                        ) {
                            return {
                                ...chat,
                                chat: {
                                    message,
                                    createdAt: new Date().toISOString(),
                                },
                            };
                        }
                        return chat;
                    });

                    return updatedChatList;
                });

                socket.current.emit("ReadMessage", {
                    roomId,
                    senderId,
                    receiverId: selectedUser.id,
                    chatId: 0,
                });

                setMessageInput("");
            }
        },
        [roomId, selectedUser, senderId]
    );

    const fetchData = async (receiverId, bookingId) => {
        try {
            const response = await axios.get(`${apiUrl}/user/chat-list`, {
                params: {
                    receiverId,
                    bookingId,
                },
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            });
            setSelectedUserMessages(response.data.data);
        } catch (error) {
            // console.error("Error fetching user messages:", error);
            setSelectedUserMessages([]);
        }
    };

    const cleanupSocket = useCallback(() => {
        setIsChatPageVisible(false);

        if (socket.current) {
            socket.current.disconnect();
        }
    }, []);

    useEffect(() => {
        const connectSocket = async () => {
            const storedUserId = localStorage.getItem("userId");

            if (storedUserId) {
                setSenderId(storedUserId);
            } else {
                // console.error("User ID not found in session.");
                return;
            }

            socket.current = io.connect(socketServerUrl, {
                transports: ["websocket"],
                reconnection: true,
                reconnectionAttempts: Infinity,
                reconnectionDelay: 0,
                reconnectionDelayMax: 5000,
                randomizationFactor: 0.5,
                timeout: 10000,
            });

            socket.current.on("connect_error", (error) => {
                // console.error("WebSocket connection error:", error);
            });

            socket.current.on("connect", () => {
                if (!roomId) {
                    socket.current.emit("createRoom", {
                        senderId,
                        receiverId,
                        bookingId: selectedUser.bookingId,
                    });
                }

                socket.current.emit("UpdateStatusToOnline", {
                    senderId,
                });
            });

            socket.current.on("ReadMessage", (data) => {
                if (data && data.messages) {
                    setSelectedUserMessages(data.messages);
                }
            });

            socket.current.on(
                "roomConnected",
                (connectedRoomId, user1name, user2name, result) => {
                    setRoomId(connectedRoomId);

                    setSelectedUserMessages(result);

                    const data = {
                        roomId: connectedRoomId,
                        senderId: receiverId,
                        receiverId,
                        chatId: 0,
                    };

                    setRoomId(connectedRoomId);
                    const roomElement = document.getElementById("room_id");

                    if (roomElement) {
                        roomElement.value = roomId;
                    }

                    socket.current.emit("ReadMessage", data);
                }
            );

            socket.current.on("newMessage", (data) => {
                if (data.senderId === receiverId) {
                    socket.current.emit("ReadMessage", data);
                }

                setSelectedUserMessages((prevMessages) => {
                    // Check if there are any messages in the previous state
                    if (prevMessages.length === 0) {
                        // If there are no previous messages, this is the first message, so add it
                        return [data];
                    }

                    // If there are previous messages, check if there's any message with the same bookingId
                    const hasMessageWithSameBookingId = prevMessages.some(
                        (msg) => msg.bookingId === data.bookingId
                    );

                    // If a message with the same bookingId exists, add the new message
                    if (hasMessageWithSameBookingId) {
                        return [...prevMessages, data];
                    }

                    // If no message with the same bookingId exists, return the previous messages unchanged
                    return prevMessages;
                });

                setChatList((prevChatList) => {
                    const index = prevChatList.findIndex(
                        (chat) =>
                            chat._id === data.senderId &&
                            chat.bookingId === data.bookingId
                    );

                    if (index !== -1) {
                        const updatedChatList = [...prevChatList];
                        updatedChatList[index] = {
                            ...updatedChatList[index],
                            chat: {
                                message: data.message,
                                createdAt: data.createdAt,
                            },
                        };
                        return updatedChatList;
                    }

                    return prevChatList;
                });
            });

            socket.current.on("disconnect", () => {
                socket.current.emit("disconnected", {
                    senderId,
                    receiverId,
                    roomId,
                });
            });
            return () => {
                cleanupSocket();
            };
        };

        connectSocket();
    }, [
        cleanupSocket,
        receiverId,
        roomId,
        selectedUser.bookingId,
        senderId,
        isChatPageVisible,
    ]);

    useEffect(() => {
        const handleUnload = () => {
            cleanupSocket();
        };

        window.addEventListener("beforeunload", handleUnload);

        return () => {
            window.removeEventListener("beforeunload", handleUnload);
            cleanupSocket();
        };
    }, [cleanupSocket]);

    useEffect(() => {
        const messagesContentElement =
            document.querySelector(".messages-content");
        if (messagesContentElement) {
            messagesContentElement.scrollTop =
                messagesContentElement.scrollHeight;
        }
    }, [selectedUserMessages]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/tasker/users`, {
                    headers: {
                        accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                });
                setChatList(response.data.data);
            } catch (error) {
                // console.error("Error fetching user chat list:", error);
            }
        };

        fetchData();
    }, []);

    const renderDateHeader = (date) => {
        const today = moment().startOf("day");
        const yesterday = moment().subtract(1, "days").startOf("day");
        const messageDate = moment(date);

        if (messageDate.isSame(today, "day")) {
            return t("Today");
        } else if (messageDate.isSame(yesterday, "day")) {
            return t("Yesterday");
        } else {
            return messageDate.format("MMMM D, YYYY");
        }
    };

    const formatDate = (createdAt) => {
        const messageDate = moment(createdAt);
        const today = moment().startOf("day");
        const yesterday = moment().subtract(1, "days").startOf("day");

        if (messageDate.isSame(today, "day")) {
            return `${messageDate.format("hh:mm A")}`;
        } else if (messageDate.isSame(yesterday, "day")) {
            return `Yesterday`;
        } else {
            return messageDate.format("D/M/YYYY");
        }
    };

    return (
        <>
            <div className="message-wrapper flex-1">
                <div className="container">
                    <div className="col-xxl-10 offset-xxl-1">
                        <h1 className="text-center my-4">{t("Messages")}</h1>
                        <div className="chat mb-5">
                            <div className="chat-left p-2 p-sm-4">
                                {chatList.length === 0 ? (
                                    <div className="no-user-selected">
                                        <img
                                            src={pendingChat}
                                            alt="pending-chat"
                                        />
                                        <h1>{t("No Messages, yet")}</h1>
                                        <h4>
                                            {t(
                                                "Start booking and start your conversation"
                                            )}
                                        </h4>
                                    </div>
                                ) : (
                                    chatList.map((chat) => (
                                        <div
                                            key={`${chat._id}-${chat.bookingId}`}
                                            onClick={() => {
                                                setReceiverId(chat._id);
                                                setSelectedUser({
                                                    id: chat._id,
                                                    name: `${chat.firstname} ${chat.lastname}`,
                                                    image: chat.profileImage,
                                                    service: chat.service,
                                                    bookingId: chat.bookingId,
                                                    status: chat.status,
                                                });
                                                fetchData(
                                                    chat._id,
                                                    chat.bookingId
                                                );
                                            }}
                                        >
                                            <div className="chats d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center gap-2">
                                                    <img
                                                        src={
                                                            chat.profileImage
                                                                ? chat.profileImage
                                                                : dummyImage
                                                        }
                                                        alt="person"
                                                        className="profile-picture"
                                                    />
                                                    <div>
                                                        <h1 className="mb-1">
                                                            {chat.firstname}
                                                            &nbsp;
                                                            {chat.lastname}
                                                            &nbsp;
                                                            <span className="service-name">
                                                                ({chat.service})
                                                            </span>
                                                        </h1>
                                                        <h3
                                                            className={`mb-0 read ${
                                                                chat.count >
                                                                    0 &&
                                                                "unread"
                                                            }`}
                                                        >
                                                            {chat.chat?.message}
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column align-items-end">
                                                    {chat.chat?.createdAt && (
                                                        <h6 className="mb-1">
                                                            {formatDate(
                                                                chat.chat
                                                                    .createdAt
                                                            )}
                                                        </h6>
                                                    )}
                                                    {chat.count > 0 && (
                                                        <span className="badge rounded-circle">
                                                            {chat.count}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    ))
                                )}
                            </div>

                            <div className="chat-right position-relative">
                                {selectedUser.id ? (
                                    <>
                                        <div className="header d-flex align-items-center justify-content-between px-2 py-2 px-sm-4 py-sm-2">
                                            <div className="d-flex align-items-center gap-2">
                                                <img
                                                    src={
                                                        selectedUser.image
                                                            ? selectedUser.image
                                                            : dummyImage
                                                    }
                                                    alt="person"
                                                    className="profile-image"
                                                />
                                                <h1 className="mb-0">
                                                    {selectedUser.name}
                                                </h1>
                                                <h4 className="mb-0">
                                                    {selectedUser.service}
                                                </h4>
                                            </div>
                                            {/* <CalenderModal
                                                selectedUser={selectedUser}
                                            /> */}
                                        </div>
                                        <div className="body p-2 p-sm-4">
                                            <div className="msg-text">
                                                <div className="messages">
                                                    <div
                                                        className="messages-content"
                                                        id="messageTest"
                                                    >
                                                        {selectedUserMessages.map(
                                                            (msg, index) => {
                                                                const currentDate =
                                                                    renderDateHeader(
                                                                        msg.createdAt
                                                                    );
                                                                const isSameDateAsLastMessage =
                                                                    index > 0 &&
                                                                    moment(
                                                                        msg.createdAt
                                                                    ).isSame(
                                                                        selectedUserMessages[
                                                                            index -
                                                                                1
                                                                        ]
                                                                            .createdAt,
                                                                        "day"
                                                                    );

                                                                return (
                                                                    <React.Fragment
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {!isSameDateAsLastMessage && (
                                                                            <div className="date-header">
                                                                                {
                                                                                    currentDate
                                                                                }
                                                                            </div>
                                                                        )}
                                                                        <div
                                                                            className={`col-12 mb-2 ${
                                                                                msg.senderId ===
                                                                                senderId
                                                                                    ? "message-chat-end"
                                                                                    : "message-chat-start"
                                                                            }`}
                                                                        >
                                                                            <div
                                                                                className={
                                                                                    msg.senderId ===
                                                                                    senderId
                                                                                        ? "order-1 right-side-chat"
                                                                                        : "left-side-chat"
                                                                                }
                                                                            >
                                                                                <span>
                                                                                    {
                                                                                        msg.message
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            <div className="time-stamp">
                                                                                {moment
                                                                                    .utc(
                                                                                        msg.createdAt
                                                                                    )
                                                                                    .local()
                                                                                    .format(
                                                                                        "hh:mm A"
                                                                                    )}
                                                                            </div>
                                                                        </div>
                                                                    </React.Fragment>
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {selectedUser.status !== 3 && (
                                            <div className="footer p-4">
                                                <div className="position-relative">
                                                    <input
                                                        className="action-box-input"
                                                        type="text"
                                                        id="message"
                                                        value={messageInput}
                                                        placeholder={t(
                                                            "Type message..."
                                                        )}
                                                        onChange={(e) =>
                                                            setMessageInput(
                                                                e.target.value
                                                            )
                                                        }
                                                        onKeyDown={
                                                            handleKeyDown
                                                        }
                                                    />
                                                    <img
                                                        src={send}
                                                        alt="send"
                                                        className="action-box-submit send"
                                                        id="send_message"
                                                        onClick={() =>
                                                            sendMessage(
                                                                messageInput
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <div className="no-user-selected">
                                        <img
                                            src={pendingChat}
                                            alt="pending-chat"
                                        />
                                        <h1>{t("Get started")}</h1>
                                        <h4>
                                            {t(
                                                "Pick a user and start your conversation"
                                            )}
                                        </h4>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TaskerChat;
