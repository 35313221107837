import "./ProfileCreation.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "sonner";
import Loader from "../../../components/Loader/Loader";
import deleteIcon from "../../../assets/images/Delete.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../context/LanguageContext";
import Select from "react-select";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const ProfileCreation = () => {
    const { t } = useTranslation();
    const { selectedLanguage } = useLanguage();
    const [loading, setLoading] = useState(false);
    const [service, setService] = useState([]);
    const [formArray, setFormArray] = useState([
        {
            serviceId: "",
            aboutMe: "",
            skillsAndExperience: "",
            minimumhours: "",
            rate: "",
        },
    ]);

    const navigate = useNavigate();

    useEffect(() => {
        axios
            .get(`${apiUrl}/data/filter`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then((response) => {
                setService(response.data.data);
            })
            .catch((error) => {
                toast.error("fetching error", error);
            });
    }, []);

    const handleChange = (e, index) => {
        const updatedFormArray = [...formArray];
        updatedFormArray[index] = {
            ...updatedFormArray[index],
            [e.target.name]: e.target.value,
        };
        setFormArray(updatedFormArray);
    };

    const addForm = () => {
        setFormArray([
            ...formArray,
            {
                serviceId: "",
                aboutMe: "",
                skillsAndExperience: "",
                minimumhours: "",
                rate: "",
            },
        ]);
    };

    const deleteForm = (index) => {
        const updatedFormArray = [...formArray];
        updatedFormArray.splice(index, 1);
        setFormArray(updatedFormArray);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let response;
        try {
            response = await axios.post(`${apiUrl}/tasker/`, formArray, {
                method: "POST",
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            });
            if (
                response.status === 200 &&
                response.data &&
                typeof response.data === "object"
            ) {
                if (response) {
                    toast.success(t("Service Create Successfully"));
                    localStorage.setItem("checkService", true);
                    navigate("/tasker-availability");
                }
            } else if (response.status === 401 || response.status === 422) {
                toast.error("Failed: " + response.data.message);
            }
        } catch (error) {
            toast.error(error.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="profile-creation-wrapper pb-5">
            {loading && <Loader />}
            <div className="container">
                <div className="col-sm-10 offset-sm-1">
                    <h1 className="text-center my-5">
                        {t("Add Your Services")}
                    </h1>
                    {formArray.map((form, index) => (
                        <div
                            key={index}
                            className="box p-5 mb-5 position-relative"
                        >
                            {formArray.length > 1 && (
                                <button
                                    onClick={() => deleteForm(index)}
                                    className="delete-icon"
                                >
                                    <img
                                        src={deleteIcon}
                                        className="delete-icon"
                                        alt="delete"
                                    />
                                </button>
                            )}
                            <form action="" id={`serverForm-${index}`}>
                                <div className="mb-3">
                                    <label
                                        htmlFor="service"
                                        className="form-label"
                                    >
                                        {t("Select Your Service")}
                                    </label>
                                    <Select
                                        value={service.find(
                                            (item) =>
                                                item.value === form.serviceId
                                        )}
                                        onChange={(selectedOption) =>
                                            handleChange(
                                                {
                                                    target: {
                                                        name: "serviceId",
                                                        value: selectedOption?.value,
                                                    },
                                                },
                                                index
                                            )
                                        }
                                        options={service.map((item) => ({
                                            value: item._id,
                                            label:
                                                selectedLanguage === "en"
                                                    ? item.task
                                                    : item.serbianTask,
                                        }))}
                                        placeholder={t("Choose Your Service")}
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                borderRadius: "10px",
                                                border: "2px solid rgba(214, 214, 214, 0.90)",
                                                minHeight: "50px",
                                                fontSize: "18px",
                                                fontWeight: "500",
                                                opacity: "0.7",
                                                color: "#000",
                                            }),
                                        }}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label
                                        htmlFor="AboutMe"
                                        className="form-label"
                                    >
                                        {t("About Me")}
                                    </label>
                                    <textarea
                                        rows="5"
                                        className="form-control"
                                        value={form.aboutMe}
                                        onChange={(e) => handleChange(e, index)}
                                        name="aboutMe"
                                    ></textarea>
                                </div>
                                <div className="mb-3">
                                    <label
                                        htmlFor="SkillsExperience"
                                        className="form-label"
                                    >
                                        {t("Skills & Experience")}
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={form.skillsAndExperience}
                                        onChange={(e) => handleChange(e, index)}
                                        name="skillsAndExperience"
                                    />
                                </div>
                                <div className="mb-3">
                                    <label
                                        htmlFor="task"
                                        className="form-label"
                                    >
                                        {t(
                                            "Required Minimum Hours to Complete Task"
                                        )}
                                    </label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        value={form.minimumhours}
                                        onChange={(e) => handleChange(e, index)}
                                        name="minimumhours"
                                    />
                                </div>
                                <div>
                                    <label
                                        htmlFor="rate"
                                        className="form-label"
                                    >
                                        {t("Rate/hr")}
                                    </label>
                                    <div className="position-relative">
                                        <input
                                            type="number"
                                            className="form-control pe-5"
                                            value={form.rate}
                                            onInput={(e) => {
                                                e.target.value = Math.max(
                                                    0,
                                                    parseInt(e.target.value)
                                                )
                                                    .toString()
                                                    .slice(0, 5);
                                            }}
                                            onChange={(e) =>
                                                handleChange(e, index)
                                            }
                                            name="rate"
                                        />
                                        <span className="position-absolute">
                                            {t("din")}
                                        </span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    ))}

                    <div className="mb-5 text-center">
                        <h3 onClick={addForm}>{t("+Add More Service")}</h3>
                    </div>

                    <div>
                        <button
                            type="submit"
                            onClick={handleSubmit}
                            className="coman-btn-fill"
                        >
                            {t("Continue")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileCreation;
