import card from "../../assets/images/cash.png";
// import SuccessModal from "../../components/Modal/Success/SuccessModal";
import "./PaymentDetails.css";
import { useState } from "react";
import { toast } from "sonner";
import axios from "axios";
import Loader from "../Loader/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { decode, encode } from "base-64";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const BidsPaymentDetails = () => {
    const { t } = useTranslation();
    const { bidId } = useParams();
    const { _iD } = useParams();
    const decodedId = decode(bidId);
    const decodedSecId = decode(_iD);
    const navigate = useNavigate();
    const [bidPaymentDetails, setBidPaymentDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [promoData, setPromoData] = useState({
        bookingId: decodedSecId,
        paymentMethod: 0,
        promocode: "",
        name: "",
    });

    const handlePromoChange = (e) => {
        setPromoData({ ...promoData, [e.target.name]: e.target.value });
    };

    const handlePromoCode = async (e) => {
        e.preventDefault();
        setLoading(true);

        const promoDataForApi = { ...promoData };

        if (promoData.promocode === "") {
            delete promoDataForApi.promocode;
        }

        try {
            await axios.post(
                `${apiUrl}/booking/payment-method`,
                promoDataForApi,
                {
                    headers: {
                        accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                }
            );
            navigate(
                `/bidspaymentconfirm/${encode(decodedId)}/${encode(
                    decodedSecId
                )}`
            );
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error("Failed: " + error.response.data.message);
            } else {
                toast.error("Failed: " + error.message);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setLoading(true);
        axios
            .get(`${apiUrl}/task/booking-detail?bookingId=${decodedSecId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then((response) => {
                setBidPaymentDetails(response.data.data);
            })
            .catch((error) => {
                toast.error("Fetching error", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [decodedSecId]);

    const firstTask = bidPaymentDetails[0] || {};

    const handlePaymentMethodChange = (e) => {
        const paymentMethod = parseInt(e.target.value);
        const updatedFormData = { ...promoData, paymentMethod };

        if (paymentMethod === 1) {
            updatedFormData.name = "";
        } else {
            delete updatedFormData.name;
        }

        setPromoData(updatedFormData);
    };

    return (
        <div className="payment-details">
            {loading && <Loader />}
            <div className="description px-4 pb-2 pt-4">
                <h3>{t("Review your task description")}</h3>
                <p className="p-3">{firstTask.description}</p>
            </div>

            <form className="mb-5" onSubmit={handlePromoCode}>
                <div className="payment p-4 my-4">
                    <h3>{t("Choose your payment method")}</h3>
                    <div className="d-flex flex-wrap gap-4 my-3">
                        <div className="option d-flex align-items-center px-3">
                            <input
                                className="me-2"
                                type="radio"
                                name="paymentMethod"
                                value={0}
                                onChange={handlePaymentMethodChange}
                                checked={promoData.paymentMethod === 0}
                                id="Cash"
                            />
                            <label htmlFor="Cash">{t("Cash")}</label>
                            <img
                                className="ms-3 cash-icon"
                                src={card}
                                alt="card"
                            />
                        </div>
                    </div>
                    {promoData.paymentMethod === 1 && (
                        <>
                            <hr />
                            <div>
                                <h4>{t("Name")}</h4>
                                <input
                                    type="text"
                                    placeholder={t("Name")}
                                    value={promoData.name}
                                    onChange={handlePromoChange}
                                    name="name"
                                />
                            </div>
                        </>
                    )}
                </div>

                <div className="promo p-4 my-4">
                    <h3>{t("Promo code / Special offer")}</h3>
                    <div className="position-relative">
                        <input
                            type="text"
                            placeholder={t("Promo code")}
                            value={promoData.promocode}
                            onChange={handlePromoChange}
                            name="promocode"
                        />
                    </div>
                </div>

                <button type="submit" className="coman-btn-fill">
                    {t("Continue")}
                </button>
            </form>
        </div>
    );
};

export default BidsPaymentDetails;
