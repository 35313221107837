import { useTranslation } from "react-i18next";
import TaskAcceptanceCard from "../../../components/Cards/TaskAcceptanceCard/TaskAcceptanceCard";
import Title from "../../../components/title/Title";

const TaskAcceptance = () => {
    const { t } = useTranslation();
    return (
        <div className="taskAcceptance-wrapper flex-1">
            <div className="container">
                <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                    <Title
                        title={t("Task Acceptance")}
                        location="/provider-task"
                    />
                    <TaskAcceptanceCard />
                </div>
            </div>
        </div>
    );
};

export default TaskAcceptance;
