import Accordion from "../../components/Accordion/Accordion";
import Title from "../../components/title/Title";

const ProviderFaqs = () => {
    return (
        <div className="faqs-wrapper flex-1">
            <div className="container">
                <div className="col-lg-10 offset-lg-1">
                    <Title title="FAQs" location="/home-provider" />
                    <Accordion />
                </div>
            </div>
        </div>
    );
};

export default ProviderFaqs;
