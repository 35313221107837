import { Link } from "react-router-dom";
import footerBg from "../../assets/images/footer-bg.png";
import logo from "../../assets/images/Original.png";
import "./Footer.css";
import { useTranslation } from "react-i18next";

const Footer = () => {
    const { t } = useTranslation();
    return (
        <footer className="position-relative">
            <div className="container pt-4">
                <div className="col-12 d-flex align-items-start align-items-lg-center justify-content-between flex-lg-row flex-column mb-lg-5 mb-2 gap-2">
                    <div className="col-12 col-lg-2 mb-lg-0 mb-2">
                        <Link to="/">
                            <img
                                src={logo}
                                alt="logo"
                                className="footer-logo"
                            />
                        </Link>
                    </div>
                    <div className="col-12 col-lg-3 text-lg-end text-start">
                        <h2 className="mb-0">
                            {t("Developed by")} <span>Poslic.rs.</span>
                        </h2>
                    </div>
                </div>
                <div className="d-flex justify-content-center gap-0 gap-lg-5 flex-column flex-lg-row lh-lg mb-lg-0 mb-2">
                    <a
                        className="nav-link"
                        href="https://blog.poslic.rs/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t("Blogs")}
                    </a>
                    <Link to="/services" className="footer-link p-0">
                        {t("Services")}
                    </Link>
                    <Link to="/user-login" className="footer-link p-0">
                        {t("User Login")}
                    </Link>
                    <Link to="/tasker" className="footer-link p-0">
                        {t("Tasker Login")}
                    </Link>
                    <Link
                        to="/terms"
                        className="footer-link p-0"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t("Terms of Use")}
                    </Link>
                    <Link
                        to="/privacy"
                        className="footer-link p-0"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t("Privacy Policy")}.
                    </Link>
                </div>
                <hr className="mb-0" />
                <div className="py-3">
                    <p className="mb-0 text-center footer-copyright">
                        {t("All Rights Reserved To The Poslic © 2023")}
                    </p>
                </div>
            </div>
            <img
                src={footerBg}
                className="position-absolute top-0 start-0 w-100 h-100 object-fit-cover z-n1"
                alt="footerImage"
            />
        </footer>
    );
};

export default Footer;
