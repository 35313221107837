import "./MyTask.css";
import BookedTaskCard from "../../../components/Cards/PlumbingDetailsCard/BookedTaskCard";
import OnGoingTaskCard from "../../../components/Cards/PlumbingDetailsCard/OnGoingTaskCard";
import CancelTaskCard from "../../../components/Cards/PlumbingDetailsCard/CancelTaskCard";
import CompletedTaskCard from "../../../components/Cards/PlumbingDetailsCard/CompletedTaskCard";
import { useTranslation } from "react-i18next";

const MyTask = () => {
    const { t } = useTranslation();
    return (
        <div className="myTask-wrapper my-5 flex-1">
            <div className="container">
                <div className="d-flex align-items-center flex-md-row flex-column gap-md-5 gap-3">
                    <h1
                        className="text-end mb-4 mb-sm-0 title"
                        style={{ flex: 1 }}
                    >
                        {t("My Tasks")}
                    </h1>
                    <ul
                        className="nav nav-pills gap-3 justify-content-center"
                        id="pills-tab"
                        role="tablist"
                    >
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link active"
                                id="pills-booked-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-booked"
                                type="button"
                                role="tab"
                                aria-controls="pills-booked"
                                aria-selected="true"
                            >
                                {t("In Process")}
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="pills-home-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-home"
                                type="button"
                                role="tab"
                                aria-controls="pills-home"
                                aria-selected="true"
                            >
                                {t("On Going")}
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="pills-profile-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-profile"
                                type="button"
                                role="tab"
                                aria-controls="pills-profile"
                                aria-selected="false"
                            >
                                {t("Cancelled")}
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="pills-contact-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-contact"
                                type="button"
                                role="tab"
                                aria-controls="pills-contact"
                                aria-selected="false"
                            >
                                {t("Completed")}
                            </button>
                        </li>
                    </ul>
                </div>
                <div className="tab-content mt-4" id="pills-tabContent">
                    <div
                        className="tab-pane fade show active"
                        id="pills-booked"
                        role="tabpanel"
                        aria-labelledby="pills-booked-tab"
                        tabIndex={0}
                    >
                        <div className="grid-3">
                            <BookedTaskCard />
                        </div>
                    </div>
                    <div
                        className="tab-pane fade"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                        tabIndex={0}
                    >
                        <div className="grid-3">
                            <OnGoingTaskCard />
                        </div>
                    </div>
                    <div
                        className="tab-pane fade"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                        tabIndex={0}
                    >
                        <div className="grid-3">
                            <CancelTaskCard />
                        </div>
                    </div>
                    <div
                        className="tab-pane fade"
                        id="pills-contact"
                        role="tabpanel"
                        aria-labelledby="pills-contact-tab"
                        tabIndex={0}
                    >
                        <div className="grid-3">
                            <CompletedTaskCard />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyTask;
