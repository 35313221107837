import "./PaymentDetails.css";
import { useCallback, useEffect, useState } from "react";
import { toast } from "sonner";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../Loader/Loader";
import card from "../../assets/images/cash.png";
import { useTranslation } from "react-i18next";
import { decode } from "base-64";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const TaskCreditDetails = ({ taskCreditDetails }) => {
    const { t } = useTranslation();
    const firstTask = taskCreditDetails[0] || {};

    const navigate = useNavigate();
    const { mytaskdetailscreditId } = useParams();
    const decodedId = decode(mytaskdetailscreditId);
    const [loading, setLoading] = useState(false);
    const [aditionPayment, setAditionPayment] = useState([]);
    const [payment, setPayment] = useState({
        paymentMethod: 0,
    });

    const handlePaymentMethodChange = (event) => {
        const selectedPaymentMethod = parseInt(event.target.value, 10);
        setPayment((prevFormData) => ({
            ...prevFormData,
            paymentMethod: selectedPaymentMethod,
        }));
    };

    const fetchData = useCallback(() => {
        setLoading(true);
        axios
            .get(`${apiUrl}/task/additonal-payment?bookingId=${decodedId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then((response) => {
                setAditionPayment(
                    Array.isArray(response.data.data)
                        ? response.data.data
                        : [response.data.data]
                );
            })
            .catch((error) => {
                toast.error("Fetching error", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [decodedId]);

    useEffect(() => {
        fetchData();
    }, [decodedId, fetchData]);

    const handlePayNow = (addPaymentId, event) => {
        event.preventDefault();
        setLoading(true);
        const type = payment.paymentMethod;
        axios
            .get(
                `${apiUrl}/paypal/addtional-payment?addPaymentId=${addPaymentId}&type=${type}`,
                {
                    headers: {
                        accept: "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                }
            )
            .then((response) => {
                if (type === 1) {
                    window.open(response.data.data, "_blank");
                }
                navigate("/mytask");
                toast.success(t("successful"));
            })
            .catch((error) => {
                toast.error("Payment error", error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handlePaymentDecline = (addPaymentId, event) => {
        event.preventDefault();
        setLoading(true);
        axios
            .get(
                `${apiUrl}/task/decline-addtionalPayment?addPaymentId=${addPaymentId}`,
                {
                    headers: {
                        accept: "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                }
            )
            .then(() => {
                fetchData();
                toast.success(t("Payment decline successfully"));
            })
            .catch((error) => {
                toast.error("Payment error", error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className="payment-details">
            {loading && <Loader />}
            <div className="description px-4 pb-2 pt-4">
                <h3>{t("Review your task description")}</h3>
                <p className="p-3">{firstTask.description}</p>
            </div>

            <div className="myTaskDetailsCredit p-4 my-4">
                <div className="mb-lg-0 mb-3">
                    <h3 className="mb-3">{t("payment method")}</h3>
                    <div className="d-flex flex-wrap gap-4 my-3">
                        <div className="option d-flex align-items-center px-3">
                            <input
                                className="me-2"
                                type="radio"
                                name="paymentMethod"
                                value={0}
                                onChange={handlePaymentMethodChange}
                                checked={payment.paymentMethod === 0}
                            />
                            <label htmlFor="Cash">{t("Cash")}</label>
                            <img
                                className="ms-3 cash-icon"
                                src={card}
                                alt="card"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <form className="mb-5">
                <div className="price-details p-4 my-4">
                    <h3 className="mb-4">
                        {t("Price Details")}{" "}
                        {firstTask.selectPrice === 1 ||
                        firstTask.selectPrice === null ? (
                            <span style={{ color: "#0D7A5F" }}>
                                ({t("Hourly")})
                            </span>
                        ) : (
                            <span style={{ color: "#0D7A5F" }}>
                                ({t("Fixed")})
                            </span>
                        )}
                    </h3>
                    {firstTask.selectPrice === 1 ||
                    firstTask.selectPrice === null ? (
                        <div className="d-flex mb-2 justify-content-between">
                            <h4>{t("Hourly Rate")}</h4>
                            <h5>
                                {firstTask.howBigIsYourTask} x{" "}
                                {firstTask.horlyRate} {t("din/hr")}
                            </h5>
                        </div>
                    ) : (
                        ""
                    )}
                    {firstTask.promoAmount && (
                        <div className="d-flex mb-2 justify-content-between">
                            <h4>{t("Promo code")}</h4>
                            <span>- {firstTask?.promoAmount} din</span>
                        </div>
                    )}
                    {firstTask.referralAmount && (
                        <div className="d-flex justify-content-between">
                            <h4>Referral Amount</h4>
                            <span>
                                - {firstTask?.referralAmount} {t("din")}
                            </span>
                        </div>
                    )}
                    <hr />
                    <div className="d-flex justify-content-between">
                        <h2 className="mb-0">{t("Total Rate")}</h2>
                        <h1 className="mb-0">
                            {firstTask.referralAmount === null &&
                            firstTask.promoAmount === null ? (
                                <>
                                    {firstTask.totalRate} {t("din")}
                                </>
                            ) : (
                                <>
                                    {firstTask.prePrize} {t("din")}
                                </>
                            )}
                        </h1>
                    </div>
                </div>

                {aditionPayment.length > 0 &&
                    aditionPayment.map((payment, index) => (
                        <div
                            className="price-details bg-green p-4 my-4"
                            key={index}
                        >
                            <h3 className="mb-4">{t("Additional Payment")}</h3>
                            <div className="d-flex mb-2 justify-content-between">
                                <h4>
                                    {t("Extra Hour")} : {payment.item.extraHour}
                                    {t("hr")}
                                </h4>
                                <h5>
                                    {payment.item.extraHour} x{" "}
                                    {payment.item.hourlyRate} {t("din/hr")}
                                </h5>
                            </div>
                            <hr />
                            <div className="d-flex justify-content-between">
                                <h2 className="mb-0">
                                    {t("Total Payable Amount")}
                                </h2>
                                <h1 className="mb-0">
                                    {payment.total} {t("din")}
                                </h1>
                            </div>
                            <div className="row mt-4">
                                {payment.item.request === 0 ? (
                                    <>
                                        <div className="col-6">
                                            <button
                                                className="coman-btn"
                                                onClick={(event) =>
                                                    handlePaymentDecline(
                                                        payment.item._id,
                                                        event
                                                    )
                                                }
                                            >
                                                {t("Declines")}
                                            </button>
                                        </div>
                                        <div className="col-6">
                                            <button
                                                className="coman-btn-fill"
                                                onClick={(event) =>
                                                    handlePayNow(
                                                        payment.item._id,
                                                        event
                                                    )
                                                }
                                            >
                                                {t("Accept")}
                                            </button>
                                        </div>
                                    </>
                                ) : payment.item.request === 1 ? (
                                    <div className="col-12">
                                        <div className="alert alert-success">
                                            {t(
                                                "You have approved additional cost request"
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="col-12">
                                        <div className="alert alert-danger">
                                            {t(
                                                "The payment for this task has been declined."
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
            </form>
        </div>
    );
};

export default TaskCreditDetails;
