import { Link, useParams } from "react-router-dom";
import leftArrow from "../../../assets/images/left-arrow.png";
import dummyImage from "../../../assets/images/profile.jpg";
import location from "../../../assets/images/location.png";
import calendars from "../../../assets/images/Calendar.png";
import ruler from "../../../assets/images/ruler.png";
import chat from "../../../assets/images/Chat.png";
import TaskCreditDetails from "../../../components/PaymentDetails/TaskCreditDetails";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "sonner";
import ChangeDateModal from "../../../components/Modal/CalenderModal/ChangeDateModal";
import OngoingBookingCancelModal from "../../../components/Modal/Cancel/OngoingBookingCancelModal";
import { formatDateTime, updateLocale } from "../../../Utils/Utils";
import { useTranslation } from "react-i18next";
import { decode } from "base-64";
import MyRatingComponent from "../../../components/MyRatingComponent";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const MyTaskDetailsCredit = () => {
    const { t } = useTranslation();
    const { mytaskdetailscreditId } = useParams();
    const [loading, setLoading] = useState(false);
    const [taskCreditDetails, setTaskCreditDetails] = useState([]);
    const [forceRender, setForceRender] = useState(false);

    useEffect(() => {
        setLoading(true);
        const decodedId = decode(mytaskdetailscreditId);
        axios
            .get(`${apiUrl}/task/booking-detail?bookingId=${decodedId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then((response) => {
                setTaskCreditDetails(response.data.data);
            })
            .catch((error) => {
                toast.error("Fetching error", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [mytaskdetailscreditId, forceRender]);

    const handleDateChangeSuccess = () => {
        setForceRender((prevForceRender) => !prevForceRender);
    };

    const selectedLanguage = localStorage.getItem("selectedLanguage") || "sr";
    updateLocale(selectedLanguage);

    const statusLable = taskCreditDetails[0]?.status;

    return (
        <div className="myTaskDetailsCard-wrapper flex-1">
            <div className="container">
                <div className="col-8 offset-2">
                    <div className="d-flex align-items-center justify-content-start my-4">
                        <Link to="/mytask" className="back-btn">
                            <img src={leftArrow} alt="leftArrow" />
                        </Link>
                        <h1 className="col-11 text-center d-flex justify-content-center gap-2 align-items-center main-title mb-0">
                            {t("Details")}
                            <span
                                className="badge"
                                style={{
                                    backgroundColor: "#0D7A5F",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                }}
                            >
                                {" "}
                                {statusLable === 1
                                    ? t("On Going")
                                    : t("In Process")}
                            </span>
                        </h1>
                    </div>
                </div>

                <div className="main-grid-2">
                    <div>
                        {taskCreditDetails.length === 0 && !loading ? (
                            <p>{t("You’ve not any on Going booking yet")}</p>
                        ) : (
                            taskCreditDetails.map((task) => (
                                <div
                                    className="plumbing-detail-card"
                                    key={task._id}
                                >
                                    <div className="card-header p-4">
                                        <h2>{task.service}</h2>
                                        <div className="d-flex align-items-center gap-3">
                                            <img
                                                src={
                                                    task.profileImage
                                                        ? task.profileImage
                                                        : dummyImage
                                                }
                                                className="person"
                                                alt="person"
                                            />
                                            <div>
                                                <h3 className="mb-0">
                                                    {task.firstname}
                                                    &nbsp;
                                                    {task.lastname}
                                                </h3>
                                                <div className="mb-2 d-flex gap-1 align-items-center">
                                                    <MyRatingComponent
                                                        value={
                                                            task.averageRating
                                                        }
                                                    />
                                                    <span>
                                                        {task.averageRating ||
                                                            0}
                                                    </span>
                                                </div>
                                                <h6>
                                                    {task.minimumhours}{" "}
                                                    {t("Hours Minimum")}
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body p-4">
                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                            <div className="d-flex align-items-center gap-2">
                                                <img
                                                    className="icons"
                                                    src={calendars}
                                                    alt="calenders"
                                                />
                                                <p className="mb-0">
                                                    {formatDateTime(
                                                        task.date,
                                                        task.time
                                                    )}
                                                </p>
                                            </div>
                                            <div>
                                                <ChangeDateModal
                                                    selectChangeDateTask={task}
                                                    onDateChangeSuccess={
                                                        handleDateChangeSuccess
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <img
                                                className="icons"
                                                src={location}
                                                alt="location"
                                            />
                                            <p>{task.address}</p>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <img
                                                className="icons"
                                                src={ruler}
                                                alt="ruler"
                                            />
                                            <p>
                                                {task.howBigIsYourTask}{" "}
                                                {t("Hours")}
                                            </p>
                                        </div>

                                        <Link
                                            to="/message"
                                            className="coman-btn-fill"
                                        >
                                            <img
                                                src={chat}
                                                className="chat"
                                                alt="chat"
                                            />
                                            &nbsp; {t("Messages")}
                                        </Link>
                                    </div>
                                </div>
                            ))
                        )}
                        <OngoingBookingCancelModal />
                    </div>
                    <TaskCreditDetails taskCreditDetails={taskCreditDetails} />
                </div>
            </div>
        </div>
    );
};

export default MyTaskDetailsCredit;
