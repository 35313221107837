import { useState } from "react";
import TaskPostDetailModal from "../../Modal/TaskPostDetail/TaskPostDetailModal";
import "./PostTaskCard.css";
import { useEffect } from "react";
import axios from "axios";
import { toast } from "sonner";
import Loader from "../../../components/Loader/Loader";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const fetchTasks = async () => {
    try {
        const response = await axios.get(`${apiUrl}/task/tasks`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
        });
        return response.data.data;
    } catch (error) {
        toast.error("Fetching error for tasks", error.response.data.message);
        return [];
    }
};

const PostTaskCard = () => {
    const { t } = useTranslation();

    const [taskData, setTaskData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const tasks = await fetchTasks();
            setTaskData(tasks);
            setLoading(false);
        };
        fetchData();
    }, []);

    const handlePostDelete = async () => {
        const tasks = await fetchTasks();
        setTaskData(tasks);
    };

    return (
        <div className="blank">
            {loading && <Loader />}
            <div className="post-task-card row row-cols-xl-4 row-cols-lg-3 row-cols-sm-2 row-cols-1 g-3 mb-5">
                {taskData.length > 0 ? (
                    taskData.map((item) => (
                        <div className="col" key={item._id}>
                            <div className="card border-0 p-3 d-flex justify-content-between">
                                <div className="mb-2">
                                    <div className="col-12 row mb-3">
                                        <div className="col-8">
                                            <p>{t("Required Service")}</p>
                                            <h3>{item.service}</h3>
                                        </div>
                                        <div className="col-4 p-0">
                                            <p>{t("Budget")}</p>
                                            <h3>
                                                {item.selectPrice === 0
                                                    ? `${item.budget} ${t(
                                                          "din"
                                                      )}`
                                                    : `${item.budget} ${t(
                                                          "din/hr"
                                                      )}`}
                                            </h3>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="card-title">
                                            {t("Description")}
                                        </p>
                                        <p className="text opacity-100 desc">
                                            {item.description}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 row g-0">
                                    <hr />
                                    <div className="col-6">
                                        <p>{t("No. of Bids")}</p>
                                        <h3>{item.bidCount}</h3>
                                    </div>
                                    <div className="col-6 align-items-center d-flex p-0">
                                        <TaskPostDetailModal
                                            taskId={item._id}
                                            taskPostDetailsLocation="/bids"
                                            numBids={item.bidCount}
                                            onPostDelete={handlePostDelete}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <Link to="/task-post" className="task-posting-tag">
                        {t("+ Task Posting")}
                    </Link>
                )}
            </div>
        </div>
    );
};

export default PostTaskCard;
