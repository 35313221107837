import React from "react";
import arrow from "../../../../assets/images/left-arrow.png";
import background from "../../../../assets/images/bg-1.png";
import "./Choose.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const Choose = () => {
    const { t } = useTranslation();
    return (
        <div className="position-relative choose-section py-5">
            <Helmet>
                <title>User Log in/Sign up to Your Account - Poslic</title>
                <meta
                    name="description"
                    content="Poslic offers a range of absolute home, office, and personal services including cleaning, handyman, moving, yard work, and more. Log in/Sign up now!"
                />
                <meta
                    name="keywords"
                    content="Poslic User Login,User login on poslic,user login,login,Website login,Poslic website Login,Poslic Sign up,Poslic User Sign up,Sign up"
                />
            </Helmet>
            <div className="container d-flex justify-content-center align-items-center">
                <div className="box p-3 py-4">
                    <div className="box-header d-flex justify-content-start align-items-center mb-5">
                        <Link to="/">
                            <img src={arrow} className="icon" alt="arrow" />
                        </Link>
                        <h1 className="mb-0 col-11 text-center">
                            {t("Choose your preference")}
                        </h1>
                    </div>
                    <div className="box-body mb-5">
                        <Link to="/signup" className="coman-btn-fill mb-4">
                            {t("Sign up")}
                        </Link>
                        <Link to="/login" className="coman-btn">
                            {t("Log in")}
                        </Link>
                    </div>
                    <div className="box-footer">
                        <p className="mb-0 text-center px-0 px-md-5">
                            {t("By signing up, you agree to our")}{" "}
                            <Link
                                to="/terms"
                                className="links"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {t("Terms of Use")}
                            </Link>{" "}
                            {t("and")}{" "}
                            <Link
                                to="/privacy"
                                className="links"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {t("Privacy Policy")}.
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
            <img
                src={background}
                className="w-100 position-absolute top-0 start-0 bg-image h-100 object-fit-cover z-n1"
                alt="background"
            />
        </div>
    );
};

export default Choose;
