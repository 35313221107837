import "./PaymentDetails.css";
import { useState } from "react";
import { toast } from "sonner";
import axios from "axios";
import Loader from "../Loader/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { decode } from "base-64";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const BidsPaymentConfirmDetails = () => {
    const { t } = useTranslation();
    const { bidId } = useParams();
    const { _iD } = useParams();
    const decodedId = decode(bidId);
    const decodedSecId = decode(_iD);
    const navigate = useNavigate();
    const [bidPaymentDetails, setBidPaymentDetails] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        axios
            .get(`${apiUrl}/task/booking-detail?bookingId=${decodedSecId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then((response) => {
                setBidPaymentDetails(response.data.data);
            })
            .catch((error) => {
                toast.error("Fetching error", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [decodedSecId]);

    const firstTask = bidPaymentDetails[0] || {};

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = {
            step: 2,
            bidId: decodedId,
        };
        try {
            await axios.post(`${apiUrl}/booking/task-booking`, formData, {
                method: "POST",
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            });
            toast.success(t("Booking Confirmed"));
            navigate("/home");
        } catch (error) {
            toast.error("Failed: " + error.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="payment-details mb-5">
            {loading && <Loader />}
            <div className="description px-4 pb-2 pt-4">
                <h3>{t("Review your task description")}</h3>
                <p className="p-3">{firstTask.description}</p>
            </div>

            <form className="mb-5" onSubmit={handleSubmit}>
                <div className="price-details p-4 my-4">
                    <h3 className="mb-4">{t("Price Details")}</h3>
                    {firstTask.selectPrice === 1 && (
                        <div className="d-flex mb-2 justify-content-between">
                            <h4>{t("Hourly Rate")}</h4>
                            <h5>
                                {firstTask.howBigIsYourTask} x{" "}
                                {firstTask.horlyRate} {t("din/hr")}
                            </h5>
                        </div>
                    )}
                    {firstTask.promoAmount && (
                        <div className="d-flex mb-2 justify-content-between">
                            <h4>{t("Promo code")}</h4>
                            <span>
                                - {firstTask?.promoAmount} {t("din")}
                            </span>
                        </div>
                    )}
                    {firstTask.referralAmount && (
                        <div className="d-flex justify-content-between">
                            <h4>Referral Amount</h4>
                            <span>
                                - {firstTask?.referralAmount} {t("din")}
                            </span>
                        </div>
                    )}
                    <hr />
                    <div className="d-flex justify-content-between">
                        <h2 className="mb-0">{t("Total Rate")}</h2>
                        <h1 className="mb-0">
                            {firstTask.referralAmount === null &&
                            firstTask.promoAmount === null ? (
                                <>
                                    {firstTask.totalRate} {t("din")}
                                </>
                            ) : (
                                <>
                                    {firstTask.prePrize} {t("din")}
                                </>
                            )}
                        </h1>
                    </div>
                </div>

                <button type="submit" className="coman-btn-fill">
                    {t("Continue")}
                </button>
            </form>
        </div>
    );
};

export default BidsPaymentConfirmDetails;
