import Filter from "../../../components/Filter/Filter";
import TaskCard from "../../../components/Cards/TaskCard/TaskCard";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import Loader from "../../../components/Loader/Loader";
import Pagination from "../../../components/Pagination/Pagination";
import { useTranslation } from "react-i18next";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const HomeProvider = () => {
    const { t } = useTranslation();
    const [taskDetail, setTaskDetail] = useState([]);
    const [loading, setLoading] = useState(true);
    const [paginationData, setPaginationData] = useState({
        currentPage: 1,
        totalPages: 1,
    });

    const [filters, setFilters] = useState({
        taskId: "",
        perPage: 10,
        page: 1,
        location: "",
        serviceId: "",
        selectPrice: {},
        search: "",
    });

    const handlePageChange = (newPage) => {
        setFilters({ ...filters, page: newPage });
    };

    const handleSearchChange = (searchTerm) => {
        setFilters({ ...filters, search: searchTerm });
    };

    useEffect(() => {
        const disableBackButton = () => {
            window.history.pushState(null, null, document.URL);
            window.addEventListener("popstate", function () {
                window.history.pushState(null, null, document.URL);
            });
        };

        disableBackButton();

        return () => {
            window.removeEventListener("popstate", disableBackButton);
        };
    }, []);

    const escapeSpecialCharacters = (str) => {
        return str.replace(/([.*+?^${}()|[\]\\])/g, "\\$1");
    };

    useEffect(() => {
        const constructApiUrl = () => {
            const {
                taskId,
                perPage,
                page,
                location,
                serviceId,
                selectPrice,
                search,
            } = filters;
            const searchTerm = search
                ? escapeSpecialCharacters(String(search))
                : "";
            return `${apiUrl}/tasker/tasks?taskId=${taskId}&perPage=${perPage}&page=${page}&location=${location}&serviceId=${serviceId}&selectPrice=${selectPrice}&search=${encodeURIComponent(
                searchTerm
            )}`;
        };
        axios
            .get(constructApiUrl(), {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then((response) => {
                setTaskDetail(response.data.data.data);
                setPaginationData({
                    currentPage: response.data.data.meta.currentPage,
                    totalPages: response.data.data.meta.totalPages,
                });
            })
            .catch((error) => {
                toast.error("fetching error", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [filters]);

    return (
        <div className="flex-1">
            {loading && <Loader />}
            <div className="container">
                <Filter
                    setFilters={setFilters}
                    filters={filters}
                    onSearchChange={handleSearchChange}
                />
                <div className="row row-gap-4 my-5">
                    {Array.isArray(taskDetail) && taskDetail.length > 0 ? (
                        taskDetail.map((browseTask) => (
                            <div
                                className="col-12 col-xl-3 col-lg-4 col-md-6"
                                key={browseTask._id}
                            >
                                <TaskCard
                                    browseTask={browseTask}
                                    browseTaskId={browseTask._id}
                                />
                            </div>
                        ))
                    ) : (
                        <p>{t("No more tasks posted")}</p>
                    )}
                </div>
                {paginationData.currentPage > 1 && (
                    <Pagination
                        currentPage={paginationData.currentPage}
                        totalPages={paginationData.totalPages}
                        onPageChange={handlePageChange}
                    />
                )}
            </div>
        </div>
    );
};

export default HomeProvider;
