import { useState } from "react";
import "./PlumbingHelp.css";
import axios from "axios";
import { toast } from "sonner";
import Loader from "../../../components/Loader/Loader";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import NameLoader from "../../../components/Loader/NameLoader";
import { useTranslation } from "react-i18next";
import PlacesAutocomplete from "react-places-autocomplete";
import { decode } from "base-64";
import { useLanguage } from "../../../context/LanguageContext";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const PlumbingHelp = ({ onPlumbingHelpResponse }) => {
    const { t } = useTranslation();
    const { selectedLanguage } = useLanguage();
    const { serviceId } = useParams();
    const { userBookingId } = useParams();
    const decodedId = decode(serviceId);
    const decodedBookingId = decode(userBookingId);
    const [serviceName, setServiceName] = useState("");
    const [formData, setFormData] = useState({
        step: 2,
        description: "",
        address: "",
        apartmentNo: "",
        howBigIsYourTask: "",
        bookingId: decodedBookingId,
        serviceId: decodedId,
    });
    const [loading, setLoading] = useState(false);
    const [serviceNameLoaded, setServiceNameLoaded] = useState(false);

    useEffect(() => {
        if (decodedId) {
            setServiceNameLoaded(true);
            axios
                .get(`${apiUrl}/task/service?serviceId=${decodedId}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                })
                .then((response) => {
                    setServiceName(response.data.data);
                })
                .catch((error) => {
                    toast.error("Fetching error", error);
                })
                .finally(() => {
                    setServiceNameLoaded(false);
                });
        } else {
            setServiceNameLoaded(false);
        }
    }, [decodedId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "howBigIsYourTask" && !/^\d*$/.test(value)) {
            return;
        }
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let response;
        try {
            response = await axios.post(`${apiUrl}/booking/search`, formData, {
                method: "POST",
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            });
            if (
                response.status === 200 &&
                response.data &&
                typeof response.data === "object"
            ) {
                if (response) {
                    toast.success(t("Step 1 Completed"));
                    if (onPlumbingHelpResponse) {
                        onPlumbingHelpResponse();
                    }
                }
            } else if (response.status === 401 || response.status === 422) {
                toast.error("Failed: " + response.data.message);
            }
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error("Failed: " + error.response.data.message);
            } else {
                toast.error("Failed: " + error.message);
            }
        } finally {
            setLoading(false);
        }
    };

    const handleSelect = async (address) => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: address }, (results, status) => {
            if (status === "OK" && results.length > 0) {
                const locationData = results[0].geometry.location;
                const lat = locationData.lat().toString();
                const long = locationData.lng().toString();

                setFormData({
                    ...formData,
                    address: address,
                });

                localStorage.setItem("lat", lat);
                localStorage.setItem("long", long);
            }
        });
    };

    return (
        <div className="container plumbing-container">
            {loading && <Loader />}
            <div className="col-sm-10 offset-sm-1">
                <div className="my-5">
                    {serviceNameLoaded && <NameLoader />}
                    <h1 className="text-center mb-3">
                        {selectedLanguage === "en"
                            ? serviceName.task
                            : serviceName.serbianTask}
                    </h1>
                    <form
                        action=""
                        className="p-sm-5 pt-sm-4 p-3 pt-2 form"
                        onSubmit={handleSubmit}
                    >
                        <div>
                            <label htmlFor="Address">{t("Address")}</label>
                            <PlacesAutocomplete
                                value={formData.address}
                                onChange={(newValue) =>
                                    setFormData({
                                        ...formData,
                                        address: newValue,
                                    })
                                }
                                onSelect={handleSelect}
                            >
                                {({
                                    getInputProps,
                                    suggestions,
                                    getSuggestionItemProps,
                                    loading,
                                }) => (
                                    <div>
                                        <input
                                            {...getInputProps({
                                                placeholder: t(
                                                    "Enter Your Location"
                                                ),
                                                className: "form-control",
                                            })}
                                        />
                                        <div className="suggestions-container">
                                            {loading && (
                                                <div>{t("Loading")}...</div>
                                            )}
                                            {suggestions.map(
                                                (suggestion, index) => (
                                                    <div
                                                        {...getSuggestionItemProps(
                                                            suggestion
                                                        )}
                                                        key={index}
                                                        className="suggestion-item"
                                                    >
                                                        <span className="suggestion-text">
                                                            {
                                                                suggestion.description
                                                            }
                                                        </span>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                        </div>
                        <div>
                            <label htmlFor="unitNo">
                                {t("Unit or Apartment No.")}
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                value={formData.apartmentNo}
                                onChange={handleChange}
                                name="apartmentNo"
                            />
                        </div>
                        <div>
                            <label htmlFor="task">
                                {t("How Big is Your Task")}
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                placeholder={t(
                                    "Enter approximate hours to complete task"
                                )}
                                value={formData.howBigIsYourTask}
                                onChange={handleChange}
                                name="howBigIsYourTask"
                                max="99999"
                            />
                        </div>
                        <div>
                            <label htmlFor="Description">
                                {t("Description")}
                            </label>
                            <textarea
                                className="form-control"
                                value={formData.description}
                                onChange={handleChange}
                                name="description"
                            />
                        </div>
                        <div className="mt-4">
                            <button type="submit" className="coman-btn-fill">
                                {t("Continue")}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default PlumbingHelp;
