import { useTranslation } from "react-i18next";
import PrivacyPolicy from "../../components/PrivacyPolicy";
import Title from "../../components/title/Title";

const PrivacyPolicys = () => {
    const { t } = useTranslation();
    return (
        <div className="container privacy-policy-container flex-1">
            <div className="col-lg-10 offset-lg-1">
                <Title title={t("Poslic Privacy Policy")} location="/home" />
                <PrivacyPolicy />
            </div>
        </div>
    );
};

export default PrivacyPolicys;
