import "./TaskAcceptanceCard.css";
import dummyImage from "../../../assets/images/profile.jpg";
import location from "../../../assets/images/location.png";
import calender from "../../../assets/images/Calendar.png";
import ruler from "../../../assets/images/ruler.png";
import chat from "../../../assets/images/Chat-green.png";
import editIcon from "../../../assets/images/edit-3.png";
import closeIcon from "../../../assets/images/x.png";
import correctIcon from "../../../assets/images/tick-circle-3.png";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "sonner";
import Loader from "../../Loader/Loader";
import Description from "../../Description/Description";
import { Link } from "react-router-dom";
import { formatDateTime, updateLocale } from "../../../Utils/Utils";
import { useTranslation } from "react-i18next";
import MyRatingComponent from "../../../components/MyRatingComponent";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const TaskAcceptanceCard = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [taskDetails, setTaskDetails] = useState([]);
    const [editHourseId, setEditHourseId] = useState(null);
    const [hour, setHour] = useState("");

    const handleHide = () => {
        setEditHourseId(null);
    };

    const handleShow = (cardId) => {
        setEditHourseId(cardId);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "hour" && !/^\d*$/.test(value)) {
            return;
        }
        setHour(value);
    };

    const fetchData = () => {
        setLoading(true);
        axios
            .get(`${apiUrl}/tasker/request-list`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then((response) => {
                setTaskDetails(response.data.data);
            })
            .catch((error) => {
                toast.error("Fetching error", error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleAction = async (bookingId, status) => {
        setLoading(true);
        const data = {
            bookingId: bookingId,
            status,
        };
        await axios
            .post(`${apiUrl}/tasker/request`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then(() => {
                if (status === 1) {
                    toast.success(t("Task accepted successfully"));
                } else if (status === 2) {
                    toast.error(t("Task declined successfully"));
                }
                fetchData();
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const submitHourse = (bookingId) => {
        setLoading(true);
        const data = {
            bookingId: bookingId,
            hour: hour,
        };
        axios
            .post(`${apiUrl}/tasker/edit-hours`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then((response) => {
                setEditHourseId(null);
                toast.success(response.data.message);
                fetchData();
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const selectedLanguage = localStorage.getItem("selectedLanguage") || "sr";
    updateLocale(selectedLanguage);

    return (
        <>
            {loading && <Loader />}
            {taskDetails.length === 0 ? (
                <p>{t("No more tasks")}</p>
            ) : (
                taskDetails.map((item) => (
                    <div className="task-acceptcard p-3 mb-4" key={item._id}>
                        {item.editFlag && (
                            <div className="alert alert-danger">
                                {t(
                                    "The user needs to approve the revised time estimate before you proceed."
                                )}
                            </div>
                        )}
                        <div className="d-flex align-items-center row gap-md-0 gap-3">
                            <div className="col-12 col-md-8 d-flex align-items-center gap-3">
                                <img
                                    src={
                                        item.profileImage
                                            ? item.profileImage
                                            : dummyImage
                                    }
                                    alt="person"
                                    className="profile-img"
                                />
                                <div>
                                    <h3 className="mb-0">
                                        {item.firstname}&nbsp;{item.lastname}
                                    </h3>
                                    <div className="d-flex gap-1 align-items-center">
                                        <MyRatingComponent
                                            value={item.averageRating}
                                        />
                                        <span>{item.averageRating || 0}</span>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <p className="mb-0">
                                            {t("Required Service")} :
                                        </p>
                                        <h2 className="mb-0">{item.service}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 d-flex gap-3 align-items-center">
                                <button
                                    className="coman-btn"
                                    onClick={() => handleAction(item._id, 2)}
                                >
                                    {t("Decline")}
                                </button>
                                <button
                                    className={`coman-btn-fill ${
                                        item.editFlag && "bg-secondary"
                                    }`}
                                    disabled={item.editFlag}
                                    onClick={() => handleAction(item._id, 1)}
                                >
                                    {t("Accept")}
                                </button>
                            </div>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between align-items-start flex-column flex-md-row align-items-md-center">
                            <div className="d-flex align-items-start flex-column flex-md-row align-items-md-center gap-4">
                                <div className="d-flex align-items-center gap-2">
                                    <img
                                        className="icon"
                                        src={location}
                                        alt="location"
                                    />
                                    <p className="mb-0 text-dark fw-medium">
                                        {item.address}
                                    </p>
                                </div>
                                <div className="d-flex align-items-center gap-2">
                                    <img
                                        className="icon"
                                        src={calender}
                                        alt="location"
                                    />
                                    <p
                                        className="mb-0 text-dark fw-medium"
                                        style={{ width: "max-content" }}
                                    >
                                        {formatDateTime(item.date, item.time)}
                                    </p>
                                </div>
                                <div className="d-flex align-items-center gap-2 me-3">
                                    <img
                                        className="icon"
                                        src={ruler}
                                        alt="location"
                                    />
                                    <p
                                        className="mb-0 text-dark fw-medium"
                                        style={{ width: "max-content" }}
                                    >
                                        {item.howBigIsYourTask} {t("Hours")}
                                    </p>
                                </div>
                            </div>
                            <div className="mt-2 mt-md-0">
                                <Link
                                    to="/tasker-message"
                                    className="coman-btn border-0 gap-2 fw-medium"
                                >
                                    <img
                                        src={chat}
                                        className="icon"
                                        alt="chat"
                                    />
                                    {t("Message")}
                                </Link>
                            </div>
                        </div>
                        {editHourseId !== item._id ? (
                            <div className="d-flex align-items-center gap-3 my-2">
                                <p className="mb-0">{t("Edit Hours")}</p>
                                <img
                                    src={editIcon}
                                    className="icon my-2"
                                    alt="img"
                                    onClick={() => handleShow(item._id)}
                                />
                            </div>
                        ) : (
                            <div className="d-flex align-items-center gap-3 mb-2">
                                <input
                                    type="number"
                                    className="form-control h-100"
                                    style={{ width: "200px" }}
                                    name="hour"
                                    value={hour}
                                    onChange={handleChange}
                                />
                                <img
                                    src={closeIcon}
                                    className="icon"
                                    alt="img"
                                    onClick={handleHide}
                                />
                                <img
                                    src={correctIcon}
                                    className="icon"
                                    alt="img"
                                    onClick={() => submitHourse(item._id)}
                                />
                            </div>
                        )}
                        <Description description={item.description} />
                        <div className="d-flex align-items-center justify-content-center flex-column flex-sm-row gap-1 gap-sm-5 mt-4">
                            <div className="d-flex align-items-center">
                                <p className="mb-0">{t("Payment Method")} :</p>{" "}
                                &nbsp;
                                <h4 className="mb-0">
                                    <>{t("Cash")}</>
                                </h4>
                            </div>
                            <div className="d-flex align-items-center">
                                <p className="mb-0">{t("Payment")} :</p> &nbsp;
                                <h5 className="mb-0">
                                    {item.totalRate} {t("din")}
                                </h5>
                            </div>
                        </div>
                    </div>
                ))
            )}
        </>
    );
};

export default TaskAcceptanceCard;
