import BidsCard from "../../../components/Cards/BidsCard/BidsCard";
import "./Bids.css";
import Title from "../../../components/title/Title";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { toast } from "sonner";
import Loader from "../../../components/Loader/Loader";
import TaskBidDetailscard from "../../../components/Cards/PostTaskCard/TaskBidDetailscard";
import { useTranslation } from "react-i18next";
import { decode } from "base-64";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const Bids = () => {
    const { t } = useTranslation();
    const { taskBidId } = useParams();
    const decodedId = decode(taskBidId);
    const [bidDetailsData, setBidDetailsData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (decodedId) {
            axios
                .get(`${apiUrl}/task/bids?taskId=${decodedId}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                })
                .then((response) => {
                    setBidDetailsData(response.data.data);
                })
                .catch((error) => {
                    toast.error("Fetching error", error);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [decodedId]);

    return (
        <div className="container flex-1">
            {loading && <Loader />}
            <div className="col-sm-10 offset-sm-1 mb-5">
                <Title title={t("Bids Details")} location="/home" />
                <div className="main-grid-2">
                    <TaskBidDetailscard />
                    <BidsCard bidDetailsData={bidDetailsData} />
                </div>
            </div>
        </div>
    );
};

export default Bids;
