import dummyImage from "../../../assets/images/profile.jpg";
import location from "../../../assets/images/location.png";
import calendars from "../../../assets/images/Calendar.png";
import ruler from "../../../assets/images/ruler.png";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "sonner";
import Loader from "../../Loader/Loader";
import { formatDateTime, updateLocale } from "../../../Utils/Utils";
import { useTranslation } from "react-i18next";
import MyRatingComponent from "../../MyRatingComponent";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const ProviderCancelTaskCard = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [taskDetails, setTaskDetails] = useState([]);

    useEffect(() => {
        setLoading(true);
        axios
            .get(`${apiUrl}/tasker/cancelled-tasks`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then((response) => {
                setTaskDetails(response.data.data);
            })
            .catch((error) => {
                toast.error("Fetching error", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const selectedLanguage = localStorage.getItem("selectedLanguage") || "sr";
    updateLocale(selectedLanguage);
    return (
        <>
            {loading && <Loader />}
            {taskDetails.length === 0 && !loading ? (
                <p>{t("You’ve not any cancelled booking yet")}</p>
            ) : (
                taskDetails.map((task) => (
                    <div className="plumbing-detail-card" key={task._id}>
                        <div className="card-header p-4">
                            <h2>{task.service}</h2>
                            <div className="d-flex align-items-center gap-3">
                                <img
                                    src={
                                        task.profileImage
                                            ? task.profileImage
                                            : dummyImage
                                    }
                                    className="person"
                                    style={{ flex: "none" }}
                                    alt="person"
                                />
                                <div>
                                    <h3 className="mb-0">
                                        {task.firstname}&nbsp;{task.lastname}
                                    </h3>
                                    <div className="d-flex gap-1 align-items-center">
                                        <MyRatingComponent
                                            value={task.averageRating}
                                        />
                                        <span>{task.averageRating || 0}</span>
                                    </div>
                                    <div className="mb-2 d-flex gap-1 align-items-center">
                                        <img
                                            src={location}
                                            className="icons"
                                            alt="location"
                                        />
                                        <p className="mb-0">{task.address}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body p-4">
                            <div className="d-flex gap-2">
                                <img
                                    className="icons"
                                    src={calendars}
                                    alt="calenders"
                                />
                                <p>{formatDateTime(task.date, task.time)}</p>
                            </div>
                            <div className="d-flex gap-2">
                                <img
                                    className="icons"
                                    src={ruler}
                                    alt="ruler"
                                />
                                <p>
                                    {task.howBigIsYourTask} {t("Hours")}
                                </p>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <h4 className="mb-0">
                                    {t("Total Paid Amount")}:{" "}
                                    <span>{task.total} din</span>
                                </h4>
                            </div>
                        </div>
                        <div className="p-4 pt-0 m-auto w-50">
                            <button className="coman-btn border-danger text-danger">
                                {t("Cancelled")}
                            </button>
                        </div>
                    </div>
                ))
            )}
        </>
    );
};

export default ProviderCancelTaskCard;
