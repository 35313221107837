import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./Navbar.css";
import { useLanguage } from "../../context/LanguageContext";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import logo from "../../assets/images/Original.png";
import currect from "../../assets/images/currect-2.png";
import world from "../../assets/images/worldwide.png";
import { Container, Navbar } from "react-bootstrap";

const ComanNavbar = () => {
    const { t } = useTranslation();
    const { selectedLanguage, setSelectedLanguage } = useLanguage();
    const [expanded, setExpanded] = useState(false);

    const handleLanguageSelect = (language) => {
        setSelectedLanguage(language);
        i18n.changeLanguage(language);
    };

    const handleNavbarToggle = () => {
        setExpanded(!expanded);
    };

    const handleNavLinkClick = () => {
        setExpanded(false);
    };
    return (
        <Navbar
            expand="lg"
            className="bg-body-tertiary p-0"
            expanded={expanded}
        >
            <Container>
                <Navbar.Brand>
                    <Link
                        className="navbar-brand"
                        to="/"
                        onClick={handleNavLinkClick}
                    >
                        <img src={logo} alt="logo" />
                    </Link>
                </Navbar.Brand>
                <div>
                    <Navbar.Toggle onClick={handleNavbarToggle} />
                </div>
                <Navbar.Collapse className="main-navbar navbar-nav align-items-start">
                    <div className="d-flex gap-lg-5 gap-2 align-items-lg-center flex-lg-row flex-column mb-lg-0 mb-3 ms-lg-auto">
                        <NavLink
                            className="nav-link px-0"
                            to="/"
                            onClick={handleNavLinkClick}
                        >
                            {t("Home")}
                        </NavLink>
                        <a
                            onClick={handleNavLinkClick}
                            className="nav-link px-0"
                            href="https://blog.poslic.rs/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {t("Blogs")}
                        </a>
                        <NavLink
                            className="nav-link px-0"
                            to="/services"
                            onClick={handleNavLinkClick}
                        >
                            {t("Services")}
                        </NavLink>
                        <NavLink className="nav-link px-0" to="/user-login">
                            {t("User Login")}
                        </NavLink>
                        <button
                            className="nav-link-box"
                            style={{ width: "fit-content" }}
                        >
                            <NavLink
                                to="/tasker"
                                className="nav-link px-3 py-1"
                            >
                                {t("Tasker Login")}
                            </NavLink>
                        </button>
                        <div className="dropdown language-dropdown">
                            <button
                                className="btn dropdown-toggle text-uppercase d-flex align-items-center gap-2"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <img src={world} className="icon" alt="world" />{" "}
                                {selectedLanguage}
                            </button>
                            <ul className="dropdown-menu dropdown-menu-end p-0 rounded-2 mt-2 overflow-hidden p-2">
                                <li>
                                    <span
                                        className={`dropdowns-list d-block mb-0 ${
                                            selectedLanguage === "sr"
                                                ? "bg-success"
                                                : ""
                                        }`}
                                        onClick={() => {
                                            handleLanguageSelect("sr");
                                            window.location.reload();
                                        }}
                                    >
                                        {selectedLanguage === "sr" && (
                                            <img
                                                src={currect}
                                                className="currect"
                                                alt="Selected"
                                            />
                                        )}{" "}
                                        {t("Serbian")}
                                    </span>
                                </li>
                                <li>
                                    <span
                                        className={`dropdowns-list d-block mb-0 ${
                                            selectedLanguage === "en"
                                                ? "bg-success"
                                                : ""
                                        }`}
                                        onClick={() => {
                                            handleLanguageSelect("en");
                                            window.location.reload();
                                        }}
                                    >
                                        {selectedLanguage === "en" && (
                                            <img
                                                src={currect}
                                                className="currect"
                                                alt="Selected"
                                            />
                                        )}{" "}
                                        {t("English")}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default ComanNavbar;
