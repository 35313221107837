import "./TaskAndPriceCard.css";
import dummyImage from "../../../assets/images/profile.jpg";
import user from "../../../assets/images/user.png";
import TaskAndPriceDetailModal from "../../Modal/TaskAndPriceDetailModal/TaskAndPriceDetailModal";
import axios from "axios";
import { toast } from "sonner";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { decode } from "base-64";
import MyRatingComponent from "../../MyRatingComponent";
import NameLoader from "../../Loader/NameLoader";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const TaskAndPriceCard = ({
    taskPriceDetails,
    onPlumbingHelpResponseTwo,
    loading,
}) => {
    const { t } = useTranslation();
    const { serviceId } = useParams();
    const { userBookingId } = useParams();
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };

    const handleSelectAndContinue = (itemId) => {
        const decodedId = decode(serviceId);
        const decodedBookingId = decode(userBookingId);
        axios
            .post(
                `${apiUrl}/booking/search`,
                {
                    step: 3,
                    serviceId: decodedId,
                    bookingId: decodedBookingId,
                    selectedServiceId: itemId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                }
            )
            .then((response) => {
                if (response.status === 200) {
                    localStorage.setItem("selectedServiceId", itemId);
                    toast.success(t("Step 2 Completed"));
                    if (onPlumbingHelpResponseTwo) {
                        onPlumbingHelpResponseTwo();
                    }
                } else {
                    toast.error("API error: " + response.data);
                }
            })
            .catch((error) => {
                toast.error(t("Network or other error:") + error);
            });
    };

    return (
        <div className="task-price-card">
            {loading && <NameLoader />}
            {taskPriceDetails.length > 0 ? (
                taskPriceDetails.map((item) => (
                    <div className="card p-3 ps-md-4" key={item._id}>
                        <div className="text-center">
                            <img
                                src={
                                    item.profileImage
                                        ? item.profileImage
                                        : dummyImage
                                }
                                className="profile-img mb-3"
                                alt="profile"
                            />
                            <TaskAndPriceDetailModal
                                bookingTaskId={item._id}
                                onPlumbingHelpResponseTwo={
                                    onPlumbingHelpResponseTwo
                                }
                            />
                            <button
                                className="coman-btn-fill mb-4"
                                onClick={() =>
                                    handleSelectAndContinue(item._id)
                                }
                            >
                                {t("Select & Continue")}
                            </button>
                            <h6>
                                {t(
                                    "You can chat with your tasker, adjust task details or change task time after booking."
                                )}
                            </h6>
                        </div>
                        <div>
                            <div className="d-flex justify-content-between flex-sm-row flex-column">
                                <h1>
                                    {item.firstname}&nbsp;{item.lastname}
                                </h1>
                                <h3>
                                    {item.rate} {t("din/hr")}
                                </h3>
                            </div>
                            <div className="d-flex align-items-start align-items-sm-center flex-sm-row flex-column gap-1 mb-3">
                                <p className="task-time mb-0">
                                    {item.minimumhours} {t("Hours Minimum")}
                                </p>
                                <div className="d-flex gap-1 align-items-center">
                                    <MyRatingComponent
                                        value={item.averageRating}
                                    />
                                    <span>{item.averageRating || 0}</span>
                                </div>
                            </div>
                            <div>
                                <p className="fw-bold fs-6 text-dark">
                                    {t("Address")}:&nbsp;
                                    <span>
                                        {item?.location
                                            .split(",")
                                            .slice(-3)
                                            .join()}
                                    </span>
                                </p>
                                <p className="task">
                                    {item.count}&nbsp;
                                    {item.service} {t("Task Completed")}
                                </p>
                            </div>
                            <div className="background p-3 mb-3">
                                <h4>{t("How I can help")} :</h4>
                                <p className="mb-0">
                                    {isExpanded
                                        ? item.aboutMe
                                        : item.aboutMe.slice(0, 150)}
                                    {item.aboutMe.length > 150 &&
                                        !isExpanded && (
                                            <span
                                                className="read-more"
                                                onClick={toggleReadMore}
                                            >
                                                &nbsp;{t("Read More")}
                                            </span>
                                        )}
                                    {item.aboutMe.length > 150 &&
                                        isExpanded && (
                                            <span
                                                className="read-more"
                                                onClick={toggleReadMore}
                                            >
                                                &nbsp;{t("Read Less")}
                                            </span>
                                        )}
                                </p>
                            </div>
                            <div className="d-flex gap-2">
                                <img
                                    src={user}
                                    className="profile-icon opacity-25"
                                    alt="user"
                                />
                                {item.feedback ? (
                                    <div>
                                        <div className="d-flex">
                                            <h5>
                                                {item.feedback.firstname}&nbsp;
                                                {item.feedback.lastname}
                                            </h5>
                                            &nbsp;
                                            <h5 className="fw-normal opacity-50">
                                                on&nbsp;
                                                {moment(
                                                    item.feedback.createdAt
                                                ).format("MMM D")}
                                            </h5>
                                        </div>
                                        <p>
                                            {isExpanded
                                                ? item.feedback.review
                                                : item.feedback.review.slice(
                                                      0,
                                                      150
                                                  )}
                                            {item.feedback.review.length >
                                                150 &&
                                                !isExpanded && (
                                                    <span
                                                        className="read-more"
                                                        onClick={toggleReadMore}
                                                    >
                                                        &nbsp;{t("Read More")}
                                                    </span>
                                                )}
                                            {item.feedback.review.length >
                                                150 &&
                                                isExpanded && (
                                                    <span
                                                        className="read-more"
                                                        onClick={toggleReadMore}
                                                    >
                                                        &nbsp;{t("Read Less")}
                                                    </span>
                                                )}
                                        </p>
                                    </div>
                                ) : (
                                    <p className="mb-0 pt-2">
                                        {t("No feedback available yet")}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <p className="task-posting-tag">
                    {t("No tasker is available.")}
                </p>
            )}
        </div>
    );
};

export default TaskAndPriceCard;
