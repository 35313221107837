import "./PlumbingDetailsCard.css";
import dummyImage from "../../../assets/images/profile.jpg";
import location from "../../../assets/images/location.png";
import calendars from "../../../assets/images/Calendar.png";
import ruler from "../../../assets/images/ruler.png";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { toast } from "sonner";
import NameLoader from "../../Loader/NameLoader";
import { formatDateTime, updateLocale } from "../../../Utils/Utils";
import { useTranslation } from "react-i18next";
import { decode } from "base-64";
import MyRatingComponent from "../../MyRatingComponent";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const PlumbingDetails = () => {
    const { t } = useTranslation();
    const { userBookingId } = useParams();
    const decodedId = decode(userBookingId);
    const [loading, setLoading] = useState(false);
    const [profileDetails, setProfileDetails] = useState("");

    useEffect(() => {
        setLoading(true);
        axios
            .get(`${apiUrl}/task/booking-detail?bookingId=${decodedId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then((response) => {
                setProfileDetails(response.data.data[0]);
            })
            .catch((error) => {
                toast.error("Fetching error", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [decodedId]);

    const selectedLanguage = localStorage.getItem("selectedLanguage") || "sr";
    updateLocale(selectedLanguage);

    return (
        <>
            {loading ? (
                <NameLoader />
            ) : (
                <div
                    className="plumbing-detail-card"
                    style={{ height: "fit-content" }}
                >
                    <div className="card-header p-4">
                        <h2>{profileDetails.service}</h2>
                        <div className="d-flex align-items-center gap-3">
                            <img
                                src={
                                    profileDetails.profileImage
                                        ? profileDetails.profileImage
                                        : dummyImage
                                }
                                className="person"
                                alt="person"
                            />
                            <div>
                                <h3 className="mb-0">
                                    {profileDetails.firstname}&nbsp;
                                    {profileDetails.lastname}
                                </h3>
                                <div className="mb-2 d-flex gap-1 align-items-center">
                                    <MyRatingComponent
                                        value={profileDetails.averageRating}
                                    />
                                    <span>
                                        {profileDetails.averageRating || 0}
                                    </span>
                                </div>
                                <h6 style={{ width: "fit-content" }}>
                                    {profileDetails.minimumhours}{" "}
                                    {t("Hours Minimum")}
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className="card-body p-4">
                        <div className="d-flex gap-2">
                            <img
                                className="icons"
                                src={calendars}
                                alt="calenders"
                            />
                            <p>
                                {formatDateTime(
                                    profileDetails.date,
                                    profileDetails.time
                                )}
                            </p>
                        </div>
                        <div className="d-flex gap-2">
                            <img
                                className="icons"
                                src={location}
                                alt="location"
                            />
                            <p>{profileDetails.address}</p>
                        </div>
                        <div className="d-flex gap-2">
                            <img className="icons" src={ruler} alt="ruler" />
                            <p className="mb-0">
                                {profileDetails.howBigIsYourTask} {t("Hours")}
                            </p>
                        </div>
                        <div className="m-auto w-50">
                            <Link to="/home" className="coman-btn">
                                {t("End Task")}
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default PlumbingDetails;
