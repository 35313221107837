import React, { useEffect, useState } from "react";
import edit from "../../assets/images/edit-2.png";
import axios from "axios";
import Loader from "../Loader/Loader";
import profile from "../../assets/images/profile.jpg";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";
import PlacesAutocomplete from "react-places-autocomplete";
import { useNavigate } from "react-router-dom";
import ReactFlagsSelect from "react-flags-select";
import { countries } from "countries-list";
import "./EditProfile.css";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const EditProfile = ({ providerProfile = false }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [formData, setFormData] = useState({
        firstname: "",
        lastname: "",
        countrycode: "",
        mobilenumber: "",
        zipcode: "",
        location: "",
        profileImage: null,
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        const fetchProfileData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${apiUrl}/user/profile`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                });

                const profileData = response.data.data;
                setFormData({
                    firstname: profileData.firstname || "",
                    lastname: profileData.lastname || "",
                    countrycode: profileData.countrycode || "",
                    mobilenumber: profileData.mobilenumber || "",
                    zipcode: profileData.zipcode || "",
                    location: profileData.location || "",
                    profileImage: profileData.profileImage || null,
                });

                if (profileData.profileImage) {
                    setSelectedImage(profileData.profileImage);
                }
            } catch (error) {
                // console.error("Error fetching profile data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchProfileData();
    }, []);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedImage(URL.createObjectURL(file));
            setImageFile(file);
        }
    };

    const handleCountrySelect = (code) => {
        const selectedCountry = countries[code.toUpperCase()];
        if (selectedCountry) {
            setFormData({
                ...formData,
                countrycode: `${code} +${selectedCountry.phone}`,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const updatedFormData = {
            ...formData,
            profileImage: imageFile,
        };
        try {
            const response = await axios.put(
                `${apiUrl}/user/profile`,
                updatedFormData,
                {
                    method: "PUT",
                    headers: {
                        accept: "application/json",
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                    body: JSON.stringify(updatedFormData),
                }
            );
            if (response.status === 200) {
                if (providerProfile === true) {
                    navigate("/home-provider");
                } else {
                    navigate("/home");
                }
                toast.success(t("Profile updated successfully"));
            } else {
                // console.error("Error updating profile.");
            }
        } catch (error) {
            toast.error(error.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {loading && <Loader />}
            <div className="box p-sm-5 p-3 my-5">
                <form action="" onSubmit={handleSubmit}>
                    <div className="form-group d-flex flex-column justify-content-center align-items-center mb-4 lh-lg">
                        <input
                            type="file"
                            accept="image/*"
                            className="form-control-file"
                            id="imageUpload"
                            style={{ display: "none" }}
                            name="profileImage"
                            onChange={handleImageChange}
                        />
                        <div className="position-relative">
                            {selectedImage ? (
                                <img
                                    src={selectedImage}
                                    alt="Selected"
                                    className="profile-photo"
                                />
                            ) : (
                                <img
                                    src={profile}
                                    alt="Default"
                                    className="profile-photo"
                                />
                            )}
                            <label
                                htmlFor="imageUpload"
                                className="edit-icon"
                                onChange={handleImageChange}
                            >
                                <img src={edit} alt="edit" />
                            </label>
                        </div>
                        <label className="upload-label">
                            {t("Update Picture")}
                        </label>
                    </div>
                    <div className="row">
                        <div className="mb-3 col-12 col-sm">
                            <label htmlFor="firstName" className="form-label">
                                {t("First Name")}
                            </label>
                            <input
                                value={formData.firstname}
                                onChange={handleChange}
                                name="firstname"
                                type="text"
                                className="form-control"
                                placeholder={t("First Name")}
                            />
                        </div>
                        <div className="mb-3 col-sm col-12">
                            <label htmlFor="lastName" className="form-label">
                                {t("Last Name")}
                            </label>
                            <input
                                value={formData.lastname}
                                onChange={handleChange}
                                name="lastname"
                                type="text"
                                className="form-control"
                                placeholder={t("Last Name")}
                            />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="mobileNumber" className="form-label">
                            {t("Mobile Number")}
                        </label>
                        <div className="position-relative">
                            <input
                                value={formData.mobilenumber}
                                onChange={handleChange}
                                name="mobilenumber"
                                type="number"
                                style={{ paddingLeft: "130px" }}
                                className="form-control"
                                placeholder={t("Mobile Phone")}
                            />
                            <ReactFlagsSelect
                                searchable
                                selected={
                                    formData.countrycode === "+381"
                                        ? "RS"
                                        : formData.countrycode.substring(0, 2)
                                }
                                onSelect={handleCountrySelect}
                                countries={Object.keys(countries)}
                                className="menu-flags position-absolute"
                                customLabels={Object.entries(countries).reduce(
                                    (acc, [code, country]) => {
                                        acc[code] = {
                                            secondary: `+${country.phone}`,
                                        };
                                        return acc;
                                    },
                                    {}
                                )}
                                placeholder={t("Country")}
                            />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="location" className="form-label">
                            {providerProfile === true ? (
                                <>{t("Location")}</>
                            ) : (
                                <>{t("Address")}</>
                            )}
                        </label>
                        <PlacesAutocomplete
                            value={formData.location}
                            onChange={(newValue) =>
                                setFormData({
                                    ...formData,
                                    location: newValue,
                                })
                            }
                            onSelect={async (address) => {
                                const geocoder =
                                    new window.google.maps.Geocoder();
                                geocoder.geocode(
                                    { address: address },
                                    (results, status) => {
                                        if (
                                            status === "OK" &&
                                            results.length > 0
                                        ) {
                                            const locationData =
                                                results[0].geometry.location;
                                            setFormData({
                                                ...formData,
                                                location: address,
                                                lat: locationData
                                                    .lat()
                                                    .toString(),
                                                long: locationData
                                                    .lng()
                                                    .toString(),
                                            });
                                        }
                                    }
                                );
                            }}
                        >
                            {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                            }) => (
                                <div>
                                    <input
                                        {...getInputProps({
                                            placeholder: t(
                                                "Enter Your Location"
                                            ),
                                            className: "form-control",
                                        })}
                                    />
                                    <div className="suggestions-container">
                                        {loading && (
                                            <div>{t("Loading")}...</div>
                                        )}
                                        {suggestions.map(
                                            (suggestion, index) => (
                                                <div
                                                    {...getSuggestionItemProps(
                                                        suggestion
                                                    )}
                                                    key={index}
                                                    className="suggestion-item"
                                                >
                                                    <span className="suggestion-text">
                                                        {suggestion.description}
                                                    </span>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>

                    <div className="mb-5">
                        <label htmlFor="zipCode" className="form-label">
                            {t("Zip Code")}
                        </label>
                        <input
                            value={formData.zipcode}
                            onChange={handleChange}
                            name="zipcode"
                            type="number"
                            className="form-control"
                            placeholder={t("Zip Code")}
                        />
                    </div>
                    <div>
                        <button type="submit" className="coman-btn-fill">
                            {t("Update")}
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default EditProfile;
