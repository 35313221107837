import { Outlet, Navigate } from "react-router-dom";
import { useAuth } from "../Hooks/useAuth";

const PrivateRoutes = ({ allowedRoles }) => {
    const { isAuthenticated, role, checkService, checkAvailability, isVerify } =
        useAuth();

    if (!isAuthenticated) {
        return <Navigate to="/" />;
    }

    if (allowedRoles && !allowedRoles.includes(role)) {
        return <Navigate to="/" />;
    }

    if (role === "2" && (!isVerify || !checkService || !checkAvailability)) {
        return <Navigate to="/" />;
    }

    return <Outlet />;
};

export default PrivateRoutes;
