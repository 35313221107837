import "./Filter.css";
import search from "../../assets/images/Search.png";
import x from "../../assets/images/x.png";
import FilterModal from "../Modal/FilterModal/FilterModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Filter = ({ setFilters, filters, onSearchChange }) => {
    const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState("");
    const handleApplyFilters = (newFilters) => {
        setFilters({ ...filters, ...newFilters });
    };

    const handleSearchChange = (event) => {
        const newSearchTerm = event.target.value;
        setSearchTerm(newSearchTerm);
        onSearchChange(newSearchTerm);
    };
    const handleResetSearch = () => {
        setSearchTerm("");
        onSearchChange("");
    };
    return (
        <div className="col-12 filter d-md-flex align-items-center my-5">
            <div className="col-12 col-md-6 col-xl-8 mb-3 mb-lg-0">
                <h3 className="mb-0">{t("Browse Tasks")}</h3>
            </div>
            <div className="col-12 col-md-6 col-xl-4 d-flex search-wrapper">
                <div className="col-sm-10 col-9 d-flex align-items-center">
                    <form className="form position-relative d-flex align-items-center">
                        <img
                            src={search}
                            className="search-icon"
                            alt="search"
                        />
                        <input
                            className="input"
                            placeholder={t("Search")}
                            required=""
                            type="text"
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                        <button
                            className="reset"
                            type="reset"
                            onClick={handleResetSearch}
                        >
                            <img src={x} alt="close" />
                        </button>
                    </form>
                </div>
                <div className="col-sm-2 col-3 d-flex align-items-center justify-content-end">
                    <FilterModal onApplyFilters={handleApplyFilters} />
                </div>
            </div>
        </div>
    );
};

export default Filter;
