import React from "react";
import "./NameLoader.css";

const NameLoader = () => {
    return (
        <div className="name-loader">
            <div className="box"></div>
            <div className="box"></div>
            <div className="box"></div>
            <div className="box"></div>
        </div>
    );
};

export default NameLoader;
