import { useEffect, useState } from "react";
import tag from "../../../assets/images/tag.png";
import "./ProjectCard.css";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { toast } from "sonner";
import NameLoader from "../../Loader/NameLoader";
import LandingProjectCard from "../TaskandPriceCard/LandingProjectCard";
import { Modal } from "react-bootstrap";
import Loader from "../../Loader/Loader";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const ProjectCard = () => {
    const { t } = useTranslation();

    const [projectCards, setProjectCards] = useState([]);
    const [taskers, setTaskers] = useState(null);
    const [loading, setLoading] = useState(false);
    const [cardLoading, setCardLoading] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const getLanguageHeaderValue = () => {
        const localStorageLanguage = localStorage.getItem("selectedLanguage");
        return localStorageLanguage === "sr" ? "2" : "1";
    };

    useEffect(() => {
        setLoading(true);
        axios
            .get(`${apiUrl}/data/service-details`, {
                headers: {
                    accept: "*/*",
                    language: getLanguageHeaderValue(),
                },
            })
            .then((response) => {
                setProjectCards(response.data.data);
            })
            .catch((error) => {
                toast.error("fetching error", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const handleCardClick = (serviceId) => {
        setCardLoading(true);
        axios
            .get(`${apiUrl}/data/taskers?serviceId=${serviceId}`, {
                headers: {
                    accept: "*/*",
                    language: getLanguageHeaderValue(),
                },
            })
            .then((response) => {
                setTaskers(response.data.data);
                handleShow();
            })
            .catch((error) => {
                toast.error("fetching taskers error", error);
            })
            .finally(() => {
                setCardLoading(false);
            });
    };
    return (
        <div className="cards project-card row row-cols-xxl-4 row-cols-lg-3 g-3">
            {loading && <NameLoader />}
            {cardLoading && <Loader />}
            {projectCards.map((item) => (
                <div className="col" key={item._id}>
                    <div
                        className="card border-0 p-2"
                        onClick={() => handleCardClick(item._id)}
                    >
                        <img src={item.image} className="card-imgs" alt="img" />
                        <div className="card-body">
                            <h3 className="card-title text-center">
                                {item.task}
                            </h3>
                            <p className="text text-center mb-0">
                                <img src={tag} className="tag-icon" alt="tag" />{" "}
                                {t("Avg. Project")}: <span>{item.price}</span>
                            </p>
                        </div>
                    </div>
                </div>
            ))}
            <Modal
                size="lg"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="taskAndPriceDetailModal p-0"
                centered
            >
                <Modal.Header closeButton className="border-0"></Modal.Header>
                <Modal.Body>
                    <LandingProjectCard
                        taskers={taskers}
                        cardLoading={cardLoading}
                    />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ProjectCard;
