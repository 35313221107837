import axios from "axios";
import React from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Calendar from "react-calendar";
import { toast } from "sonner";
import dummyImage from "../../../assets/images/profile.jpg";
import moment from "moment";
import { useTranslation } from "react-i18next";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const ChangeDateModal = ({ selectChangeDateTask, onDateChangeSuccess }) => {
    const { t, i18n } = useTranslation();
    const [show, setShow] = useState(false);
    const [activeDate, setActiveDate] = useState(new Date());

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [selectedTime, setSelectedTime] = useState();
    const [availableTimes, setAvailableTimes] = useState([]);

    const handleDateChange = (newDate) => {
        setActiveDate(newDate);
        const date = new Date(newDate);
        const formattedDate =
            date.getTime() - date.getTimezoneOffset() * 60 * 1000;
        axios
            .get(
                `${apiUrl}/task/date-time?taskerId=${selectChangeDateTask.taskerId}&date=${formattedDate}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                }
            )
            .then((response) => {
                setSelectedTime("");
                setAvailableTimes(response.data.data);
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            });
    };

    const handleTimeChange = (event) => {
        const newTime = event.target.value;
        setSelectedTime(newTime);
    };

    const handleSelectAndContinue = () => {
        const selectedDateTime = moment(activeDate)
            .hour(parseInt(selectedTime.slice(0, 2), 10))
            .minute(parseInt(selectedTime.slice(3, 5), 10))
            .utc();

        const timestamp = selectedDateTime.valueOf();

        const bookingId = selectChangeDateTask._id;

        axios
            .post(
                `${apiUrl}/task/reschedule`,
                {
                    date: timestamp.toString(),
                    time: timestamp.toString(),
                    bookingId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                }
            )
            .then((response) => {
                if (response.status === 200) {
                    toast.success(t("Date change succsessfully"));
                    setShow(false);
                    onDateChangeSuccess();
                } else {
                    toast.error("API error: " + response.data);
                }
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            });
    };

    const formattedDate = activeDate.toLocaleDateString(undefined, {
        month: "long",
        day: "numeric",
    });

    const formattedTime = moment(selectedTime, "HH:mm").format("hh:mm A");

    return (
        <div>
            <button
                className="border-0 bg-white add-btn change-btn mb-0"
                onClick={handleShow}
            >
                {t("Change")}
            </button>
            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                backdrop="static"
                keyboard={false}
                centered
                className="calender-modal"
            >
                <Modal.Header closeButton className="border-0"></Modal.Header>
                <Modal.Body className="text-center px-md-5">
                    <h1 className="mb-3">
                        {t("Reschedule your task date and start time:")}
                    </h1>
                    <div className="row g-4">
                        <div className="col-lg-8 col-12 left">
                            <div className="mt-4">
                                <div className="d-flex gap-2 align-items-center flex-wrap">
                                    <img
                                        src={
                                            selectChangeDateTask.profileImage
                                                ? selectChangeDateTask.profileImage
                                                : dummyImage
                                        }
                                        className="person-img"
                                        alt="person"
                                    />
                                    <h3 className="mb-0">
                                        {selectChangeDateTask.firstname}&nbsp;
                                        {selectChangeDateTask.lastname}
                                    </h3>
                                    <h6 className="mb-0 opacity-50">
                                        (Availability)
                                    </h6>
                                </div>

                                <div className="mt-3">
                                    <Calendar
                                        onChange={handleDateChange}
                                        value={activeDate}
                                        minDate={new Date()}
                                        locale={i18n.language}
                                    />
                                </div>

                                <div className="my-2">
                                    <select
                                        value={selectedTime}
                                        onChange={handleTimeChange}
                                        className="form-select"
                                    >
                                        {Array.isArray(availableTimes) &&
                                        availableTimes.length > 0 ? (
                                            <>
                                                <option value="">
                                                    {t("Please select time")}
                                                </option>
                                                {availableTimes.map((time) => {
                                                    const formattedTime =
                                                        moment(
                                                            time,
                                                            "HH:mm"
                                                        ).format("hh:mm A");
                                                    return (
                                                        <option
                                                            key={time}
                                                            value={time}
                                                        >
                                                            {formattedTime}
                                                        </option>
                                                    );
                                                })}
                                            </>
                                        ) : (
                                            <option value="">
                                                {t("Tasker is not available.")}
                                            </option>
                                        )}
                                    </select>
                                </div>

                                <div>
                                    <p>
                                        {t(
                                            "You can chat with your tasker, adjust task details or change task time after booking."
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-12">
                            <div className="d-flex justify-content-center align-items-start flex-column h-100">
                                <h5 className="mb-3">{t("Request for:")}</h5>
                                {selectedTime ? (
                                    <h4 className="mb-3">
                                        {formattedDate}, {formattedTime}
                                    </h4>
                                ) : (
                                    <h4 className="mb-3">
                                        Please select a date & time.
                                    </h4>
                                )}
                                <button
                                    className="coman-btn-fill"
                                    onClick={handleSelectAndContinue}
                                >
                                    {t("Confirm")}
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ChangeDateModal;
