import { useState } from "react";
import "./Description.css";
import { useTranslation } from "react-i18next";

const Description = ({ description }) => {
    const { t } = useTranslation();
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };
    return (
        <div className="description p-3">
            <h4>{t("Description")}</h4>
            <p className="mb-0">
                {isExpanded ? description : description.slice(0, 150)}
                {description.length > 150 && !isExpanded && (
                    <span className="read-more" onClick={toggleReadMore}>
                        &nbsp;{t("Read More")}
                    </span>
                )}
                {description.length > 150 && isExpanded && (
                    <span className="read-more" onClick={toggleReadMore}>
                        &nbsp;{t("Read Less")}
                    </span>
                )}
            </p>
        </div>
    );
};

export default Description;
