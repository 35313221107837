import { Link, NavLink, useNavigate } from "react-router-dom";
import "./Navbar.css";
import user from "../../assets/images/user.png";
import translate from "../../assets/images/translate.png";
import user2 from "../../assets/images/2User.png";
import paper from "../../assets/images/paper.png";
import shield from "../../assets/images/shieldDone.png";
import logout from "../../assets/images/logout.png";
import rightArrow from "../../assets/images/right-arrow.png";
import chatBlack from "../../assets/images/chatBlack.png";
import notification from "../../assets/images/notification.png";
import home from "../../assets/images/Home.png";
import document from "../../assets/images/Document.png";
// import share from "../../assets/images/share.png";
import plus from "../../assets/images/Plus.png";
import DeleteAccModal from "../Modal/DeleteAccount/DeleteAccModal";
import logo from "../../assets/images/Original.png";
import deleteImg from "../../assets/images/deleteAcc.png";
import LogoutModal from "../Modal/LogOut/LogoutModal";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { toast } from "sonner";
import dummyImage from "../../assets/images/profile.jpg";
import star2 from "../../assets/images/Star-2.png";
import { useTranslation } from "react-i18next";
import { Container, Navbar } from "react-bootstrap";
import MyRatingComponent from "../MyRatingComponent";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const NavbarMain = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [profileData, setProfileData] = useState([]);
    const [averageRating, setAverageRating] = useState(null);
    const [expanded, setExpanded] = useState(false);

    const handleNavbarToggle = () => {
        setExpanded(!expanded);
    };

    const handleNavLinkClick = () => {
        setExpanded(false);
    };

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/user/profile`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                });

                setProfileData(response.data.data);
                setAverageRating(response.data.data.avarage);
            } catch (error) {
                toast.error("Fetching error", error.response.data.message);
            }
        };
        fetchProfileData();
    }, [navigate]);

    return (
        <>
            <Navbar
                expand="lg"
                className="bg-body-tertiary"
                expanded={expanded}
            >
                <Container>
                    <Navbar.Brand>
                        <Link className="navbar-brand" to="/home">
                            <img src={logo} alt="logo" />
                        </Link>
                    </Navbar.Brand>
                    <div>
                        <LogoutModal icon="true" />
                        <Navbar.Toggle onClick={handleNavbarToggle} />
                    </div>
                    <Navbar.Collapse className="justify-content-end main-navbar navbar-nav">
                        <div className="d-flex align-items-lg-center align-items-start gap-3 gap-xl-4 flex-column w-100 flex-lg-row justify-content-lg-end">
                            <div className="d-flex align-items-center gap-3 dropdown-item d-lg-none">
                                <img
                                    src={
                                        profileData.profileImage
                                            ? profileData.profileImage
                                            : dummyImage
                                    }
                                    alt="person"
                                    className="person-img"
                                />
                                <div>
                                    <h3 className="mb-0">
                                        {profileData.firstname}
                                        &nbsp;
                                        {profileData.lastname}
                                    </h3>
                                    <p className="mb-0">{profileData.email}</p>
                                    <div className="d-flex align-items-center gap-1">
                                        {averageRating !== null && (
                                            <MyRatingComponent
                                                value={averageRating}
                                            />
                                        )}

                                        {averageRating !== null ? (
                                            <span>
                                                {averageRating.toFixed(1)}
                                            </span>
                                        ) : (
                                            <span>{t("N/A")}</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <NavLink
                                to="/home"
                                className="d-flex align-items-center gap-2"
                                onClick={handleNavLinkClick}
                            >
                                <img
                                    className="nav-link-icon"
                                    src={home}
                                    alt="home"
                                />
                                <p className="mb-0">{t("Home")}</p>
                            </NavLink>
                            {/* <NavLink
                                to="/refer"
                                className="d-flex align-items-center gap-2"
                                onClick={handleNavLinkClick}
                            >
                                <img
                                    className="nav-link-icon"
                                    src={share}
                                    alt="home"
                                />
                                <p className="mb-0">{t("Get 10 din")}</p>
                            </NavLink> */}
                            <NavLink
                                to="/task-post"
                                className="d-flex align-items-center gap-2"
                                onClick={handleNavLinkClick}
                            >
                                <img
                                    className="nav-link-icon"
                                    src={plus}
                                    alt="home"
                                />
                                <p className="mb-0">{t("Task Post")}</p>
                            </NavLink>
                            <NavLink
                                to="/mytask"
                                className="d-flex align-items-center gap-2"
                                onClick={handleNavLinkClick}
                            >
                                <img
                                    className="nav-link-icon"
                                    src={document}
                                    alt="home"
                                />
                                <p className="mb-0">{t("My Tasks")}</p>
                            </NavLink>
                            <div className="d-lg-none d-flex flex-column gap-3">
                                <NavLink
                                    to="/edit-profile"
                                    className="d-flex align-items-center gap-2"
                                    onClick={handleNavLinkClick}
                                >
                                    <img
                                        className="nav-link-icon"
                                        src={user}
                                        alt="home"
                                    />
                                    <p className="mb-0">{t("Edit Profile")}</p>
                                </NavLink>
                                <NavLink
                                    to="/languages"
                                    className="d-flex align-items-center gap-2"
                                    onClick={handleNavLinkClick}
                                >
                                    <img
                                        className="nav-link-icon"
                                        src={translate}
                                        alt="translate"
                                    />
                                    <p className="mb-0">{t("Languages")}</p>
                                </NavLink>
                                <NavLink
                                    to="/reviews"
                                    className="d-flex align-items-center gap-2"
                                    onClick={handleNavLinkClick}
                                >
                                    <img
                                        className="nav-link-icon"
                                        src={star2}
                                        alt="star2"
                                    />
                                    <p className="mb-0">
                                        {t("Reviews and Ratings")}
                                    </p>
                                </NavLink>
                                <NavLink
                                    to="/customer-support"
                                    className="d-flex align-items-center gap-2"
                                    onClick={handleNavLinkClick}
                                >
                                    <img
                                        className="nav-link-icon"
                                        src={user2}
                                        alt="user2"
                                    />
                                    <p className="mb-0">
                                        {t("Customer Support")}
                                    </p>
                                </NavLink>
                                <NavLink
                                    to="/faqs"
                                    className="d-flex align-items-center gap-2"
                                    onClick={handleNavLinkClick}
                                >
                                    <img
                                        className="nav-link-icon"
                                        src={user2}
                                        alt="user2"
                                    />
                                    <p className="mb-0">{t("FAQs")}</p>
                                </NavLink>
                                <NavLink
                                    to="/terms-and-conditions"
                                    className="d-flex align-items-center gap-2"
                                    onClick={handleNavLinkClick}
                                >
                                    <img
                                        className="nav-link-icon"
                                        src={paper}
                                        alt="paper"
                                    />
                                    <p className="mb-0">
                                        {t("Terms & Conditions")}
                                    </p>
                                </NavLink>
                                <NavLink
                                    to="/privacy-policy"
                                    className="d-flex align-items-center gap-2"
                                    onClick={handleNavLinkClick}
                                >
                                    <img
                                        className="nav-link-icon"
                                        src={shield}
                                        alt="shield"
                                    />
                                    <p className="mb-0">
                                        {t("Privacy Policy")}
                                    </p>
                                </NavLink>
                            </div>
                            <NavLink
                                to="/notification"
                                className="position-relative"
                                onClick={handleNavLinkClick}
                            >
                                <img
                                    className="nav-icon nav-img-icon"
                                    src={notification}
                                    alt="notification"
                                />
                                {profileData.notificatoin === 0 ? (
                                    <></>
                                ) : (
                                    <span className="badge rounded-circle bell">
                                        {profileData.notificatoin}
                                    </span>
                                )}
                            </NavLink>
                            <NavLink
                                to="/message"
                                className="position-relative"
                                onClick={handleNavLinkClick}
                            >
                                <img
                                    className="nav-icon nav-img-icon"
                                    src={chatBlack}
                                    alt="chat"
                                />
                                {profileData.chat === 0 ? (
                                    <></>
                                ) : (
                                    <span className="badge rounded-circle bell">
                                        {profileData.chat}
                                    </span>
                                )}
                            </NavLink>
                            <ul className="navbar-nav d-lg-block d-none">
                                <li className="nav-item dropdown">
                                    <div
                                        className="nav-link dropdown-toggle d-flex align-items-center gap-2"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <img
                                            src={
                                                profileData.profileImage
                                                    ? profileData.profileImage
                                                    : dummyImage
                                            }
                                            alt="person"
                                            className="nav-profile-img"
                                        />
                                        {profileData.firstname}&nbsp;
                                        {profileData.lastname}
                                    </div>
                                    <ul className="dropdown-menu dropdown-menu-end p-3">
                                        <li>
                                            <div className="d-flex align-items-center gap-3 dropdown-item">
                                                <img
                                                    src={
                                                        profileData.profileImage
                                                            ? profileData.profileImage
                                                            : dummyImage
                                                    }
                                                    alt="person"
                                                    className="person-img"
                                                />
                                                <div>
                                                    <h3 className="mb-0">
                                                        {profileData.firstname}
                                                        &nbsp;
                                                        {profileData.lastname}
                                                    </h3>
                                                    <p className="mb-0">
                                                        {profileData.email}
                                                    </p>
                                                    <div className="d-flex align-items-center gap-1">
                                                        {averageRating !==
                                                            null && (
                                                            <MyRatingComponent
                                                                value={
                                                                    averageRating
                                                                }
                                                            />
                                                        )}

                                                        {averageRating !==
                                                        null ? (
                                                            <span>
                                                                {averageRating.toFixed(
                                                                    1
                                                                )}
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                {t("N/A")}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <hr className="hr" />
                                        <li className="my-3">
                                            <Link
                                                to="/edit-profile"
                                                className="dropdown-item d-flex align-items-center justify-content-between"
                                            >
                                                <div className="d-flex align-items-center gap-2">
                                                    <img
                                                        src={user}
                                                        alt="user"
                                                        className="icon"
                                                    />
                                                    <h4 className="mb-0">
                                                        {t("Edit Profile")}
                                                    </h4>
                                                </div>
                                                <img
                                                    src={rightArrow}
                                                    alt="rightArrow"
                                                    className="icon"
                                                />
                                            </Link>
                                        </li>
                                        <li className="my-3">
                                            <Link
                                                to="/languages"
                                                className="dropdown-item d-flex align-items-center justify-content-between"
                                            >
                                                <div className="d-flex align-items-center gap-2">
                                                    <img
                                                        src={translate}
                                                        alt="user"
                                                        className="icon"
                                                    />
                                                    <h4 className="mb-0">
                                                        {t("Languages")}
                                                    </h4>
                                                </div>
                                                <img
                                                    src={rightArrow}
                                                    alt="rightArrow"
                                                    className="icon"
                                                />
                                            </Link>
                                        </li>
                                        <li className="my-3">
                                            <Link
                                                to="/reviews"
                                                className="dropdown-item d-flex align-items-center justify-content-between"
                                            >
                                                <div className="d-flex align-items-center gap-2">
                                                    <img
                                                        src={star2}
                                                        alt="user"
                                                        className="icon"
                                                    />
                                                    <h4 className="mb-0">
                                                        {t(
                                                            "Reviews and Ratings"
                                                        )}
                                                    </h4>
                                                </div>
                                                <img
                                                    src={rightArrow}
                                                    alt="rightArrow"
                                                    className="icon"
                                                />
                                            </Link>
                                        </li>
                                        <li className="my-3">
                                            <Link
                                                to="/customer-support"
                                                className="dropdown-item d-flex align-items-center justify-content-between"
                                            >
                                                <div className="d-flex align-items-center gap-2">
                                                    <img
                                                        src={user2}
                                                        alt="user"
                                                        className="icon"
                                                    />
                                                    <h4 className="mb-0">
                                                        {t("Customer Support")}
                                                    </h4>
                                                </div>
                                                <img
                                                    src={rightArrow}
                                                    alt="rightArrow"
                                                    className="icon"
                                                />
                                            </Link>
                                        </li>
                                        <li className="my-3">
                                            <Link
                                                to="/faqs"
                                                className="dropdown-item d-flex align-items-center justify-content-between"
                                            >
                                                <div className="d-flex align-items-center gap-2">
                                                    <img
                                                        src={user2}
                                                        alt="user"
                                                        className="icon"
                                                    />
                                                    <h4 className="mb-0">
                                                        {t("FAQs")}
                                                    </h4>
                                                </div>
                                                <img
                                                    src={rightArrow}
                                                    alt="rightArrow"
                                                    className="icon"
                                                />
                                            </Link>
                                        </li>
                                        <li className="my-3">
                                            <Link
                                                to="/terms-and-conditions"
                                                className="dropdown-item d-flex align-items-center justify-content-between"
                                            >
                                                <div className="d-flex align-items-center gap-2">
                                                    <img
                                                        src={paper}
                                                        alt="user"
                                                        className="icon"
                                                    />
                                                    <h4 className="mb-0">
                                                        {t(
                                                            "Terms & Conditions"
                                                        )}
                                                    </h4>
                                                </div>
                                                <img
                                                    src={rightArrow}
                                                    alt="rightArrow"
                                                    className="icon"
                                                />
                                            </Link>
                                        </li>
                                        <li className="my-3">
                                            <Link
                                                to="/privacy-policy"
                                                className="dropdown-item d-flex align-items-center justify-content-between"
                                            >
                                                <div className="d-flex align-items-center gap-2">
                                                    <img
                                                        src={shield}
                                                        alt="user"
                                                        className="icon"
                                                    />
                                                    <h4 className="mb-0">
                                                        {t("Privacy Policy")}
                                                    </h4>
                                                </div>
                                                <img
                                                    src={rightArrow}
                                                    alt="rightArrow"
                                                    className="icon"
                                                />
                                            </Link>
                                        </li>
                                        <li className="my-2">
                                            <div className="dropdown-item d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items-center gap-2">
                                                    <img
                                                        src={deleteImg}
                                                        alt="user"
                                                        className="icon"
                                                    />
                                                    <DeleteAccModal />
                                                </div>
                                            </div>
                                        </li>
                                        <li className="my-2">
                                            <div className="dropdown-item d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items-center gap-2">
                                                    <img
                                                        src={logout}
                                                        alt="user"
                                                        className="icon"
                                                    />
                                                    <LogoutModal />
                                                </div>
                                            </div>
                                        </li>
                                        <li className="mt-5 text-center">
                                            <p className="footer mb-0">
                                                {t("Developed by")}
                                                <span>{t("Poslic.rs.")}</span>
                                            </p>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
};

export default NavbarMain;
