import { Link } from "react-router-dom";
import "./RatingReviews.css";
import leftArrow from "../../assets/images/left-arrow.png";
import dummyImage from "../../assets/images/profile.jpg";
import rightArrow from "../../assets/images/right-arrow-2.png";
import star from "../../assets/images/Rating-Star.png";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "sonner";
import Loader from "../../components/Loader/Loader";
import moment from "moment";
import MyRatingComponent from "../../components/MyRatingComponent";
import { useTranslation } from "react-i18next";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const Index = ({ ratingLocation }) => {
    const { t } = useTranslation();
    const [reviews, setReviews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [averageRating, setAverageRating] = useState(null);

    useEffect(() => {
        axios
            .get(`${apiUrl}/feedback/`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then((response) => {
                setReviews(response.data.data.result.feedbackList);
                setAverageRating(response.data.data.result.averageRating);
            })
            .catch((error) => {
                toast.error("Fetching error", error);
                // console.error("Fetching error:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const reviewsPerPage = 5;
    const [currentPage, setCurrentPage] = useState(1);

    const startIndex = (currentPage - 1) * reviewsPerPage;
    const endIndex = startIndex + reviewsPerPage;

    const displayedReviews = reviews && reviews.slice(startIndex, endIndex);

    const totalPages = Math.ceil((reviews && reviews.length) / reviewsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    return (
        <div className="rating-review-wrapper flex-1">
            {loading && <Loader />}
            <div className="container">
                <div className="col-md-10 offset-md-1">
                    <div className="grid-3 my-4">
                        <Link to={ratingLocation} className="back-btn">
                            <img src={leftArrow} alt="leftArrow" />
                        </Link>
                        <h1 className="text-center d-flex justify-content-center gap-2 align-items-center mb-0">
                            {t("Rating & Reviews")}
                        </h1>
                        <div className="d-flex flex-column">
                            <p className="mb-0">{t("Overall Rating")}</p>
                            <div className="d-flex align-items-center remove gap-1">
                                {averageRating !== null && (
                                    <MyRatingComponent value={averageRating} />
                                )}

                                {averageRating !== null ? (
                                    <span>{averageRating.toFixed(1)}</span>
                                ) : (
                                    <span>{t("N/A")}</span>
                                )}
                            </div>
                        </div>
                    </div>{" "}
                    <hr />
                    <div className="col-12 col-lg-8 offset-0 offset-lg-2 mb-5">
                        {displayedReviews.length > 0 ? (
                            displayedReviews.map((item) => (
                                <div
                                    className="reviews-wrapper p-3 mb-3"
                                    key={item._id}
                                >
                                    <div className="d-flex align-items-center gap-2 mb-2">
                                        <img
                                            src={
                                                item.profileImage
                                                    ? item.profileImage
                                                    : dummyImage
                                            }
                                            alt="person"
                                            className="profile-photo"
                                        />
                                        <div>
                                            <div className="d-flex align-items-center gap-2">
                                                <h3 className="mb-0">
                                                    {item.firstname}
                                                    &nbsp;
                                                    {item.lastname}
                                                </h3>
                                                <span className="px-2 py-1">
                                                    {item.service}
                                                </span>
                                            </div>
                                            <div className="d-flex align-items-center gap-2">
                                                <img
                                                    src={star}
                                                    className="star"
                                                    alt="star"
                                                />
                                                <h5 className="mb-0">
                                                    {item.rate}
                                                </h5>
                                                <h6 className="mb-0">
                                                    on&nbsp;
                                                    {moment(
                                                        item.createdAt
                                                    ).format("ddd, MMM D")}
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="mb-0">{item.review}</p>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>{t("No feedback available.")}</p>
                        )}

                        {totalPages > 1 && (
                            <div className="pagination">
                                <button
                                    onClick={() =>
                                        handlePageChange(currentPage - 1)
                                    }
                                    disabled={currentPage === 1}
                                    className="bg-white border-0"
                                >
                                    <img
                                        src={leftArrow}
                                        alt="leftArrow"
                                        className="arrow-page"
                                    />
                                </button>
                                {Array.from({ length: totalPages }, (_, i) => (
                                    <button
                                        key={i + 1}
                                        onClick={() => handlePageChange(i + 1)}
                                        className={`${
                                            i + 1 === currentPage
                                                ? "active"
                                                : ""
                                        } pagination-btn`}
                                    >
                                        {i + 1}
                                    </button>
                                ))}
                                <button
                                    onClick={() =>
                                        handlePageChange(currentPage + 1)
                                    }
                                    disabled={currentPage === totalPages}
                                    className="bg-white border-0"
                                >
                                    <img
                                        src={rightArrow}
                                        alt="rightArrow"
                                        className="arrow-page"
                                    />
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
