import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { useLanguage } from "../../../context/LanguageContext";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const AddMoreServicesModal = ({ onServiceAdded }) => {
    const { t } = useTranslation();
    const { selectedLanguage } = useLanguage();
    const [service, setService] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [formData, setFormData] = useState({
        serviceId: "",
        aboutMe: "",
        skillsAndExperience: "",
        minimumhours: "",
        rate: "",
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        axios
            .get(`${apiUrl}/data/filter`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then((response) => {
                setService(response.data.data);
            })
            .catch((error) => {
                toast.error("fetching error", error);
            });
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let response;
        try {
            response = await axios.post(`${apiUrl}/tasker/`, [formData], {
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            });

            if (response.status === 200) {
                toast.success(t("Service Create Successfully"));
                if (onServiceAdded) {
                    onServiceAdded();
                }
                setShow(false);
                setFormData({
                    serviceId: "",
                    aboutMe: "",
                    skillsAndExperience: "",
                    minimumhours: "",
                    rate: "",
                });
            }
        } catch (error) {
            // console.error("Service Create error:", error);

            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                toast.error("Failed: " + error.response.data.message);
            } else {
                toast.error("Failed: " + error.message);
            }
        }
    };

    return (
        <>
            <button
                type="button"
                className="mb-3 border-0 bg-white"
                style={{
                    color: "#0D7A5F",
                    fontSize: "16px",
                    fontWeight: "600",
                }}
                onClick={handleShow}
            >
                {t("+Add More Service")}
            </button>

            <Modal
                size="lg"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="taskAndPriceDetailModal p-0"
                centered
            >
                {/* {loading && <Loader />} */}
                <Modal.Header closeButton className="border-0"></Modal.Header>
                <Modal.Body>
                    <div className="box p-2 pt-0">
                        <form action="" onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="service" className="form-label">
                                    {t("Select Your Service")}
                                </label>
                                <select
                                    className="form-select"
                                    value={formData.serviceId}
                                    onChange={handleChange}
                                    name="serviceId"
                                >
                                    <option value="">
                                        {t("Choose Your Service")}
                                    </option>
                                    {Array.isArray(service) &&
                                        service.map((item) => (
                                            <option
                                                key={item._id}
                                                value={item._id}
                                            >
                                                {selectedLanguage === "en"
                                                    ? item.task
                                                    : item.serbianTask}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="AboutMe" className="form-label">
                                    {t("About Me")}
                                </label>
                                <textarea
                                    rows="5"
                                    className="form-control"
                                    value={formData.aboutMe}
                                    onChange={handleChange}
                                    name="aboutMe"
                                ></textarea>
                            </div>
                            <div className="mb-3">
                                <label
                                    htmlFor="SkillsExperience"
                                    className="form-label"
                                >
                                    {t("Skills & Experience")}
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={formData.skillsAndExperience}
                                    onChange={handleChange}
                                    name="skillsAndExperience"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="task" className="form-label">
                                    {t(
                                        "Required Minimum Hours to Complete Task"
                                    )}
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={formData.minimumhours}
                                    onChange={handleChange}
                                    name="minimumhours"
                                />
                            </div>
                            <div>
                                <label htmlFor="rate" className="form-label">
                                    {t("Rate/hr")}
                                </label>
                                <div className="position-relative">
                                    <input
                                        type="number"
                                        className="form-control pe-5"
                                        value={formData.rate}
                                        onInput={(e) => {
                                            e.target.value = Math.max(
                                                0,
                                                parseInt(e.target.value)
                                            )
                                                .toString()
                                                .slice(0, 5);
                                        }}
                                        onChange={handleChange}
                                        name="rate"
                                    />
                                    <span
                                        className="position-absolute bg-transparent fs-6 text-success"
                                        style={{ top: "14px", right: "13px" }}
                                    >
                                        {t("din")}
                                    </span>
                                </div>
                            </div>
                            <div className="mt-4">
                                <button
                                    type="submit"
                                    onClick={handleSubmit}
                                    className="coman-btn-fill"
                                >
                                    {t("+Add Service")}
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddMoreServicesModal;
