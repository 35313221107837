import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";
import deleteImg from "../../../assets/images/deleteAcc.png";
import Loader from "../../Loader/Loader";
import "./DeleteAccModal.css";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const DeleteAccModal = ({ icon }) => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const navigate = useNavigate();

    const handleLogout = async (e) => {
        e.preventDefault();
        const localStorageLanguage = localStorage.getItem("selectedLanguage");

        let languageHeaderValue = "1";
        if (localStorageLanguage === "sr") {
            languageHeaderValue = "2";
        }
        setLoading(true);
        try {
            const response = await axios.delete(`${apiUrl}/user/delete`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                    language: languageHeaderValue,
                },
            });
            if (response.status === 200) {
                localStorage.clear("accessToken");
                localStorage.clear();
                navigate("/");
                toast.success(response.data.message);
            }
        } catch (error) {
            toast.error(error.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {icon === "true" ? (
                <img
                    className="logout-img me-2 d-lg-none"
                    src={deleteImg}
                    alt="deleteImg"
                    onClick={handleShow}
                />
            ) : (
                <button
                    className="border-0 bg-body w-100"
                    style={{
                        color: "#B00505",
                        opacity: "0.8",
                        fontSize: "18px",
                        fontWeight: "500",
                    }}
                    onClick={handleShow}
                >
                    {t("Delete Account")}
                </button>
            )}

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                className="deleteAccModal"
                dialogClassName="my-modal"
            >
                {loading && <Loader />}
                <Modal.Body className="px-5 pt-4">
                    <h1 className="text-center mb-4">{t("Delete Account")}</h1>
                    <h5 className="text-center">
                        {t("Are you sure want to delete account?")}
                    </h5>
                </Modal.Body>
                <Modal.Footer className="border-0 row px-5 pb-4">
                    <div className="col">
                        <button
                            className="coman-btn fw-medium"
                            onClick={handleClose}
                        >
                            {t("Cancel")}
                        </button>
                    </div>
                    <div className="col">
                        <button
                            className="coman-btn-fill fw-medium"
                            onClick={handleLogout}
                        >
                            {t("Delete")}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DeleteAccModal;
