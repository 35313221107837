import search from "../assets/images/Search.png";
import serviceBg from "../assets/images/service-bg.png";
import simpleBg from "../assets/images/simple-bg.png";
import FeaturedSlider from "../components/Slider/FeaturedSlider/FeaturedSlider";
import ProjectCard from "../components/Cards/ProjectCard/ProjectCard";
import "./Landing.css";
import TestimonialSlider from "../components/Slider/TestimonialSlider/TestimonialSlider";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLanguage } from "../context/LanguageContext";
import Select from "react-select";
import axios from "axios";
import { toast } from "sonner";
import { Helmet } from "react-helmet";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const Landing = () => {
    const { t } = useTranslation();
    const { selectedLanguage } = useLanguage();
    const navigate = useNavigate();
    const [searchResults, setSearchResults] = useState([]);

    useEffect(() => {
        const localStorageLanguage = localStorage.getItem("selectedLanguage");

        let languageHeaderValue = "1";
        if (localStorageLanguage === "sr") {
            languageHeaderValue = "2";
        }
        axios
            .get(`${apiUrl}/data/category-data`, {
                headers: {
                    accept: "*/*",
                    language: languageHeaderValue,
                },
            })
            .then((response) => {
                setSearchResults(response.data.data);
            })
            .catch((error) => {
                toast.error("fetching error", error);
            });
    }, []);
    return (
        <div className="landing_page">
            <Helmet>
                <title>
                    Poslic: Brze i pouzdane popravke, selidbe, čišćenje i druge
                    usluge
                </title>
                <meta
                    name="description"
                    content="Poslic: Same-day service for repairs, moving, cleaning, & more. Skilled workers in home maintenance, electrical, lifting, gardening, & plumbing."
                />
                <meta
                    name="keywords"
                    content="same day service in serbia,repair service in Serbia,moving service in serbia,cleaning solutions in serbia,Helpers in Serbia ,Expert in serbia,furniture assembly,home cleaning,plumbing repairs,electrical help,heavy lifting,academic tasks,plumbers near me,water heater repair,house cleaning services,housekeeping,drain repair near me,septic tank repair,birthday decoration near me,event organisers,event management,Electrician near me,catering services,electrical services near me,handyman near me,transportation services,furniture installation,party decorators near me,handyman ,Gardening service"
                />
            </Helmet>
            <section className="bg-banner bg-image h-100">
                <div className="container d-flex justify-content-start align-items-center bg-image h-100 py-5">
                    <div className="bg-box d-flex justify-content-center align-items-center flex-column py-4 px-sm-5 px-3">
                        <h1 className="text-center">
                            {t(
                                "Poslić - Quickly and Easily Find Experts and Helpers in Serbia!"
                            )}
                        </h1>
                        <p>
                            {t(
                                "Find reliable and skilled professionals for every task - from household repairs to personal assistants"
                            )}
                        </p>
                        <div className="group mt-2">
                            <div className="position-relative">
                                <img
                                    src={search}
                                    alt="search"
                                    className="icon"
                                />
                                <Select
                                    options={searchResults.map((item) => ({
                                        value: item._id,
                                        label:
                                            selectedLanguage === "en"
                                                ? item.task
                                                : item.serbianTask,
                                    }))}
                                    placeholder={t("searchPlaceholder")}
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            borderRadius: "10px",
                                            border: "2px solid rgba(214, 214, 214, 0.90)",
                                            backgroundColor: "#F6F6F6",
                                            minHeight: "50px",
                                            fontSize: "18px",
                                            fontWeight: "500",
                                            opacity: "0.7",
                                            color: "#000",
                                            margin: "15px 0",
                                            paddingLeft: "35px",
                                        }),
                                    }}
                                    onChange={() => {
                                        navigate("/login");
                                    }}
                                />
                            </div>
                            <button className="border-0">
                                {t("Get help today")}
                            </button>
                        </div>

                        <div className="w-100 mt-3">
                            <Link to="/login" className="bg-box-btn mb-2">
                                {t("Home Repairs")}
                            </Link>
                            <Link to="/login" className="bg-box-btn mb-2">
                                {t("TV Mounting")}
                            </Link>
                            <Link to="/login" className="bg-box-btn mb-2">
                                {t("Furniture Assembly")}
                            </Link>
                        </div>
                    </div>
                </div>
            </section>

            <section className="my-5 container">
                <h2 className="mb-4">{t("Popular projects in your area")}</h2>
                <ProjectCard />
            </section>

            <section className="service-section position-relative py-5 my-5">
                <img
                    src={serviceBg}
                    className="w-100 object-fit-cover position-absolute top-0 z-n1 h-100"
                    alt="service"
                />
                <div className=" container">
                    <div className="col col-xl-6 col-lg-8">
                        <h4 className="h4">{t("Everyday life made easier")}</h4>
                        <p style={{ paddingRight: "61px" }}>
                            {t(
                                "When life gets busy, you don’t have to tackle it alone. Get time back for what you love without breaking the bank"
                            )}
                            .
                        </p>
                        <ul className="ps-2">
                            <li>
                                {t(
                                    "Choose your Tasker by reviews, skills, and price"
                                )}
                            </li>
                            <li>
                                {t(
                                    "Schedule when it works for you — as early as today"
                                )}
                            </li>
                            <li>
                                {t(
                                    "Chat, pay, tip, and review all through one platform"
                                )}
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="my-5 container">
                <h4 className="mb-4 h4">{t("Featured Taskers")}</h4>
                <FeaturedSlider />
            </section>

            <section className="service-section position-relative py-5 my-5">
                <div className=" container">
                    <div className="col offset-xl-6 col-xl-6 col-lg-8">
                        <h4 className="h4">
                            {t("A go-to team at your fingertips")}
                        </h4>
                        <p style={{ paddingRight: "65px" }}>
                            {t(
                                "Build your team of local, background-checked Taskers to help with — and for — life. Whatever you need, they’ve got it covered"
                            )}
                            .
                        </p>
                        <ul className="ps-2">
                            <li>
                                {t(
                                    "Compare Tasker reviews, ratings, and prices"
                                )}
                            </li>
                            <li>
                                {t(
                                    "Choose and connect with the best person for the job"
                                )}
                            </li>
                            <li>
                                {t(
                                    "Save your favorites to book again and again"
                                )}
                            </li>
                        </ul>
                    </div>
                </div>
                <img
                    src={simpleBg}
                    className="w-100 object-fit-cover position-absolute top-0 z-n1 h-100"
                    alt="service"
                />
            </section>

            <section className="my-5 container testimonial-section">
                <h4 className="h4">{t("Don't just take our word for it")}</h4>
                <h6>
                    {t(
                        "See how Taskers are saving the day for people like you"
                    )}
                    .
                </h6>
                <TestimonialSlider />
            </section>
        </div>
    );
};

export default Landing;
