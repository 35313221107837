import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
    const { t } = useTranslation();
    return (
        <div className="my-5">
            <p>
                <b>{t("Introduction:")}</b>{" "}
                {t(
                    "At Poslić, your privacy is our priority. We are aware of the importance of protecting personal data and are committed to maintaining the trust of our users."
                )}
            </p>
            <ol className="lh-lg">
                <li className="fw-bold">
                    <h2 className="fw-bold fs-6">
                        {t("Collection and Use of Personal Data:")}
                    </h2>
                    <ul>
                        <li>
                            <b>{t("Data Collection:")}</b>{" "}
                            {t(
                                "We collect data that are necessary to provide our services, including but not limited to, name, surname, contact information, and payment details."
                            )}
                        </li>
                        <li>
                            <b>{t("Purpose of Collection:")}</b>{" "}
                            {t(
                                "Your data are used exclusively for improving our services and for communication related to Poslić."
                            )}
                        </li>
                    </ul>
                </li>
                <li className="fw-bold">
                    <h2 className="fw-bold fs-6">
                        {t("Sharing Information with Third Parties:")}
                    </h2>
                    <ul>
                        <li>
                            <b>{t("Limitation on Sharing:")}</b>{" "}
                            {t(
                                "Your personal data will not be shared with third parties except in accordance with the law or your explicit consent."
                            )}
                        </li>
                        <li>
                            <b>{t("Cooperation with Law Enforcement:")}</b>{" "}
                            {t(
                                "In the case of legal requests, we may be compelled to share certain data with competent authorities."
                            )}
                        </li>
                    </ul>
                </li>
                <li className="fw-bold">
                    <h2 className="fw-bold fs-6">
                        {t("Security and Data Protection:")}
                    </h2>
                    <ul>
                        <li>
                            <b>{t("Security Measures:")}</b>{" "}
                            {t(
                                "We use advanced encryption methods and security protocols to protect your data from unauthorized access or misuse."
                            )}
                        </li>
                        <li>
                            <b>{t("Regular Audits:")}</b>{" "}
                            {t(
                                "We regularly conduct audits and updates of our security systems to ensure the maximum protection of your data."
                            )}
                        </li>
                    </ul>
                </li>
                <li className="fw-bold">
                    <h2 className="fw-bold fs-6">
                        {t("Cookies and Analytical Tools:")}
                    </h2>
                    <ul>
                        <li>
                            <b>{t("Use of Cookies:")}</b>{" "}
                            {t(
                                "We use cookies to enhance user experience and site functionality."
                            )}
                        </li>
                        <li>
                            <b>{t("Google Analytics:")}</b>{" "}
                            {t(
                                "We use Google Analytics to analyze user behavior, with all measures to protect privacy."
                            )}
                        </li>
                    </ul>
                </li>
                <li className="fw-bold">
                    <h2 className="fw-bold fs-6">{t("User Rights:")}</h2>
                    <ul>
                        <li>
                            <b>{t("Access and Correction:")}</b>{" "}
                            {t(
                                "Users have the right to access, correct, or delete their personal data that we store."
                            )}
                        </li>
                        <li>
                            <b>{t("Withdrawal of Consent:")}</b>{" "}
                            {t(
                                "Users can withdraw their consent for data processing at any time."
                            )}
                        </li>
                    </ul>
                </li>
                <li className="fw-bold">
                    <h2 className="fw-bold fs-6">
                        {t("Changes to the Privacy Policy:")}
                    </h2>
                    <ul>
                        <li>
                            <b>{t("Transparency of Changes:")}</b>{" "}
                            {t(
                                "Any changes to our privacy policy will be clearly published on our website, with appropriate notification to users."
                            )}
                        </li>
                    </ul>
                </li>
            </ol>
            <p>
                {t(
                    "At Poslić, we are dedicated to preserving your privacy and the security of your data. If you have any questions or concerns, please feel free to contact us through our customer support system."
                )}
            </p>
        </div>
    );
};

export default PrivacyPolicy;
