import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./CancelModal.css";
import axios from "axios";
import { toast } from "sonner";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../Loader/Loader";
import { useTranslation } from "react-i18next";
import { decode } from "base-64";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const OngoingBookingCancelModal = () => {
    const { t } = useTranslation();
    const { mytaskdetailscreditId } = useParams();
    const decodedId = decode(mytaskdetailscreditId);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleAction = () => {
        setLoading(true);

        axios
            .get(`${apiUrl}/task/decline-booking?bookingId=${decodedId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then((response) => {
                toast.error(t("Booking declined successfully"));
                navigate("/mytask");
            })
            .catch((error) => {
                toast.error(t("Error updating request"), error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <button
                className="border-0 mt-3 bg-body text-danger w-100 fw-medium"
                onClick={handleShow}
            >
                {t("Cancel")}
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                className="cancelModal"
                dialogClassName="my-modal"
            >
                {loading && <Loader />}
                <Modal.Body className="px-5 pt-4">
                    <h1 className="text-center mb-4">{t("Cancel")}</h1>

                    <h5 className="text-center mb-4">
                        {t("Are you sure you want to cancel this service?")}
                    </h5>
                    {/* <p className="text-center mb-0">
                        {t(
                            "As you are canceling this service within 24 hours of its scheduled start time, you will be charged a one-hour cancellation fee."
                        )}
                    </p> */}
                </Modal.Body>
                <Modal.Footer className="border-0 row px-5 pb-4">
                    <div className="col">
                        <button className="coman-btn" onClick={handleClose}>
                            {t("No")}
                        </button>
                    </div>
                    <div className="col">
                        <button
                            className="coman-btn-fill"
                            onClick={handleAction}
                        >
                            {t("Yes")}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default OngoingBookingCancelModal;
