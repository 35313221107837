import { useTranslation } from "react-i18next";
import EditProfile from "../../components/EditProfile/EditProfile";
import Title from "../../components/title/Title";

const EditProfiles = () => {
    const { t } = useTranslation();
    return (
        <div className="edit-profile-wrapper flex-1">
            <div className="container">
                <div className="col-12 col-md-10 offset-0 offset-md-1">
                    <Title title={t("Edit Profile")} location="/home" />
                    <EditProfile />
                </div>
            </div>
        </div>
    );
};

export default EditProfiles;
