import Title from "../../../components/title/Title";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "sonner";
import calendars from "../../../assets/images/Calendar.png";
import location from "../../../assets/images/location.png";
import ruler from "../../../assets/images/ruler.png";
import dummyImage from "../../../assets/images/profile.jpg";
import Loader from "../../../components/Loader/Loader";
import { formatDateTime, updateLocale } from "../../../Utils/Utils";
import { useTranslation } from "react-i18next";
import { decode } from "base-64";
import MyRatingComponent from "../../../components/MyRatingComponent";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const BidDetails = () => {
    const { t } = useTranslation();
    const { bidDetailsId } = useParams();
    const decodedId = decode(bidDetailsId);
    const [bidData, setBidData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (decodedId) {
            axios
                .get(`${apiUrl}/bid/?bidId=${decodedId}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                })
                .then((response) => {
                    setBidData(response.data);
                })
                .catch((error) => {
                    toast.error("Fetching error", error);
                    // console.error("Fetching error:", error);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [decodedId]);
    const selectedLanguage = localStorage.getItem("selectedLanguage") || "sr";
    updateLocale(selectedLanguage);

    return (
        <div className="bid-submission-wrapper flex-1">
            {loading && <Loader />}
            <div className="container">
                <div className="col-sm-10 offset-sm-1">
                    <Title title={t("Bid Details")} location="/my-bids" />
                    <div className="main-grid-2 my-5">
                        <div className="task-card-wrapper h-100">
                            <div className="box p-3 d-flex justify-content-between flex-column h-100">
                                <div>
                                    <div className="d-flex align-items-center gap-2">
                                        <img
                                            src={
                                                bidData.profileImage
                                                    ? bidData.profileImage
                                                    : dummyImage
                                            }
                                            className="profile-img"
                                            alt="person"
                                        />
                                        <div>
                                            <h3 className="mb-0">
                                                {bidData.firstname}&nbsp;
                                                {bidData.lastname}
                                            </h3>
                                            <div className="d-flex gap-1 align-items-center">
                                                <MyRatingComponent
                                                    value={
                                                        bidData.averageRating
                                                    }
                                                />
                                                <span>
                                                    {bidData.averageRating || 0}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row mb-2">
                                        <div className="col-6">
                                            <h5 className="mb-1">
                                                {t("Required Service")}
                                            </h5>
                                            <h2 className="mb-0">
                                                {bidData.service}
                                            </h2>
                                        </div>
                                        <div className="col-6">
                                            <h5 className="mb-1">
                                                {t("Budget")}
                                            </h5>
                                            <h2 className="mb-0">
                                                {bidData.budget}{" "}
                                                {bidData.selectPrice === 1
                                                    ? t("din/hr")
                                                    : "din"}
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="mb-2">
                                        <h5 className="mb-1">
                                            {t("Description")}
                                        </h5>
                                        <p className="mb-0">
                                            {bidData.description}
                                        </p>
                                    </div>
                                </div>
                                <hr />
                                <div className="d-flex flex-column gap-3">
                                    <div className="d-flex gap-2">
                                        <img
                                            className="icons"
                                            src={calendars}
                                            alt="calenders"
                                        />
                                        <p className="mb-0 title">
                                            {formatDateTime(
                                                bidData.selectDate,
                                                bidData.selectTime
                                            )}
                                        </p>
                                    </div>
                                    <div className="d-flex gap-2">
                                        <img
                                            className="icons"
                                            src={location}
                                            alt="location"
                                        />
                                        <p className="mb-0 title">
                                            {bidData.address}
                                        </p>
                                    </div>
                                    <div className="d-flex gap-2">
                                        <img
                                            className="icons"
                                            src={ruler}
                                            alt="ruler"
                                        />
                                        <p className="mb-0 title">
                                            {bidData.howBigIsYourTask}{" "}
                                            {t("Hours")}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="bid-submission-from">
                            <div className="box p-4">
                                {bidData.selectPrice === 1 && (
                                    <div className="mb-4">
                                        <label
                                            htmlFor="task"
                                            className="form-label"
                                        >
                                            {t("Hourly Rate")}
                                        </label>
                                        <input
                                            className="form-control"
                                            placeholder={t("Your Hourly Rate")}
                                            value={bidData.howBigIsYourTask}
                                            readOnly
                                        />
                                    </div>
                                )}
                                <div className="mb-4">
                                    <label
                                        htmlFor="task"
                                        className="form-label"
                                    >
                                        {t(
                                            "Your Proposed Prices for Completing Task"
                                        )}{" "}
                                        {bidData.selectPrice === 1 ? (
                                            <span
                                                className="fw-bold"
                                                style={{ color: "#0D7A5F" }}
                                            >
                                                ({t("Hourly")})
                                            </span>
                                        ) : (
                                            <span
                                                className="fw-bold"
                                                style={{ color: "#0D7A5F" }}
                                            >
                                                ({t("Fixed")})
                                            </span>
                                        )}
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Your Proposed Price"
                                        value={
                                            `${bidData.praposedPrice} din` || ""
                                        }
                                        style={{
                                            color: "#0D7A5F",
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            opacity: 1,
                                        }}
                                        readOnly
                                    />
                                </div>
                                <div>
                                    <label
                                        htmlFor="task"
                                        className="form-label"
                                    >
                                        {t("Description")}
                                    </label>
                                    <textarea
                                        className="form-control"
                                        rows={4}
                                        placeholder="Write Here"
                                        value={bidData.takerDescription || ""}
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BidDetails;
