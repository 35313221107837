import React from "react";
import BidsPaymentDetails from "../../../components/PaymentDetails/BidsPaymentDetails";
import BidsPaymentCard from "../../../components/Cards/PlumbingDetailsCard/BidsPaymentCard";
import { useTranslation } from "react-i18next";

const BidsPayment = () => {
    const { t } = useTranslation();
    return (
        <div className="confirm-detail-container mt-5 flex-1">
            <div className="container">
                <p className="heading text-center my-4 w-50 m-auto">
                    {t(
                        "You’re almost done! We just need a few more details to connect you with your tasker."
                    )}
                </p>
                <div className="main-grid-2">
                    <BidsPaymentCard />
                    <BidsPaymentDetails />
                </div>
            </div>
        </div>
    );
};

export default BidsPayment;
