import { Link } from "react-router-dom";
import footerBg from "../../assets/images/footer-bg.png";
import logo from "../../assets/images/Original.png";
import "./Footer.css";
import { useTranslation } from "react-i18next";

const UserFooter = () => {
    const { t } = useTranslation();
    return (
        <footer className="position-relative">
            <div className="container pt-4">
                <div className="d-flex align-items-start align-items-lg-center justify-content-between flex-lg-row flex-column mb-5">
                    <div className="mb-lg-0 mb-2">
                        <Link to="/home">
                            <img
                                src={logo}
                                alt="logo"
                                className="footer-logo"
                            />
                        </Link>
                    </div>
                    <div
                        className="d-flex gap-0 gap-lg-5 justify-content-center flex-column flex-lg-row lh-lg mb-lg-0 mb-2"
                        style={{ flex: "1" }}
                    >
                        <Link to="/languages" className="footer-link p-0">
                            {t("Languages")}
                        </Link>
                        <Link to="/faqs" className="footer-link p-0">
                            {t("FAQs")}
                        </Link>
                        <Link
                            to="/customer-support"
                            className="footer-link p-0"
                        >
                            {t("Customer Support")}
                        </Link>
                    </div>
                    <div className="text-lg-end text-start">
                        <h2 className="mb-0">
                            {t("Developed by")} <span>{t("Poslic.rs.")}</span>
                        </h2>
                    </div>
                </div>{" "}
                <hr className="mb-0" />
                <div className="py-3">
                    <p className="mb-0 text-center footer-copyright">
                        {t("All Rights Reserved To The Poslic © 2023")}
                    </p>
                </div>
            </div>
            <img
                src={footerBg}
                className="position-absolute top-0 start-0 w-100 h-100 object-fit-cover z-n1"
                alt="footerImage"
            />
        </footer>
    );
};

export default UserFooter;
