import { useState } from "react";
import block from "../../assets/images/Block.png";
import { toast } from "sonner";
import axios from "axios";
import Loader from "../Loader/Loader";
import { useTranslation } from "react-i18next";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const Blocked = () => {
    const { t } = useTranslation();
    const [visible, setVisible] = useState(true);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleHide = () => {
        setVisible(false);
    };

    const handleShow = () => {
        setVisible(true);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let response;
        try {
            response = await axios.post(
                `${apiUrl}/user/customer-support`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                }
            );
            if (response.status === 200) {
                formData.name = "";
                formData.email = "";
                formData.message = "";
                toast.success(t("Your request for help is submitted"));
                setVisible(true);
            }
        } catch (error) {
            toast.error("Failed: " + error.response.data.message);
        } finally {
            setLoading(false);
        }
    };
    return (
        <div className="container">
            <div className="row">
                <div className="col-10 col-lg-6 offset-lg-3 offset-1">
                    {visible ? (
                        <div className="d-flex justify-content-center align-items-center flex-column gap-5">
                            <h1 className="mt-5 mb-0">
                                {t("Your Account is Blocked")}
                            </h1>
                            <img
                                src={block}
                                alt="images"
                                className="img-fluid"
                            />
                            <button
                                className="coman-btn-fill"
                                onClick={handleHide}
                            >
                                {t("Customer Support")}
                            </button>
                        </div>
                    ) : (
                        <div className="box p-4 ps-md-5 my-5">
                            {loading && <Loader />}
                            <form
                                action=""
                                className="px-0 px-lg-5"
                                onSubmit={handleSubmit}
                            >
                                <div className="mb-3">
                                    <label
                                        htmlFor="name"
                                        className="form-label"
                                    >
                                        {t("Name")}
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={formData.name}
                                        name="name"
                                    />
                                </div>
                                <div className="mb-3">
                                    <label
                                        htmlFor="email"
                                        className="form-label"
                                    >
                                        {t("Email")}
                                    </label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={formData.email}
                                        name="email"
                                    />
                                </div>
                                <div className="mb-3">
                                    <label
                                        htmlFor="Message"
                                        className="form-label"
                                    >
                                        {t("Message")}
                                    </label>
                                    <textarea
                                        className="form-control"
                                        rows="4"
                                        onChange={handleChange}
                                        value={formData.message}
                                        name="message"
                                    />
                                </div>
                                <div className="mt-5 row">
                                    <div className="col-6">
                                        <div
                                            className="coman-btn"
                                            onClick={handleShow}
                                        >
                                            {t("Back")}
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <button
                                            type="submit"
                                            className="coman-btn-fill"
                                        >
                                            {t("Send")}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Blocked;
