import { useState } from "react";
import Loader from "../../../components/Loader/Loader";
// import CancelModal from "../../../components/Modal/Cancel/CancelModal";
import Title from "../../../components/title/Title";
import dummyImage from "../../../assets/images/profile.jpg";
import location from "../../../assets/images/location.png";
import calendars from "../../../assets/images/Calendar.png";
import ruler from "../../../assets/images/ruler.png";
import chat from "../../../assets/images/Chat.png";
import { useEffect } from "react";
import axios from "axios";
import { toast } from "sonner";
import { Link, useParams } from "react-router-dom";
import BookingPaymentDetails from "../../../components/PaymentDetails/BookingPaymentDetails";
import { formatDateTime, updateLocale } from "../../../Utils/Utils";
import { useTranslation } from "react-i18next";
import { decode } from "base-64";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const TaskDetails = () => {
    const { t } = useTranslation();
    const { bookingId } = useParams();
    const decodedId = decode(bookingId);
    const [loading, setLoading] = useState(false);
    const [taskDetails, setTaskDetails] = useState([]);

    useEffect(() => {
        setLoading(true);
        axios
            .get(`${apiUrl}/tasker/booking-detail?bookingId=${decodedId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then((response) => {
                setTaskDetails(response.data.data);
            })
            .catch((error) => {
                toast.error("Fetching error", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [decodedId]);

    const selectedLanguage = localStorage.getItem("selectedLanguage") || "sr";
    updateLocale(selectedLanguage);

    return (
        <div className="flex-1">
            {loading && <Loader />}
            <div className="container">
                <div className="col-sm-10 offset-sm-1">
                    <Title
                        title={t("Task Details")}
                        location="/provider-task"
                    />
                </div>
                <div className="main-grid-2">
                    <div>
                        {Object.keys(taskDetails).length === 0 && !loading ? (
                            <p>{t("You’ve not any on Going booking yet")}</p>
                        ) : (
                            <div className="plumbing-detail-card">
                                <div className="card-header p-4">
                                    <h2>{taskDetails.service}</h2>
                                    <div className="d-flex align-items-center gap-3">
                                        <img
                                            src={
                                                taskDetails.profileImage
                                                    ? taskDetails.profileImage
                                                    : dummyImage
                                            }
                                            className="person"
                                            style={{ flex: "none" }}
                                            alt="person"
                                        />
                                        <div>
                                            <h3 className="mb-0">
                                                {taskDetails.firstname}&nbsp;
                                                {taskDetails.lastname}
                                            </h3>
                                            <div className="mb-2 d-flex gap-1 align-items-center">
                                                <img
                                                    src={location}
                                                    className="icons"
                                                    alt="location"
                                                />
                                                <p className="mb-0">
                                                    {taskDetails.address}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body p-4">
                                    <div className="d-flex gap-2">
                                        <img
                                            className="icons"
                                            src={calendars}
                                            alt="calenders"
                                        />
                                        <p>
                                            {formatDateTime(
                                                taskDetails.date,
                                                taskDetails.time
                                            )}
                                        </p>
                                    </div>
                                    <div className="d-flex gap-2">
                                        <img
                                            className="icons"
                                            src={ruler}
                                            alt="ruler"
                                        />
                                        <p>
                                            {taskDetails.howBigIsYourTask}{" "}
                                            {t("Hours")}
                                        </p>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h4 className="mb-0">
                                            {t("Total Paid Amount")}:{" "}
                                            <span>{taskDetails.total} din</span>
                                        </h4>
                                    </div>
                                </div>
                                <div className="p-4 pt-0">
                                    <Link
                                        to="/tasker-message"
                                        className="coman-btn-fill"
                                    >
                                        <img
                                            src={chat}
                                            className="chat"
                                            alt="chat"
                                        />
                                        &nbsp; {t("Message")}
                                    </Link>
                                </div>
                            </div>
                        )}
                        {/* <CancelModal taskDetails={true} /> */}
                    </div>
                    <BookingPaymentDetails taskDetails={taskDetails} />
                </div>
            </div>
        </div>
    );
};

export default TaskDetails;
