import moment from "moment";

export const formatDateTime = (selectDate, selectTime, t) => {
    if (!selectDate || !selectTime) {
        return "Date and time not available";
    }

    const date = moment(selectDate);
    const time = moment(selectTime);

    const formattedDateTime =
        date.format("D MMM, ddd") + " At " + time.format("hh:mm A");
    return formattedDateTime;
};

export const updateLocale = (selectedLanguage) => {
    if (selectedLanguage === "sr") {
        moment.updateLocale("sr", {
            months: [
                "Januar",
                "Februar",
                "Mart",
                "April",
                "Maj",
                "Jun",
                "Jul",
                "Avgust",
                "Septembar",
                "Oktobar",
                "Novembar",
                "Decembar",
            ],
            monthsShort: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "Maj",
                "Jun",
                "Jul",
                "Avg",
                "Sep",
                "Okt",
                "Nov",
                "Dec",
            ],
            weekdays: [
                "Nedelja",
                "Ponedeljak",
                "Utorak",
                "Sreda",
                "Četvrtak",
                "Petak",
                "Subota",
            ],
            weekdaysShort: ["Ned", "Pon", "Uto", "Sre", "Čet", "Pet", "Sub"],
            weekdaysMin: ["Ne", "Po", "Ut", "Sr", "Če", "Pe", "Su"],
        });
    } else {
        moment.updateLocale("en");
    }
};
