import "./BidsCard.css";
import BidDetailsModal from "../../Modal/TaskAndPriceDetailModal/BidDetailsModal";
import dummyImage from "../../../assets/images/profile.jpg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { encode } from "base-64";
import MyRatingComponent from "../../../components/MyRatingComponent";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const BidsCard = ({ bidDetailsData }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleAccept = (bidId) => {
        const requestData = {
            request: "0",
            step: 1,
            bidId: bidId,
        };
        axios
            .post(`${apiUrl}/booking/task-booking`, requestData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then((response) => {
                if (response.data.data._id) {
                    const _iD = response.data.data._id;
                    const newUrl = `/bidspayment/${encode(bidId)}/${encode(
                        _iD
                    )}`;
                    navigate(newUrl);
                } else {
                    // console.error("Response does not contain _id.");
                }
            })
            .catch((error) => {
                // console.error("API Error:", error);
            });
    };

    const handleDecline = (bidId) => {
        const requestData = {
            request: "2",
            step: 1,
            bidId: bidId,
        };

        axios
            .post(`${apiUrl}/booking/task-booking`, requestData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then((response) => {
                navigate("/home");
            })
            .catch((error) => {
                // console.error("API Error:", error);
            });
    };
    return (
        <div className="bids-card">
            {bidDetailsData.length > 0 ? (
                bidDetailsData.map((item) => (
                    <div className="cards p-3 mb-4" key={item._id}>
                        <div className="d-flex justify-content-between flex-column flex-lg-row">
                            <div className="d-flex align-items-center gap-3">
                                <div className="text-center">
                                    <img
                                        src={
                                            item.profileImage
                                                ? item.profileImage
                                                : dummyImage
                                        }
                                        className="profile-img mb-3"
                                        alt="profile"
                                    />
                                </div>
                                <div className="d-flex flex-column mb-3">
                                    <h1 className="mb-0">
                                        {item.firstname}&nbsp;{item.lastname}
                                    </h1>

                                    <div className="d-flex gap-1 align-items-center">
                                        <MyRatingComponent
                                            value={item.averageRating}
                                        />
                                        <span className="mt-1">
                                            {item.averageRating || 0}
                                        </span>
                                    </div>
                                    <BidDetailsModal
                                        bidCardDetailsId={item._id}
                                        onAccept={handleAccept}
                                        onDecline={handleDecline}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="d-flex gap-2 mb-2">
                                    <button
                                        className="coman-btn p-3"
                                        style={{ height: "30px" }}
                                        onClick={() => handleDecline(item._id)}
                                    >
                                        {t("Decline")}
                                    </button>
                                    <button
                                        className="coman-btn-fill p-3"
                                        style={{ height: "30px" }}
                                        onClick={() => handleAccept(item._id)}
                                    >
                                        {t("Accept")}
                                    </button>
                                </div>
                                <div className="d-flex align-items-center">
                                    <h5>{t("Proposed Price")} :</h5>
                                    <h3>
                                        &nbsp;{item.praposedPrice}{" "}
                                        {item.selectPrice === 0
                                            ? "din"
                                            : t("din/hr")}
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="background p-3">
                            <h4>{t("Description")}</h4>
                            <p className="mb-0">{item.description}</p>
                        </div>
                    </div>
                ))
            ) : (
                <p className="task-posting-tag">{t("No More Bids")}</p>
            )}
        </div>
    );
};

export default BidsCard;
