import React from "react";
import { Outlet } from "react-router-dom";
import NavbarMain from "../components/Navbar/NavbarMain";
import UserFooter from "../components/Footer/UserFooter";

const UserLayout = () => {
    return (
        <>
            <NavbarMain />
            <Outlet />
            <UserFooter />
        </>
    );
};

export default UserLayout;
