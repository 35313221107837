import Index from "../../components/BankDetails/Index";

const BankDetails = () => {
    return (
        <div className="flex-1">
            <Index />
        </div>
    );
};

export default BankDetails;
