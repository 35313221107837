import deleteIcon from "../../assets/images/Delete.png";
import editIcon from "../../assets/images/edit-3.png";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { toast } from "sonner";
import Loader from "../Loader/Loader";
import AddMoreServicesModal from "../Modal/AddMoreServicesModal/AddMoreServicesModal";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../context/LanguageContext";
import Select from "react-select";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const Index = () => {
    const { t } = useTranslation();
    const { selectedLanguage } = useLanguage();
    const [serviceData, setServiceData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [serviceOptions, setServiceOptions] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        axios
            .get(`${apiUrl}/tasker/services`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then((response) => {
                setServiceData(response.data.data);
            })
            .catch((error) => {
                toast.error("fetching error", error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleServiceAdded = () => {
        fetchData();
    };

    useEffect(() => {
        axios
            .get(`${apiUrl}/data/filter`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then((response) => {
                setServiceOptions(response.data.data);
            })
            .catch((error) => {
                toast.error("fetching error", error);
            });
    }, []);

    const handleEditClick = () => {
        setIsEditing(!isEditing);
    };

    const handleInputChange = (event, index) => {
        const { name, value } = event.target;
        const updatedServiceData = [...serviceData];
        updatedServiceData[index] = {
            ...updatedServiceData[index],
            [name]: value,
        };
        setServiceData(updatedServiceData);

        const updatedServiceOptions = [...serviceOptions];
        const selectedOption = updatedServiceOptions.find(
            (option) => option._id === value
        );
        if (selectedOption) {
            updatedServiceData[index] = {
                ...updatedServiceData[index],
                service: value,
            };
            setServiceData(updatedServiceData);
        }
    };

    const handleSaveClick = async (editedServiceData) => {
        setLoading(true);
        try {
            const response = await axios.put(
                `${apiUrl}/tasker/?id=${editedServiceData._id}`,
                editedServiceData,
                {
                    headers: {
                        accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                }
            );

            if (response.status === 200) {
                fetchData();
                toast.success(t("Service data updated successfully"));
            }
        } catch (error) {
            toast.error(error.response.data.message);
            fetchData();
        } finally {
            setLoading(false);
            setIsEditing(false);
        }
    };

    const handleDeleteService = async (serviceId) => {
        setLoading(true);
        try {
            const response = await axios.delete(
                `${apiUrl}/tasker/?id=${serviceId}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                }
            );
            if (response.status === 200) {
                toast.success(t("Service deleted successfully"));
                fetchData();
            }
        } catch (error) {
            toast.error(error.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="profile-creation-wrapper pb-5 flex-1">
            {loading && <Loader />}
            <div className="container">
                <div className="col-sm-10 offset-sm-1">
                    <h1 className="text-center my-5">{t("Edit Services")}</h1>
                    {serviceData.map((item, index) => (
                        <div
                            className="box p-5 mb-5 position-relative"
                            key={item._id}
                        >
                            <img
                                src={editIcon}
                                alt="edit"
                                className="edit-icon"
                                onClick={handleEditClick}
                            />
                            {serviceData.length > 1 ? (
                                <img
                                    src={deleteIcon}
                                    alt="delete"
                                    onClick={() =>
                                        handleDeleteService(item._id)
                                    }
                                    className="delete-icon"
                                />
                            ) : (
                                <></>
                            )}

                            <form action="">
                                <div className="mb-3">
                                    <label
                                        htmlFor={`service-${index}`}
                                        className="form-label"
                                    >
                                        {t("Select Your Service")}
                                    </label>
                                    <Select
                                        name="serviceId"
                                        isDisabled={!isEditing}
                                        value={{
                                            label: (() => {
                                                if (isEditing) {
                                                    if (
                                                        selectedLanguage ===
                                                        "en"
                                                    ) {
                                                        return (
                                                            serviceOptions.find(
                                                                (option) =>
                                                                    option._id ===
                                                                    item.service
                                                            )?.task ||
                                                            item.service
                                                        );
                                                    } else {
                                                        return (
                                                            serviceOptions.find(
                                                                (option) =>
                                                                    option._id ===
                                                                    item.service
                                                            )?.serbianTask ||
                                                            item.service
                                                        );
                                                    }
                                                } else {
                                                    return (
                                                        serviceOptions.find(
                                                            (option) =>
                                                                option._id ===
                                                                item.service
                                                        )?.serbianTask ||
                                                        item.service
                                                    );
                                                }
                                            })(),
                                            value: item.service,
                                        }}
                                        onChange={(selectedOption) =>
                                            handleInputChange(
                                                {
                                                    target: {
                                                        name: "serviceId",
                                                        value: selectedOption.value,
                                                    },
                                                },
                                                index
                                            )
                                        }
                                        options={serviceOptions.map(
                                            (option) => ({
                                                label:
                                                    selectedLanguage === "en"
                                                        ? option.task
                                                        : option.serbianTask,
                                                value: option._id,
                                            })
                                        )}
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                borderRadius: "10px",
                                                border: "2px solid rgba(214, 214, 214, 0.90)",
                                                minHeight: "50px",
                                                fontSize: "18px",
                                                fontWeight: "500",
                                                opacity: "0.7",
                                                color: "#000",
                                            }),
                                        }}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label
                                        htmlFor="AboutMe"
                                        className="form-label"
                                    >
                                        {t("About Me")}
                                    </label>
                                    <textarea
                                        rows="5"
                                        className="form-control"
                                        name="aboutMe"
                                        value={item.aboutMe}
                                        readOnly={!isEditing}
                                        onChange={(e) =>
                                            handleInputChange(e, index)
                                        }
                                    ></textarea>
                                </div>
                                <div className="mb-3">
                                    <label
                                        htmlFor="SkillsExperience"
                                        className="form-label"
                                    >
                                        {t("Skills & Experience")}
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="skillsAndExperience"
                                        value={item.skillsAndExperience}
                                        readOnly={!isEditing}
                                        onChange={(e) =>
                                            handleInputChange(e, index)
                                        }
                                    />
                                </div>
                                <div className="mb-3">
                                    <label
                                        htmlFor="task"
                                        className="form-label"
                                    >
                                        {t(
                                            "Required Minimum Hours to Complete Task"
                                        )}
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="minimumhours"
                                        value={item.minimumhours}
                                        readOnly={!isEditing}
                                        onChange={(e) =>
                                            handleInputChange(e, index)
                                        }
                                    />
                                </div>
                                <div>
                                    <label
                                        htmlFor="rate"
                                        className="form-label"
                                    >
                                        {t("Rate/hr")}
                                    </label>
                                    <div className="position-relative">
                                        <input
                                            type="number"
                                            className="form-control pe-5"
                                            name="rate"
                                            value={item.rate}
                                            readOnly={!isEditing}
                                            onChange={(e) =>
                                                handleInputChange(e, index)
                                            }
                                        />
                                        <span className="position-absolute">
                                            {t("din")}
                                        </span>
                                    </div>
                                </div>
                                {isEditing && (
                                    <button
                                        type="button"
                                        className="coman-btn-fill mt-5"
                                        onClick={() =>
                                            handleSaveClick(item, index)
                                        }
                                    >
                                        {t("Save")}
                                    </button>
                                )}
                            </form>
                        </div>
                    ))}
                    <div className="text-center">
                        <AddMoreServicesModal
                            onServiceAdded={handleServiceAdded}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
