import React from "react";

const Sitemap = () => {
    const urls = [
        { loc: "https://poslic.rs/", priority: "1.00" },
        { loc: "https://poslic.rs/services", priority: "0.80" },
        { loc: "https://poslic.rs/user-login", priority: "0.80" },
        { loc: "https://poslic.rs/signup", priority: "0.80" },
        { loc: "https://poslic.rs/login", priority: "0.80" },
        { loc: "https://poslic.rs/forgot", priority: "0.80" },
        { loc: "https://poslic.rs/tasker", priority: "0.80" },
        { loc: "https://poslic.rs/tasker-signup", priority: "0.80" },
        { loc: "https://poslic.rs/tasker-login", priority: "0.80" },
        { loc: "https://poslic.rs/tasker-forgot", priority: "0.80" },
        { loc: "https://poslic.rs/privacy", priority: "0.80" },
        { loc: "https://poslic.rs/terms", priority: "0.80" },
    ];

    const currentDate = new Date().toISOString();

    const xmlContent = `<?xml version="1.0" encoding="UTF-8"?>
    <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9"
            xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
            xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
        ${urls
            .map(
                (url) => `
          <url>
              <loc>${url.loc}</loc>
              <lastmod>${currentDate}</lastmod>
              <priority>${url.priority}</priority>
          </url>
      `
            )
            .join("")}
    </urlset>`;

    return (
        <div className="bg-dark text-white">
            <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                {xmlContent}
            </pre>
        </div>
    );
};

export default Sitemap;
