import "./MyBids.css";
import location from "../../../assets/images/location.png";
import calender from "../../../assets/images/Calendar.png";
import Description from "../../../components/Description/Description";
// import { myBids } from "../../../Data";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "sonner";
import Loader from "../../../components/Loader/Loader";
import dummyImage from "../../../assets/images/profile.jpg";
import { formatDateTime, updateLocale } from "../../../Utils/Utils";
import { useTranslation } from "react-i18next";
import { encode } from "base-64";
import MyRatingComponent from "../../../components/MyRatingComponent";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const MyBids = () => {
    const { t } = useTranslation();
    const [bidsDetails, setBidsDetails] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios
            .get(`${apiUrl}/bid/bids`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then((response) => {
                setBidsDetails(response.data);
            })
            .catch((error) => {
                toast.error("fetching error", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const selectedLanguage = localStorage.getItem("selectedLanguage") || "sr";
    updateLocale(selectedLanguage);

    return (
        <div className="myBids-wrapper my-5 flex-1">
            {loading && <Loader />}
            <div className="container">
                <h1 className="text-center">{t("My Bids")}</h1>
                <div className="grid-2 my-5">
                    {bidsDetails && bidsDetails.length > 0 ? (
                        bidsDetails.map((item) => (
                            <div className="box p-3" key={item._id}>
                                <div className="d-flex align-items-sm-center align-items-start justify-content-between mb-3 flex-column flex-sm-row">
                                    <div className="d-flex align-items-center gap-2">
                                        <img
                                            src={
                                                item.profileImage
                                                    ? item.profileImage
                                                    : dummyImage
                                            }
                                            alt="person"
                                            className="profile-img"
                                        />
                                        <div>
                                            <h2 className="mb-0">
                                                {item.firstname}&nbsp;
                                                {item.lastname}
                                            </h2>
                                            <div className="d-flex gap-1 align-items-center">
                                                <MyRatingComponent
                                                    value={item.averageRating}
                                                />
                                                <span>
                                                    {item.averageRating || 0}
                                                </span>
                                            </div>
                                            <div className="d-flex align-items-center gap-2">
                                                <img
                                                    className="icon"
                                                    src={location}
                                                    alt="location"
                                                />
                                                <p className="mb-0 opacity-50">
                                                    {item.address}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column align-items-sm-end align-items-start mt-3 mt-sm-0 gap-2">
                                        <Link
                                            className="coman-btn px-2"
                                            to={`/bid-details/${encode(
                                                item._id
                                            )}`}
                                        >
                                            {t("View Details")}
                                        </Link>
                                        <div className="d-flex align-items-center gap-2">
                                            <img
                                                className="icon"
                                                src={calender}
                                                alt="calender"
                                            />
                                            <h4
                                                className="mb-0"
                                                style={{ width: "max-content" }}
                                            >
                                                {formatDateTime(
                                                    item.selectDate,
                                                    item.selectTime
                                                )}
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="background p-3">
                                    <div className="d-flex align-items-center mb-2">
                                        <h3 className="mb-0">
                                            {t("Proposed Price")} :
                                        </h3>
                                        &nbsp;
                                        <span className="price">
                                            {item.praposedPrice} {t("din")}
                                        </span>
                                    </div>
                                    {item.takerDescription && (
                                        <Description
                                            description={item.takerDescription}
                                        />
                                    )}
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>{t("No bids to display")}</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MyBids;
