import "./Home.css";
import PostTaskCard from "../../../components/Cards/PostTaskCard/PostTaskCard";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { toast } from "sonner";
import SelectServiceButton from "../../../components/SelectServiceButton/SelectServiceButton";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { encode } from "base-64";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../../context/LanguageContext";
import search from "../../../assets/images/Search.png";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const Home = () => {
    const { t } = useTranslation();
    const { selectedLanguage } = useLanguage();
    const [serviceButton, setServiceButton] = useState([]);
    const [showAllButtons, setShowAllButtons] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [selectedService, setSelectedService] = useState(null);
    const [userBookingId, setUserBookingId] = useState();
    const navigate = useNavigate();
    const maxButtonsToShow = 7;

    useEffect(() => {
        axios
            .get(`${apiUrl}/data/top-category`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then((response) => {
                setServiceButton(response.data.data);
            })
            .catch((error) => {
                toast.error("fetching error", error);
            });
    }, []);

    const toggleShowAllButtons = () => {
        setShowAllButtons(!showAllButtons);
    };

    useEffect(() => {
        axios
            .get(`${apiUrl}/data/`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then((response) => {
                setSearchResults(response.data.data);
            })
            .catch((error) => {
                toast.error("fetching error", error);
            });
    }, []);

    const handleBooking = (id) => {
        setSelectedService(id);
        const payload = {
            step: 1,
            serviceId: id,
        };
        axios
            .post(`${apiUrl}/booking/search`, payload, {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                setUserBookingId(response.data.data._id);
            })
            .catch((error) => {
                console.error(error.response.data.message);
            });
    };

    useEffect(() => {
        if (userBookingId && selectedService) {
            const serviceLink = `/plumbing/${encode(selectedService)}/${encode(
                userBookingId
            )}`;
            navigate(serviceLink);
        }
    }, [selectedService, userBookingId, navigate]);

    return (
        <main className="flex-1">
            <section className="booktask-section">
                <div className="container">
                    <div className="box my-5">
                        <div className="inner-box m-auto py-4 px-3 position-relative">
                            <h1 className="text-center mb-4">
                                {t("bookTaskTitle")}
                            </h1>
                            <div className="position-relative">
                                <img
                                    src={search}
                                    alt="search"
                                    className="icon"
                                />
                                <Select
                                    options={searchResults.map((item) => ({
                                        value: item._id,
                                        label:
                                            selectedLanguage === "en"
                                                ? item.task
                                                : item.serbianTask,
                                    }))}
                                    onChange={(selectedOption) => {
                                        handleBooking(selectedOption.value);
                                    }}
                                    placeholder={t("searchPlaceholder")}
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            borderRadius: "10px",
                                            border: "2px solid rgba(214, 214, 214, 0.90)",
                                            backgroundColor: "#F6F6F6",
                                            minHeight: "50px",
                                            fontSize: "18px",
                                            fontWeight: "500",
                                            opacity: "0.7",
                                            color: "#000",
                                            margin: "15px 0",
                                            paddingLeft: "35px",
                                        }),
                                    }}
                                />
                            </div>
                            <div className="button-wrapper d-flex align-items-center flex-wrap gap-3">
                                {serviceButton
                                    .slice(
                                        0,
                                        showAllButtons
                                            ? undefined
                                            : maxButtonsToShow
                                    )
                                    .map((item) => (
                                        <SelectServiceButton
                                            SelectServiceLink={item}
                                            key={item._id}
                                        />
                                    ))}
                                {serviceButton.length > maxButtonsToShow && (
                                    <button
                                        className="show-more-button"
                                        onClick={toggleShowAllButtons}
                                    >
                                        {showAllButtons ? (
                                            <>{t("showLessButton")}</>
                                        ) : (
                                            <>{t("showMoreButton")}</>
                                        )}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="post-task-section">
                <div className="container">
                    <h1 className="text-center mb-4">
                        {t("postedTasksTitle")}
                    </h1>
                    <PostTaskCard />
                </div>
            </section>
        </main>
    );
};

export default Home;
