import { Link, useNavigate } from "react-router-dom";
import VerificationInput from "react-verification-input";
import background from "../../../../assets/images/bg-1.png";
import "./VerificationCode.css";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import axios from "axios";
import Loader from "../../../../components/Loader/Loader";
import edit from "../../../../assets/images/edit.png";
import { useTranslation } from "react-i18next";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const VerificationCode = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [remainingSeconds, setRemainingSeconds] = useState(
        localStorage.getItem("remainingSeconds") || 59
    );
    const [otp, setOTP] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const storedEmail = localStorage.getItem("userEmail");
        if (storedEmail) {
            setEmail(storedEmail);
        }
    }, []);

    useEffect(() => {
        let timer;

        if (remainingSeconds > 0 && !loading) {
            timer = setInterval(() => {
                setRemainingSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
        }

        if (remainingSeconds <= 0) {
            clearInterval(timer);
        }

        return () => {
            clearInterval(timer);
        };
    }, [remainingSeconds, loading]);

    useEffect(() => {
        localStorage.setItem("remainingSeconds", remainingSeconds);
    }, [remainingSeconds]);

    const handleChange = (e) => {
        const inputValue = e.target ? e.target.value : e;
        setOTP(inputValue);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!otp.trim()) {
            toast.error(t("Please enter a valid OTP"));
            return;
        }
        setLoading(true);
        const headers = {
            accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        };
        try {
            const response = await axios.post(
                `${apiUrl}/auth/verify-otp`,
                { otp, email },
                { headers }
            );
            if (response.status === 200) {
                toast.success(t("OTP verified successfully"));
                localStorage.setItem("isVerify", true);
                navigate("/home");
            } else {
                toast.error(t("Failed to verify OTP: AccessToken not found"));
            }
        } catch (error) {
            setOTP("");
            toast.error(error.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    const handleResendOTP = async () => {
        setLoading(true);
        setRemainingSeconds(59);
        const headers = {
            accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        };
        try {
            const response = await axios.post(
                `${apiUrl}/auth/resend-otp`,
                { email },
                { headers }
            );

            toast.success(response.data.message);
        } catch (error) {
            toast.error(error.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="position-relative choose-section py-5">
            {loading && <Loader />}
            <div className="container d-flex justify-content-center align-items-center">
                <div className="box p-5">
                    <div className="box-header mb-4">
                        <h1 className="text-center">
                            {t("Verification code")}
                        </h1>
                        <p className="mb-2 text-center">
                            {t(
                                "Please enter the OTP sent to your email address"
                            )}
                        </p>
                        <span className="links d-flex align-items-center justify-content-center gap-2">
                            {email}
                            <Link to="/edit-email">
                                <img
                                    src={edit}
                                    className="edit-icon"
                                    alt="edit"
                                />
                            </Link>
                        </span>
                    </div>
                    <form action="" onSubmit={handleSubmit}>
                        <input
                            type="email"
                            name="email"
                            placeholder={t("Email address")}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            style={{ display: "none" }}
                        />
                        <div className="d-flex justify-content-between mb-3">
                            <label
                                htmlFor="enterOTP"
                                style={{ fontWeight: "500" }}
                            >
                                {t("Enter OTP")}
                            </label>
                            <span className="links">
                                {remainingSeconds > 0
                                    ? `00:${
                                          remainingSeconds < 10
                                              ? `0${remainingSeconds}`
                                              : remainingSeconds
                                      }`
                                    : "00:00"}
                            </span>
                        </div>
                        <VerificationInput
                            classNames={{
                                container: "vi_container",
                                character: "character",
                                characterInactive: "character--inactive",
                                characterSelected: "character--selected",
                            }}
                            validChars="0-9"
                            inputProps={{ inputMode: "numeric" }}
                            length={4}
                            autoFocus={true}
                            placeholder=""
                            value={otp}
                            onChange={handleChange}
                        />
                        <div className="text-end">
                            <p
                                className={`mt-3 ${
                                    remainingSeconds === 0
                                        ? "opacity-100"
                                        : "opacity-25"
                                }`}
                                style={{ cursor: "pointer", color: "#000" }}
                                onClick={
                                    remainingSeconds > 0
                                        ? null
                                        : handleResendOTP
                                }
                                disabled={remainingSeconds <= 0}
                            >
                                {t("Resend")}
                            </p>
                        </div>
                        <button type="submit" className="coman-btn-fill mt-5">
                            {t("Verify")}
                        </button>
                    </form>
                </div>
            </div>
            <img
                src={background}
                className="w-100 position-absolute top-0 start-0 object-fit-cover z-n1 bg-image h-100"
                alt="background"
            />
        </div>
    );
};

export default VerificationCode;
