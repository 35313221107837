import "./Loader.css";

const Loader = () => {
    return (
        <div className="loader-container">
            <div className="showbox">
                <div className="loader">
                    <svg className="circular" viewBox="25 25 50 50">
                        <circle
                            className="path"
                            cx="50"
                            cy="50"
                            r="20"
                            fill="none"
                            strokeWidth="2"
                            strokeMiterlimit="10"
                        />
                    </svg>
                    <div className="loader-text">
                        <svg
                            width="512"
                            height="512"
                            viewBox="0 0 512 512"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clipPath="url(#clip0_3143_7641)">
                                <rect
                                    width="512"
                                    height="512"
                                    rx="256"
                                    fill="#0D7A5F"
                                />
                                <path
                                    d="M274.82 276.84C290.22 276.84 302.82 271.94 312.62 262.14C322.7 252.34 327.74 235.82 327.74 212.58C327.74 189.9 322.42 172.12 311.78 159.24C301.42 146.36 285.32 139.92 263.48 139.92C257.04 139.92 250.32 140.76 243.32 142.44V334.38C243.32 346.42 244.58 355.8 247.1 362.52C249.9 368.96 254.1 373.58 259.7 376.38C265.3 378.9 273.14 380.44 283.22 381C284.9 385.76 285.74 393.18 285.74 403.26C272.02 402.42 251.72 402 224.84 402C195.16 402 173.74 402.42 160.58 403.26C160.58 392.9 161.28 385.48 162.68 381C170.52 380.44 176.4 379.04 180.32 376.8C184.52 374.56 187.46 370.78 189.14 365.46C190.82 360.14 191.66 352.16 191.66 341.52V178.14C191.66 167.78 190.82 159.94 189.14 154.62C187.46 149.3 184.66 145.52 180.74 143.28C176.82 141.04 170.94 139.64 163.1 139.08C161.42 133.2 160.58 125.78 160.58 116.82C175.98 117.66 196 118.08 220.64 118.08L256.34 117.66C263.62 117.38 272.3 117.24 282.38 117.24C317.38 117.24 342.72 124.94 358.4 140.34C374.36 155.46 382.34 175.48 382.34 200.4C382.34 218.32 378.56 234.98 371 250.38C363.44 265.5 352.24 277.68 337.4 286.92C322.56 295.88 304.78 300.36 284.06 300.36C272.3 300.36 262.5 298.82 254.66 295.74C254.1 292.38 253.82 289.72 253.82 287.76C253.82 283.28 254.66 278.94 256.34 274.74C261.1 276.14 267.26 276.84 274.82 276.84Z"
                                    fill="white"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_3143_7641">
                                    <rect
                                        width="512"
                                        height="512"
                                        rx="256"
                                        fill="white"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Loader;
