import star from "../../../assets/images/Rating-Star.png";
import "./TestimonialSlider.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { useTranslation } from "react-i18next";
const TestimonialSlider = () => {
    const { t } = useTranslation();

    const testimonialSlider = [
        {
            id: 1,
            name: "Jelena M.",
            desc: t(
                "Branko assembled my IKEA dresser in less than 30 minutes, and he also quickly put together a metal shelf. He even fixed a drawer on an existing desk so that it opens better."
            ),
        },
        {
            id: 2,
            name: "Igor P.",
            desc: t(
                "Aleksandar was fantastic! He came with all the necessary equipment, even with parts I didn't know I needed. He perfectly hung a heavy chandelier and secured a cabinet to the wall."
            ),
        },
        {
            id: 3,
            name: "Dunja R.",
            desc: t(
                "Igor fixed the air conditioning pipe that was clogging the sink in my bedroom. He was punctual, communicative, and efficient. I highly recommend him!"
            ),
        },
        {
            id: 4,
            name: "Stefan N.",
            desc: t(
                "Mihailo was excellent in helping with the installation of a tempered glass shower cabin with unusual settings. He was patient and willing to help us resolve it. Thank you, Mihailo!"
            ),
        },
        {
            id: 5,
            name: "Stefan N.",
            desc: t(
                "I urgently needed a translator, and I found one in less than an hour. Fantastic!"
            ),
        },
        {
            id: 6,
            name: "Sara M.",
            desc: t(
                "A great way to quickly find someone to help with an event. Reliable and efficient!"
            ),
        },
        {
            id: 7,
            name: "Igor V.",
            desc: t(
                "I'm thrilled with how easily I found someone for moving. Professional and affordable."
            ),
        },
        {
            id: 8,
            name: "Jelena D.",
            desc: t(
                "The platform is wonderful, I found perfect help for house cleaning. I recommend it to everyone!"
            ),
        },
    ];

    return (
        <Swiper
            spaceBetween={15}
            navigation={true}
            pagination={{
                clickable: true,
            }}
            centeredSlides={true}
            modules={[Navigation, Pagination]}
            breakpoints={{
                0: {
                    slidesPerView: 1,
                },
                768: {
                    slidesPerView: 2,
                },
                1200: {
                    slidesPerView: 3,
                },
            }}
            className="blur mySwiper p-3 py-5"
        >
            {testimonialSlider.map((item) => (
                <SwiperSlide key={item.id} className="testimonial-box p-4">
                    <div className="d-flex icon gap-1 mt-2 mb-4">
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                        <img src={star} alt="star" />
                    </div>
                    <div>
                        <p className="text-start">{item.desc}</p>
                    </div>
                    {/* <div className="d-flex align-items-center"> */}
                    {/* <img src={person} className="profile" alt="person" /> */}
                    <h3 className="mb-0 text-start">{item.name}</h3>
                    {/* </div> */}
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default TestimonialSlider;
