import React from "react";
import { Outlet } from "react-router-dom";
import NavbarProvider from "../components/Navbar/NavbarProvider";
import ProviderFooter from "../components/Footer/ProviderFooter";

const TaskerLayout = () => {
    return (
        <>
            <NavbarProvider />
            <Outlet />
            <ProviderFooter />
        </>
    );
};

export default TaskerLayout;
