import Notifications from "../../components/Notifications/Notifications";

const ProviderNotification = () => {
    return (
        <div className="flex-1">
            <Notifications />
        </div>
    );
};

export default ProviderNotification;
