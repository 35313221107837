import star from "../../../assets/images/Rating-Star.png";
import tickCircle from "../../../assets/images/tick-circle.png";
import featuresImg1 from "../../../assets/images/featuresImg1.jpg";
import featuresImg2 from "../../../assets/images/featuresImg2.jpg";
import featuresImg3 from "../../../assets/images/featuresImg3.jpg";
import featuresImg4 from "../../../assets/images/featuresImg4.jpg";
import "./FeaturedSlider.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { useTranslation } from "react-i18next";

const FeaturedSlider = () => {
    const { t } = useTranslation();
    const featuredSlider = [
        {
            id: 1,
            img: featuresImg1,
            name: "Jelena Marković",
            star: "4.9",
            task: "35",
            title: t("Professional Childcare and Developmental Activities"),
            desc: t(
                "Jelena brings joy and creativity into children's daily lives, providing them with a safe and stimulating environment for play, learning, and development."
            ),
            skill: [
                t("Creative and educational activities for children"),
                t("providing emotional support"),
                t("play and learning through fun"),
            ],
        },
        {
            id: 2,
            img: featuresImg2,
            name: "Marko Jovanović ",
            star: "4.8",
            task: "18",
            title: t("Reliable and Safe Electrical Services"),
            desc: t(
                "With a license and extensive experience, Marko provides professional electrical services with a guarantee of safety and quality."
            ),
            skill: [
                t("Lighting installation"),
                t("repairs"),
                t("comprehensive electrical work"),
            ],
        },
        {
            id: 3,
            img: featuresImg3,
            name: "Ivana Miljković",
            star: "4.8",
            task: "14",
            title: t("Translation and Educational Services"),
            desc: t(
                "With precision and professionalism in translation and content creation, Ivana ensures that communication is clear and accurate in every aspect."
            ),
            skill: [t("Translation"), t("proofreading"), t("content creation")],
        },
        {
            id: 4,
            img: featuresImg4,
            name: "Luka Jović",
            star: "4.8",
            task: "12",
            title: t("Dog Walking and Pet Care"),
            desc: t(
                "Luka is a reliable and caring animal lover who provides peace of mind to pet owners through dedicated care and attention."
            ),
            skill: [
                t("Daily pet care"),
                t("dog walking"),
                t("pet companionship"),
            ],
        },
    ];
    return (
        <Swiper
            slidesPerView={3}
            spaceBetween={30}
            navigation={true}
            modules={[Navigation]}
            breakpoints={{
                0: {
                    slidesPerView: 1,
                },
                768: {
                    slidesPerView: 2,
                },
                1200: {
                    slidesPerView: 3,
                },
            }}
            className="mySwiper p-3"
        >
            {featuredSlider.map((item) => (
                <SwiperSlide key={item.id} className="featured-box p-3">
                    <div className="featured-header d-flex align-items-start gap-3 flex-wrap">
                        <img src={item.img} className="profile" alt="person" />
                        <div>
                            <h3 className="text-start">{item.name}</h3>
                            <div className="d-flex align-items-center mb-2">
                                <img
                                    src={star}
                                    className="icon me-2"
                                    alt="star"
                                />
                                <h6 className="me-2 mb-0">
                                    {item.star} <span>{t("Rating")}</span>
                                </h6>
                            </div>
                            <div className="d-flex align-items-center">
                                <img
                                    src={tickCircle}
                                    className="icon me-2"
                                    alt="tick"
                                />
                                <h6 className="me-2 mb-0">
                                    {item.task}{" "}
                                    <span>{t("Completed Tasks")}</span>
                                </h6>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="featured-body">
                        <h5 className="text-start">{t("Featured Skills")}</h5>
                        {item.skill.map((task, index) => (
                            <div
                                key={index}
                                className="text-start text-capitalize"
                            >
                                <h4>{task}</h4>
                            </div>
                        ))}
                    </div>{" "}
                    <hr />
                    <div className="featured-footer">
                        <h3 className="text-start">{item.title}</h3>
                        <p className="text-start">{item.desc}</p>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default FeaturedSlider;
