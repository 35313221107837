import { useState } from "react";
import dummyImage from "../../../assets/images/profile.jpg";
import Reviews from "../../Reviews/Reviews";
import "./TaskAndPriceDetailModal.css";
import Modal from "react-bootstrap/Modal";
import Description from "../../Description/Description";
import { useEffect } from "react";
import axios from "axios";
import { toast } from "sonner";
import Loader from "../../Loader/Loader";
import MyRatingComponent from "../../../components/MyRatingComponent";
import { useTranslation } from "react-i18next";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const BidDetailsModal = ({ bidCardDetailsId, onAccept, onDecline }) => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [bidDetails, setBidDetails] = useState({});
    const [loading, setLoading] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (bidCardDetailsId) {
            axios
                .get(`${apiUrl}/task/bid-detail?bidId=${bidCardDetailsId}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                })
                .then((response) => {
                    setBidDetails(response.data.data);
                })
                .catch((error) => {
                    toast.error("Fetching error", error);
                    // console.error("Fetching error:", error);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            // console.error("Bid ID is undefined.");
            setLoading(false);
        }
    }, [bidCardDetailsId]);

    return (
        <>
            <span
                onClick={handleShow}
                style={{ color: "#0D7A5F", cursor: "pointer" }}
            >
                {t("View Profile")}
            </span>

            <Modal
                size="lg"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="taskAndPriceDetailModal p-0"
                centered
            >
                {loading && <Loader />}
                <Modal.Header closeButton className="border-0"></Modal.Header>
                <Modal.Body>
                    <div className="d-flex align-items-start align-items-sm-center justify-content-between flex-column flex-lg-row">
                        <div className="d-flex align-items-center gap-3 mb-3 mb-sm-0">
                            <img
                                src={
                                    bidDetails.profileImage
                                        ? bidDetails.profileImage
                                        : dummyImage
                                }
                                className="profile-img"
                                alt="person"
                            />
                            <div>
                                <div className="d-flex align-items-center flex-column flex-sm-row gap-2 mb-2">
                                    <h1 className="mb-0">
                                        {bidDetails.firstname}&nbsp;
                                        {bidDetails.lastname}
                                    </h1>
                                    <span className="px-2 py-1 span">
                                        {bidDetails.minimumhours}{" "}
                                        {t("Hours Minimum")}
                                    </span>
                                </div>
                                <div className="d-flex align-items-center gap-2">
                                    <MyRatingComponent
                                        value={bidDetails.averageRating}
                                    />
                                    <h6 className="mb-0">
                                        {bidDetails.averageRating || 0}
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h2>
                                {bidDetails.rate} {t("din/hr")}
                            </h2>
                        </div>
                    </div>

                    <div>
                        <div className="my-2 d-flex align-items-center justify-content-between">
                            <p className="mb-0">
                                {t("Proposed Price")} :
                                <span className="price opacity-100">
                                    {bidDetails.praposedPrice}{" "}
                                    {bidDetails.selectPrice === 0
                                        ? "din"
                                        : t("din/hr")}
                                </span>
                            </p>
                            <div className="d-flex align-items-center gap-2">
                                <button
                                    className="coman-btn px-3"
                                    style={{ height: "38px" }}
                                    onClick={() => onDecline(bidCardDetailsId)}
                                >
                                    {t("Decline")}
                                </button>
                                <button
                                    className="coman-btn-fill px-3"
                                    style={{ height: "38px" }}
                                    onClick={() => onAccept(bidCardDetailsId)}
                                >
                                    {t("Accept")}
                                </button>
                            </div>
                        </div>
                        <Description description={bidDetails.description} />
                    </div>

                    <hr />
                    <div className="my-2">
                        <h3>{t("About me")}</h3>
                        <p>{bidDetails.aboutMe}</p>
                    </div>
                    <div className="my-2">
                        <h3>{t("Skills & Experience")}</h3>
                        <p>{bidDetails.skillsAndExperience}</p>
                    </div>
                    <div className="my-2 reviews">
                        <h3>
                            {t("Reviews")} ({bidDetails.feedbacksCount})
                        </h3>
                        <Reviews bookingReview={bidDetails} />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default BidDetailsModal;
