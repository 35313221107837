import { BrowserRouter as Router } from "react-router-dom";
import AllRoutes from "./routes";
import GoToTop from "../components/GoToTop";
import { Toaster } from "sonner";

const Routing = () => {
    return (
        <>
            <Toaster position="top-left" expand={false} richColors />
            <GoToTop />
            <Router>
                <AllRoutes />
            </Router>
        </>
    );
};

export default Routing;
