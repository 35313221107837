import React from "react";

const Star = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#FFC403"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-star"
        >
            <polygon points="12 2 15.09 8.26 22 9.27 17 14.03 18.18 21.02 12 17.77 5.82 21.02 7 14.03 2 9.27 8.91 8.26 12 2"></polygon>
        </svg>
    );
};

export default Star;
