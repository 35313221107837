import { Link } from "react-router-dom";
import Mark from "../../../assets/images/mark.png";

const PaymentError = () => {
    return (
        <div className="container vh-100 d-flex flex-column justify-content-center align-items-start">
            <div className="d-flex gap-4 mb-5">
                <img src={Mark} height="40" alt="icon" />
                <div>
                    <h2 className="mb-3">Something's gone wrong</h2>
                    <p>
                        Unable to proceed because the required element is
                        unexpectedly detached from the page.
                    </p>
                </div>
            </div>
            <Link
                className="align-self-center my-5 bg-danger p-3 rounded text-white"
                to="/home-provider"
            >
                Go to home page
            </Link>
        </div>
    );
};

export default PaymentError;
