import { useState } from "react";
import "./Availability.css";
import { toast } from "sonner";
import axios from "axios";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const Availability = ({ availabilityTitle, providerAvailability = false }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [availabilityData, setAvailabilityData] = useState([
        {
            weekday: 0,
            type: false,
            startTime: "",
            endTime: "",
            day: t("Monday"),
        },
        {
            weekday: 1,
            type: false,
            startTime: "",
            endTime: "",
            day: t("Tuesday"),
        },
        {
            weekday: 2,
            type: false,
            startTime: "",
            endTime: "",
            day: t("Wednesday"),
        },
        {
            weekday: 3,
            type: false,
            startTime: "",
            endTime: "",
            day: t("Thursday"),
        },
        {
            weekday: 4,
            type: false,
            startTime: "",
            endTime: "",
            day: t("Friday"),
        },
        {
            weekday: 5,
            type: false,
            startTime: "",
            endTime: "",
            day: t("Saturday"),
        },
        {
            weekday: 6,
            type: false,
            startTime: "",
            endTime: "",
            day: t("Sunday"),
        },
    ]);

    const navigate = useNavigate();

    const handleCheckboxChange = (index) => {
        const updatedData = [...availabilityData];
        updatedData[index].type = !updatedData[index].type;
        if (updatedData[index].type) {
            updatedData[index].startTime = "08:00";
            updatedData[index].endTime = "17:00";
        } else {
            updatedData[index].startTime = "";
            updatedData[index].endTime = "";
        }
        setAvailabilityData(updatedData);
    };

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        const updatedData = [...availabilityData];
        updatedData[index][name] = value;
        setAvailabilityData(updatedData);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        for (let day of availabilityData) {
            if (day.type && day.startTime >= day.endTime) {
                toast.error(
                    `${t("End time must be greater than start time for")} ${
                        day.day
                    }`
                );
                return;
            }
        }
        setLoading(true);
        let response;
        try {
            const requestBody = availabilityData
                .filter((day) => day.type === true)
                .map((day) => ({
                    weekday: day.weekday,
                    type: day.type,
                    startTime: day.startTime,
                    endTime: day.endTime,
                }));
            response = await axios.post(
                `${apiUrl}/tasker/availability`,
                requestBody,
                {
                    method: "POST",
                    headers: {
                        accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                }
            );
            if (
                response.status === 200 &&
                response.data &&
                typeof response.data === "object"
            ) {
                if (response) {
                    toast.success(t("Task Create Successfully"));
                    localStorage.setItem("checkAvailability", true);
                    navigate("/home-provider");
                } else {
                    toast.error("Failed: " + response.data.message);
                }
            }
        } catch (error) {
            toast.error(error.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="availability-wrapper mb-5">
            {loading && <Loader />}
            <div className="container">
                <div className="col-12">
                    <h1 className="my-5 text-center">{availabilityTitle}</h1>
                    <div className="box my-5 p-5">
                        <form action="">
                            <div className="">
                                {availabilityData.map((day, index) => (
                                    <div key={day.weekday}>
                                        <div className="grid-3">
                                            <div className="form-check small-box d-flex align-items-center gap-3 ps-5">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={day.type}
                                                    id={day.day}
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            index
                                                        )
                                                    }
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor={day.day}
                                                >
                                                    {day.day}
                                                </label>
                                            </div>
                                            <div>
                                                <input
                                                    type="time"
                                                    className="form-control"
                                                    value={day.startTime}
                                                    onChange={(e) =>
                                                        handleChange(
                                                            e,
                                                            index,
                                                            "startTime"
                                                        )
                                                    }
                                                    name="startTime"
                                                    disabled={!day.type}
                                                />
                                            </div>
                                            <div>
                                                <input
                                                    type="time"
                                                    className="form-control"
                                                    value={day.endTime}
                                                    onChange={(e) =>
                                                        handleChange(
                                                            e,
                                                            index,
                                                            "endTime"
                                                        )
                                                    }
                                                    name="endTime"
                                                    disabled={!day.type}
                                                />
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                ))}
                            </div>
                        </form>
                    </div>

                    {providerAvailability === true ? (
                        <div className="d-flex justify-content-center">
                            <button
                                className="coman-btn-fill"
                                onClick={handleSubmit}
                                style={{ width: "90%" }}
                            >
                                {t("Update")}
                            </button>
                        </div>
                    ) : (
                        <div>
                            <button
                                onClick={handleSubmit}
                                className="coman-btn-fill"
                            >
                                {t("Continue")}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Availability;
