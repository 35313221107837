import "./TaskCard.css";
import location from "../../../assets/images/location.png";
import calendars from "../../../assets/images/Calendar.png";
import ruler from "../../../assets/images/ruler.png";
import BrowsTaskDetailModal from "../../Modal/TaskPostDetail/BrowsTaskDetailModal";
import dummyImage from "../../../assets/images/profile.jpg";
import { useTranslation } from "react-i18next";
import MyRatingComponent from "../../../components/MyRatingComponent";
import { formatDateTime, updateLocale } from "../../../Utils/Utils";

const TaskCard = ({ bidDetails, browseTask, bidSubmissionCard = false }) => {
    const { t } = useTranslation();
    const selectedLanguage = localStorage.getItem("selectedLanguage") || "sr";
    updateLocale(selectedLanguage);
    return (
        <div className="task-card-wrapper h-100">
            <div className="box p-3 d-flex justify-content-between flex-column h-100">
                {bidSubmissionCard === true ? (
                    <div>
                        <div className="d-flex align-items-center gap-2">
                            <img
                                src={
                                    bidDetails.profileImage
                                        ? bidDetails.profileImage
                                        : dummyImage
                                }
                                className="profile-img"
                                alt="person"
                            />
                            <div>
                                <h3 className="mb-0">
                                    {bidDetails.firstname}&nbsp;
                                    {bidDetails.lastname}
                                </h3>
                                <div className="d-flex gap-1 align-items-center">
                                    <MyRatingComponent
                                        value={bidDetails.averageRating}
                                    />
                                    <span>{bidDetails.averageRating || 0}</span>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="row mb-2">
                            <div className="col-6">
                                <h5 className="mb-1">
                                    {t("Required Service")}
                                </h5>
                                <h2 className="mb-0">{bidDetails.service}</h2>
                            </div>
                            <div className="col-6">
                                <h5 className="mb-1">{t("Budget")}</h5>
                                <h2 className="mb-0">
                                    {bidDetails.selectPrice === 0
                                        ? `${bidDetails.budget} din`
                                        : `${bidDetails.budget} ${t("din/hr")}`}
                                </h2>
                            </div>
                        </div>
                        <div className="mb-2 desc">
                            <h5 className="mb-1">{t("Description")}</h5>
                            <p className="mb-0">{bidDetails.description}</p>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="custome-grids">
                            <img
                                src={
                                    browseTask.profileImage
                                        ? browseTask.profileImage
                                        : dummyImage
                                }
                                className="profile-img"
                                alt="person"
                            />
                            <div>
                                <h3 className="mb-0">
                                    {browseTask.firstname}&nbsp;
                                    {browseTask.lastname}
                                </h3>
                                <div className="d-flex gap-1 align-items-center">
                                    <MyRatingComponent
                                        value={browseTask.averageRating}
                                    />
                                    <span>{browseTask.averageRating || 0}</span>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-start gap-1 mt-2">
                            <img
                                src={location}
                                className="icon"
                                alt="location"
                            />
                            <h4 className="mb-0 small-address">
                                {browseTask.address}
                            </h4>
                        </div>
                        <hr />
                        <div className="row mb-2">
                            <div className="col-6">
                                <h5 className="mb-1">
                                    {t("Required Service")}
                                </h5>
                                <h2 className="mb-0">{browseTask.service}</h2>
                            </div>
                            <div className="col-6">
                                <h5 className="mb-1">{t("Budget")}</h5>
                                <h2 className="mb-0">
                                    {browseTask.selectPrice === 0
                                        ? `${browseTask.budget} din`
                                        : `${browseTask.budget} ${t("din/hr")}`}
                                </h2>
                            </div>
                        </div>
                        <div className="mb-2">
                            <h5 className="mb-1">{t("Description")}</h5>
                            <p className="mb-0 desc">
                                {browseTask.description}
                            </p>
                        </div>
                    </div>
                )}

                {bidSubmissionCard === true ? (
                    <>
                        <hr />
                        <div className="d-flex flex-column gap-3">
                            <div className="d-flex gap-2">
                                <img
                                    className="icons"
                                    src={calendars}
                                    alt="calenders"
                                />
                                <p className="mb-0 title">
                                    {formatDateTime(
                                        bidDetails.selectDate,
                                        bidDetails.selectTime
                                    )}
                                </p>
                            </div>
                            <div className="d-flex gap-2">
                                <img
                                    className="icons"
                                    src={location}
                                    alt="location"
                                />
                                <p className="mb-0 title">
                                    {bidDetails.address}
                                </p>
                            </div>
                            <div className="d-flex gap-2">
                                <img
                                    className="icons"
                                    src={ruler}
                                    alt="ruler"
                                />
                                <p className="mb-0 title">
                                    {bidDetails.howBigIsYourTask} {t("Hours")}
                                </p>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="d-flex justify-content-center align-items-center">
                        <BrowsTaskDetailModal browseTaskId={browseTask._id} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default TaskCard;
