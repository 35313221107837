import { useTranslation } from "react-i18next";

const TermsAndConditions = () => {
    const { t } = useTranslation();
    return (
        <div className="my-5">
            <h4 className="fw-bold">{t("Welcome to Poslić!")}</h4>
            <p>
                {t(
                    "These terms of use define the rules and regulations for using the Poslić service. By using Poslić, you agree to be bound by these terms of use. If you do not agree with any part of these terms, please do not use our services."
                )}
            </p>
            <ol className="lh-lg">
                <li className="fw-bold">
                    <h2 className="fw-bold fs-6">
                        {t("Service Description:")}
                    </h2>
                    <ul>
                        <li>
                            {t(
                                "Poslić provides a platform for connecting service providers with clients through profiles and tasks."
                            )}
                        </li>
                    </ul>
                </li>
                <li className="fw-bold">
                    <h2 className="fw-bold fs-6">
                        {t("Advertisements and Profiles:")}
                    </h2>
                    <ul>
                        <li>
                            {t(
                                "Users can create profiles and post tasks for free."
                            )}
                        </li>
                        <li>
                            {t(
                                "We offer commercial services for the promotion of profiles and tasks."
                            )}
                        </li>
                    </ul>
                </li>
                <li className="fw-bold">
                    <h2 className="fw-bold fs-6">{t("Usage Rules:")}</h2>
                    <ul>
                        <li>
                            {t(
                                "Registration is free and mandatory for using the services."
                            )}
                        </li>
                        <li>{t("Users must be over 16 years of age.")}</li>
                        <li>{t("Each user can have only one account.")}</li>
                        <li>
                            {t(
                                "The content of profiles and tasks must comply with the law."
                            )}
                        </li>
                    </ul>
                </li>
                <li className="fw-bold">
                    <h2 className="fw-bold fs-6">
                        {t("Limitations and Prohibitions:")}
                    </h2>
                    <ul>
                        <li>
                            {t(
                                "The maximum number of active tasks per user is limited."
                            )}
                        </li>
                        <li>
                            {t("Advertising illegal activities is prohibited.")}
                        </li>
                    </ul>
                </li>
                <li className="fw-bold">
                    <h2 className="fw-bold fs-6">{t("User Rating:")}</h2>
                    <ul>
                        <li>
                            {t(
                                "Mutual rating of users is enabled after the exchange of messages."
                            )}
                        </li>
                    </ul>
                </li>
                <li className="fw-bold">
                    <h2 className="fw-bold fs-6">
                        {t("Data Protection and Privacy:")}
                    </h2>
                    <ul>
                        <li>
                            {t(
                                "Data are stored in a database and are used only for service needs."
                            )}
                        </li>
                        <li>
                            {t(
                                "We do not share data with third parties except in legally prescribed cases."
                            )}
                        </li>
                    </ul>
                </li>
                <li className="fw-bold">
                    <h2 className="fw-bold fs-6">
                        {t("Liability and Availability of Information:")}
                    </h2>
                    <ul>
                        <li>
                            {t(
                                "Poslić is not responsible for the content on the site in accordance with the Electronic Commerce Law."
                            )}
                        </li>
                    </ul>
                </li>
                <li className="fw-bold">
                    <h2 className="fw-bold fs-6">{t("Changes to Terms:")}</h2>
                    <ul>
                        <li>
                            {t(
                                "Poslić reserves the right to change the terms. Continued use of the service after changes constitutes acceptance of the new terms."
                            )}
                        </li>
                    </ul>
                </li>
            </ol>
        </div>
    );
};

export default TermsAndConditions;
