import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import "./AddAmountModal.css";
import axios from "axios";
import { toast } from "sonner";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { decode } from "base-64";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const AddAmountModal = ({ onAddPayment }) => {
    const { t } = useTranslation();
    const { bookingId } = useParams();
    const decodedId = decode(bookingId);
    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState({
        extraHrs: "",
        rate: "",
        bookingId: decodedId,
    });

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let response;
        try {
            response = await axios.post(
                `${apiUrl}/booking/additional-payment`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "accessToken"
                        )}`,
                    },
                }
            );

            if (response.status === 200) {
                toast.success(t("Additonal payment service successfully"));
                setShow(false);
                onAddPayment();
            }
        } catch (error) {
            toast.error(error.response.data.message);
        }
    };

    return (
        <>
            <button
                type="button"
                className="coman-btn border-0 mb-3 fw-medium"
                onClick={handleShow}
            >
                {t("+Add More Payable Amount")}
            </button>
            <Modal
                size="lg"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="addamountmodal"
                centered
            >
                <Modal.Header
                    closeButton
                    className="border-0 justify-content-center text-center"
                >
                    <h1 className="mb-0">{t("+Add More Payable Amount")}</h1>
                </Modal.Header>
                <Modal.Body>
                    <form action="" onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="hours" className="form-label">
                                {t("Extra Hours")}
                            </label>
                            <input
                                type="number"
                                placeholder={t("Enter Extra Hours")}
                                className="form-control"
                                onChange={handleChange}
                                value={formData.extraHrs}
                                name="extraHrs"
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="rate" className="form-label">
                                {t("Hourly Rate")}
                            </label>
                            <div className="position-relative din">
                                <input
                                    type="number"
                                    placeholder={t("Enter Hourly Rate")}
                                    className="form-control pe-5"
                                    onChange={handleChange}
                                    value={formData.rate}
                                    name="rate"
                                />
                                <span className="position-absolute">
                                    {t("din")}
                                </span>
                            </div>
                        </div>
                        <button className="coman-btn-fill">{t("Done")}</button>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddAmountModal;
