import { useTranslation } from "react-i18next";
import Languages from "../../components/Languages/Languages";
import Title from "../../components/title/Title";

const ProviderLanguage = () => {
    const { t } = useTranslation();
    return (
        <div className="languages-wrapper flex-1">
            <div className="container">
                <div className="col-lg-10 offset-lg-1">
                    <Title title={t("Languages")} location="/home-provider" />
                    <Languages />
                </div>
            </div>
        </div>
    );
};

export default ProviderLanguage;
