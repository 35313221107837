import { Link } from "react-router-dom";
import background from "../../../assets/images/bg-1.png";
import { useState } from "react";
import axios from "axios";
import { toast } from "sonner";
import Loader from "../../../components/Loader/Loader";
import { useTranslation } from "react-i18next";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const ForgotPassword = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const localStorageLanguage = localStorage.getItem("selectedLanguage");
        let languageHeaderValue = "1";
        if (localStorageLanguage === "sr") {
            languageHeaderValue = "2";
        }
        if (!email.trim()) {
            toast.error(t("Email Required"));
            return;
        }
        setLoading(true);
        let response;
        const headers = {
            accept: "application/json",
            "Content-Type": "application/json",
            language: languageHeaderValue,
        };
        try {
            response = await axios.post(
                `${apiUrl}/user/forgot-password`,
                { email },
                { headers }
            );
            if (response.status === 200) {
                toast.success(response.data.message);
            }
        } catch (error) {
            toast.error(error.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="position-relative choose-section py-5">
            {loading && <Loader />}
            <div className="container d-flex justify-content-center align-items-center">
                <div className="box p-3 py-4">
                    <div className="box-header mb-4">
                        <h1 className="text-center">{t("Forgot Password")}</h1>
                        <p className="mb-0 text-center">
                            {t("Please enter your registered email address")}
                        </p>
                    </div>
                    <form action="" onSubmit={handleSubmit}>
                        <div className="form-group mb-5">
                            <input
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                name="email"
                                type="email"
                                className="form-control"
                                placeholder={t("Enter Email Address")}
                                autoComplete="on"
                            />
                        </div>
                        <button type="submit" className="coman-btn-fill mb-5">
                            {t("Send reset password link")}
                        </button>
                    </form>
                    <div className="box-footer text-center">
                        <Link to="/login" className="links underline">
                            {t("Back to login")}
                        </Link>
                    </div>
                </div>
            </div>
            <img
                src={background}
                className="w-100 position-absolute top-0 start-0 bg-image h-100 object-fit-cover z-n1"
                alt="background"
            />
        </div>
    );
};

export default ForgotPassword;
