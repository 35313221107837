import { useTranslation } from "react-i18next";
import ProfileAvailability from "../../components/Availability/ProfileAvailability";
import EditProfile from "../../components/EditProfile/EditProfile";
import Title from "../../components/title/Title";

const ProviderEditProfile = () => {
    const { t } = useTranslation();
    return (
        <div className="edit-profile-wrapper flex-1">
            <div className="container">
                <div className="col-12 col-md-10 offset-0 offset-md-1">
                    <Title
                        title={t("Edit Profile")}
                        location="/home-provider"
                    />
                    <EditProfile
                        providerProfile={true}
                        location="/home-provider"
                    />
                    <ProfileAvailability
                        availabilityTitle={t("Edit Availability")}
                        providerAvailability={true}
                    />
                </div>
            </div>
        </div>
    );
};

export default ProviderEditProfile;
