import { Link } from "react-router-dom";
import leftArrow from "../../assets/images/left-arrow.png";

const Title = ({ title, location }) => {
    return (
        <div className="d-flex align-items-center justify-content-start my-4">
            <Link to={location} className="back-btn">
                <img src={leftArrow} alt="leftArrow" />
            </Link>
            <h1 className="col-11 text-center mb-0">{title}</h1>
        </div>
    );
};

export default Title;
