import "./PaymentDetails.css";
import AddAmountModal from "../Modal/AddAmountModal/AddAmountModal";
import { useCallback, useEffect, useState } from "react";
import { toast } from "sonner";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../Loader/Loader";
import { useTranslation } from "react-i18next";
import { decode } from "base-64";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const BookingPaymentDetails = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { bookingId } = useParams();
    const decodedId = decode(bookingId);
    const [loading, setLoading] = useState(false);
    const [aditionPayment, setAditionPayment] = useState([]);

    const [taskDetails, setTaskDetails] = useState([]);
    const completeTask = useCallback(() => {
        setLoading(true);
        axios
            .get(`${apiUrl}/tasker/booking-detail?bookingId=${decodedId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then((response) => {
                setTaskDetails(response.data.data);
            })
            .catch((error) => {
                toast.error("Fetching error", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [decodedId]);

    useEffect(() => {
        if (taskDetails?.status === 3) {
            navigate("/provider-task");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskDetails.status]);

    const fetchData = useCallback(() => {
        setLoading(true);
        axios
            .get(`${apiUrl}/tasker/additonal-payment?bookingId=${decodedId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then((response) => {
                setAditionPayment(
                    Array.isArray(response.data.data)
                        ? response.data.data
                        : [response.data.data]
                );
            })
            .catch((error) => {
                toast.error("Fetching error", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [decodedId]);

    useEffect(() => {
        fetchData();
        completeTask();
    }, [decodedId, fetchData, completeTask]);

    const handleAddPayment = () => {
        fetchData();
    };

    const handleCompleteTask = (e) => {
        e.preventDefault();
        setLoading(true);
        axios
            .get(`${apiUrl}/booking/complete?bookingId=${decodedId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then(() => {
                toast.success(t("Task completed successfully"));
                completeTask();
            })
            .catch((error) => {
                toast.error(error.response.data.message);
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className="payment-details">
            {loading && <Loader />}
            <div className="description px-4 pb-2 pt-4">
                <h3>{t("Review your task description")}</h3>
                <p className="p-3">{taskDetails.description}</p>
            </div>

            <form className="mb-5">
                <div className="price-details p-4 my-4">
                    <h3 className="mb-4">
                        {t("Price Details")}{" "}
                        {taskDetails.selectPrice === 1 ||
                        taskDetails.selectPrice === null ? (
                            <span style={{ color: "#0D7A5F" }}>
                                ({t("Hourly")})
                            </span>
                        ) : (
                            <span style={{ color: "#0D7A5F" }}>
                                ({t("Fixed")})
                            </span>
                        )}
                    </h3>
                    {taskDetails.selectPrice === 1 ||
                    taskDetails.selectPrice === null ? (
                        <div className="d-flex mb-2 justify-content-between">
                            <h4>{t("Price Details")}</h4>
                            <h5>
                                {taskDetails.howBigIsYourTask} x{" "}
                                {taskDetails.horlyRate} {t("din/hr")}
                            </h5>
                        </div>
                    ) : (
                        ""
                    )}
                    {taskDetails.promoAmount && (
                        <div className="d-flex mb-2 justify-content-between">
                            <h4>{t("Promo code")}</h4>
                            <span>
                                - {taskDetails?.promoAmount} {t("din")}
                            </span>
                        </div>
                    )}
                    {taskDetails.referralAmount && (
                        <div className="d-flex justify-content-between">
                            <h4>Referral Amount</h4>
                            <span>
                                - {taskDetails?.referralAmount} {t("din")}
                            </span>
                        </div>
                    )}
                    <hr />
                    <div className="d-flex justify-content-between">
                        <h2 className="mb-0">{t("Total Rate")}</h2>
                        <h1 className="mb-0">
                            {taskDetails.referralAmount === null &&
                            taskDetails.promoAmount === null ? (
                                <>
                                    {taskDetails.totalRate} {t("din")}
                                </>
                            ) : (
                                <>
                                    {taskDetails.prePrize} {t("din")}
                                </>
                            )}
                        </h1>
                    </div>
                </div>

                {aditionPayment.length > 0 &&
                    aditionPayment.map((payment, index) => (
                        <div
                            className="price-details bg-green p-4 my-4"
                            key={index}
                        >
                            <h3 className="mb-4">
                                {t("Requested Amount")}&nbsp;
                                <span className="badge">
                                    {payment.item.request === 0 ? (
                                        <>{t("Pending")}</>
                                    ) : payment.item.request === 1 ? (
                                        <>{t("Completed")}</>
                                    ) : (
                                        <>{t("Declined")}</>
                                    )}
                                </span>
                            </h3>
                            <div className="d-flex mb-2 justify-content-between">
                                <h4>{t("Hourly Rate")}</h4>
                                <h5>
                                    {payment.item.extraHour} x{" "}
                                    {payment.item.hourlyRate} {t("din/hr")}
                                </h5>
                            </div>
                            {/* <div className="d-flex mb-2 justify-content-between">
                        <h4>Promo code</h4>
                        <span>- 15.00 din</span>
                    </div>
                    <div className="d-flex justify-content-between">
                        <h4>Trust And Support Fee</h4>
                        <h5>13.30 din</h5>
                    </div> */}
                            <hr />
                            <div className="d-flex justify-content-between">
                                <h2 className="mb-0">{t("Total Rate")}</h2>
                                <h1 className="mb-0">
                                    {payment.total} {t("din")}
                                </h1>
                            </div>
                        </div>
                    ))}

                <AddAmountModal onAddPayment={handleAddPayment} />

                {taskDetails.completeApproval === 1 ? (
                    <div>
                        <button
                            className="coman-btn-fill bg-secondary"
                            disabled
                            onClick={handleCompleteTask}
                        >
                            {t("Waiting For User Response")}
                        </button>
                    </div>
                ) : (
                    <div>
                        <button
                            className="coman-btn-fill"
                            onClick={handleCompleteTask}
                        >
                            {t("Complete Task")}
                        </button>
                    </div>
                )}
            </form>
        </div>
    );
};

export default BookingPaymentDetails;
