import "./Notifications.css";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import dummyImage from "../../assets/images/profile.jpg";
import moment from "moment";
import { toast } from "sonner";
import axios from "axios";
import Loader from "../Loader/Loader";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { encode } from "base-64";
import Star from "../Star";
import ReactStars from "react-rating-stars-component";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const Notifications = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [feedback, setFeedback] = useState({
        bookingId: null,
        notificationId: null,
        rate: 0,
        review: "",
    });
    const [notificationMsg, setNotificationMsg] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const notificationsPerPage = 10;

    const fetchNotifications = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${apiUrl}/user/notification`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            });
            setNotificationMsg(response.data.data);
        } catch (error) {
            toast.error("Fetching error", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchNotifications();
    }, []);

    const openModal = (item) => {
        if (item.isReview) {
            setSelectedUser({
                name: item.firstname,
                image: item.image || dummyImage,
            });
            setFeedback({
                ...feedback,
                notificationId: item._id,
                bookingId: item.bookingId,
            });
            setShowModal(true);
        } else {
            handleNotificationClick(
                item.parameter,
                item.bookingId,
                item.taskId,
                item.isbookingComplete,
                item.bidStatus,
                item.isAccpet
            );
        }
    };

    const handleNotificationClick = (
        parameter,
        bookingId,
        taskId,
        isBookingComplete,
        bidStatus,
        isAccpet
    ) => {
        if (isBookingComplete) {
            return;
        }

        if (parameter === "bid" && bidStatus) {
            toast.error(t("Bid has been already Accepted or Declined"));
            return;
        }

        if (parameter === "completebooking" && isAccpet) {
            return;
        }

        const navigationMapping = {
            bid: `/bids/${encode(taskId)}`,
            bookingrequest: "/task-acceptance",
            acceptbooking: "/mytask",
            completebooking: "/mytask",
            cancelbooking: "/mytask",
            // editHours: "/mytask",
            bidDecline: "/my-bids",
            "cancelbooking-tasker": "/mytask",
            "cancelbooking-user": "/provider-task",
            acceptBid: "/provider-task",
            reschedule: "/provider-task",
            message: "/message",
            sumbitfeedbackuser: "/reviews-ratings",
            sumbitfeedbacktasker: "/reviews",
            completeaddtional: `/task-details/${encode(bookingId)}`,
            declineaddtional: `/task-details/${encode(bookingId)}`,
            addtionalpayment: `/mytaskdetailscredit/${encode(bookingId)}`,
        };

        const route = navigationMapping[parameter];
        if (route) {
            navigate(route);
        }
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const handleSubmitFeedback = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(`${apiUrl}/feedback/`, feedback, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            });
            if (response.status === 200) {
                toast.success(t("Feedback submitted successfully!"));
                setShowModal(false);
                fetchNotifications();
                setFeedback({
                    bookingId: null,
                    notificationId: null,
                    rate: 0,
                    review: "",
                });
            }
        } catch (error) {
            toast.error("Failed: " + error.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    const handleCompleteBooking = async (
        bookingId,
        notificationId,
        accept,
        parameter,
        hour
    ) => {
        setLoading(true);
        if (parameter === "editHours") {
            const editData = {
                bookingId: bookingId,
                hour: hour,
                type: +accept,
                notificationId: notificationId,
            };
            try {
                const response = await axios.post(
                    `${apiUrl}/task/accept-reject-editHours`,
                    editData,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "accessToken"
                            )}`,
                        },
                    }
                );
                toast.success(response.data.message);
                fetchNotifications();
            } catch (error) {
                toast.error(error.response.data.message);
            } finally {
                setLoading(false);
            }
        } else {
            let compeleteNotifactionIds = [];
            if (parameter === "completebooking") {
                compeleteNotifactionIds = currentNotifications
                    .filter((item) => {
                        return (
                            item.parameter === "addtionalpayment" &&
                            item.bookingId === bookingId
                        );
                    })
                    .map((item) => item._id);
            }
            const data = {
                bookingId: bookingId,
                notificationId: notificationId,
                accept,
                compeleteNotifactionIds,
            };
            try {
                const response = await axios.post(
                    `${apiUrl}/task/approve-completed-booking`,
                    data,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "accessToken"
                            )}`,
                        },
                    }
                );
                toast.success(response.data.message);
                fetchNotifications();
                navigate("/mytask");
            } catch (error) {
                toast.error(error.response.data.message);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        axios
            .get(`${apiUrl}/user/seen-notification`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                },
            })
            .then(() => {})
            .catch((error) => {
                toast.error("Fetching error", error);
            });
    }, []);

    const formatDate = (createdAt) => {
        const messageDate = moment(createdAt);
        const today = moment().startOf("day");
        const yesterday = moment().subtract(1, "days").startOf("day");

        if (messageDate.isSame(today, "day")) {
            return `${t("Today")} ${messageDate.format("hh:mm A")}`;
        } else if (messageDate.isSame(yesterday, "day")) {
            return `${t("Yesterday")} ${messageDate.format("hh:mm A")}`;
        } else {
            return messageDate.format("D/M/YYYY hh:mm A");
        }
    };

    const indexOfLastNotification = currentPage * notificationsPerPage;
    const indexOfFirstNotification =
        indexOfLastNotification - notificationsPerPage;
    const currentNotifications = notificationMsg.slice(
        indexOfFirstNotification,
        indexOfLastNotification
    );

    const pageNumbers = Math.ceil(
        notificationMsg.length / notificationsPerPage
    );

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const renderPagination = () => {
        const MAX_PAGES_VISIBLE = 5;
        const paginationItems = [];

        let startPage = currentPage - Math.floor(MAX_PAGES_VISIBLE / 2);
        startPage = Math.max(startPage, 1);
        const endPage = Math.min(
            startPage + MAX_PAGES_VISIBLE - 1,
            pageNumbers
        );

        startPage = Math.max(endPage - MAX_PAGES_VISIBLE + 1, 1);

        for (let i = startPage; i <= endPage; i++) {
            paginationItems.push(
                <li
                    key={i}
                    className={`page-item ${currentPage === i ? "active" : ""}`}
                >
                    <button
                        className="page-link"
                        onClick={() => setCurrentPage(i)}
                    >
                        {i}
                    </button>
                </li>
            );
        }
        return (
            <>
                {notificationMsg.length > 10 && (
                    <nav>
                        <ul className="pagination align-items-center justify-content-center gap-2">
                            <li>
                                <button
                                    onClick={() =>
                                        handlePageChange(currentPage - 1)
                                    }
                                    disabled={currentPage === 1}
                                    className="bg-white border-0 arrow-btn"
                                >
                                    &laquo;
                                </button>
                            </li>
                            {paginationItems}
                            <li>
                                <button
                                    onClick={() =>
                                        handlePageChange(currentPage + 1)
                                    }
                                    disabled={currentPage === pageNumbers}
                                    className="bg-white border-0 arrow-btn"
                                >
                                    &raquo;
                                </button>
                            </li>
                        </ul>
                    </nav>
                )}
            </>
        );
    };

    return (
        <div className="notification-wrapper my-5 flex-1">
            {loading && <Loader />}
            <div className="container">
                <div className="col-xxl-6 col-xl-8 offset-xxl-3 offset-xl-2 col-lg-10 offset-lg-1">
                    <h1 className="text-center mb-4">{t("Notifications")}</h1>
                    <div className="boxs">
                        {notificationMsg.length === 0 ? (
                            <div
                                className="d-flex justify-content-center align-items-center"
                                style={{ minHeight: "450px" }}
                            >
                                {t("No notifications yet")}
                            </div>
                        ) : (
                            currentNotifications.map((item) => (
                                <div
                                    className={`box p-3 mb-3 ${
                                        item.seen ? "" : "seen-notification"
                                    }`}
                                    key={item._id}
                                    onClick={() => openModal(item)}
                                >
                                    <div className="d-flex justify-content-between align-items-start align-items-sm-center gap-sm-0 gap-2 flex-sm-row flex-column">
                                        <div className="d-flex align-items-center gap-4">
                                            <img
                                                src={
                                                    item.image
                                                        ? item.image
                                                        : dummyImage
                                                }
                                                alt="person"
                                            />
                                            <h3 className="mb-0">
                                                {item.title}
                                            </h3>
                                        </div>
                                        {item.createdAt && (
                                            <h6 className="mb-1">
                                                {formatDate(item.createdAt)}
                                            </h6>
                                        )}
                                    </div>
                                    {item.isAccpet === true && (
                                        <div className="row mt-4">
                                            <div className="col-6">
                                                <button
                                                    className="coman-btn-fill"
                                                    onClick={() =>
                                                        handleCompleteBooking(
                                                            item.bookingId,
                                                            item._id,
                                                            "1",
                                                            item.parameter,
                                                            item.hour
                                                        )
                                                    }
                                                >
                                                    {t("Approve")}
                                                </button>
                                            </div>
                                            <div className="col-6">
                                                <button
                                                    className="coman-btn"
                                                    onClick={() =>
                                                        handleCompleteBooking(
                                                            item.bookingId,
                                                            item._id,
                                                            "0",
                                                            item.parameter,
                                                            item.hour
                                                        )
                                                    }
                                                >
                                                    {t("Reject")}
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))
                        )}
                    </div>
                    {renderPagination()}
                </div>
            </div>

            <Modal
                show={showModal}
                onHide={closeModal}
                size="md"
                backdrop="static"
                keyboard={false}
                centered
                className="notification-modal"
            >
                {loading && <Loader />}
                <Modal.Header closeButton className="border-0"></Modal.Header>
                <Modal.Body className="text-center px-5">
                    <h1 className="mb-3">{t("Rate & Review")}</h1>
                    {selectedUser && (
                        <>
                            <img
                                src={selectedUser.image}
                                className="mb-4 rounded-circle object-fit-cover"
                                alt="person"
                            />
                            <p className="px-5">
                                {t("How was your experience with")}{" "}
                                {selectedUser.name}
                            </p>
                        </>
                    )}
                    <div className="mb-4 d-flex align-items-center justify-content-center gap-2">
                        <ReactStars
                            count={5}
                            onChange={(newRating) =>
                                setFeedback({
                                    ...feedback,
                                    rate: Number(newRating),
                                })
                            }
                            value={feedback.rate}
                            name="rate"
                            size={22}
                            isHalf={true}
                            emptyIcon={<Star />}
                            halfIcon={<i className="fa fa-star-half-alt"></i>}
                            fullIcon={<i className="fa fa-star"></i>}
                            activeColor="#FFC403"
                        />
                    </div>
                    <form>
                        <div className="mb-3">
                            <textarea
                                className="w-100 p-2"
                                placeholder={t("Write A Review")}
                                rows="4"
                                value={feedback.review}
                                onChange={(e) =>
                                    setFeedback({
                                        ...feedback,
                                        review: e.target.value,
                                    })
                                }
                                name="review"
                            ></textarea>
                        </div>
                        <button
                            type="button"
                            className="coman-btn-fill mb-3"
                            onClick={handleSubmitFeedback}
                        >
                            {t("Submit Feedback")}
                        </button>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Notifications;
